import { Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import { Colors } from "../theme";

export const PromotionsContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    padding: "0px 0px 0px 0px",
  },
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "0px 0px 0px 0px",
  overflow: "hidden",
  background: Colors.white,
}));

export const MessageText = styled(Typography)(({ theme }) => ({
     fontFamily: '"Montez", "cursive"',
  [theme.breakpoints.up("md")]: {
    fontSize: "3rem",
  },
  color: Colors.white,
  fontSize: "1.5rem",
}));

export const ImagePromotion = styled("img")(({ src, theme }) => ({
  src: `url(${src})`,
  width: "100%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%"
  },
}));

export const ImagePromotionCarousel = styled("img")(({ src, theme }) => ({
  src: `url(${src})`,
  width: "100%",
 
   [theme.breakpoints.down("lg")]: {
    height: "380px"
  },
  [theme.breakpoints.up("lg")]: {
    height: "320px"
  },
  [theme.breakpoints.up("xl")]: {
    height: "380px"
  },
  // [theme.breakpoints.down("md")]: {
  //   height: "100px"
  // },
  [theme.breakpoints.down("sm")]: {
      height: "145px"
  },
}));
