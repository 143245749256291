import * as React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";

import { Colors } from "../../../../../styles/theme";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from "@mui/material";

import { styled } from "@mui/material/styles";

function Incluye(props) {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: Colors.blue_ibero,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableCellAlta = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: Colors.orange_ibero,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <Container
      component="section"
      sx={{
        mt: 10,
        mb: 10,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <Typography
            variant="h4"
            marked="center"
            component="h2"
            sx={{ mb: 3 }}
            align="center"
          >
            Tarifas por persona
          </Typography>
          {/* <Typography
            variant="h5"
            align="center"
            sx={{ color: Colors.blue_ibero, mb: 3, height: "80px" }}
          >
            28 octubre / 18 noviembre
          </Typography> */}
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 250 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">
                    Triple
                  </StyledTableCell>
                  <StyledTableCell align="center">Doble</StyledTableCell>
                  <StyledTableCell align="center">Niños 3 a 15 años</StyledTableCell>
                  <StyledTableCell align="center">Doble a compartir</StyledTableCell>
                  <StyledTableCell align="center">
                    Sencilla
                  </StyledTableCell>                
                </TableRow>
              </TableHead>
              <TableBody>
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row" align="center">
                    13.120.000
                    </StyledTableCell>
                    <StyledTableCell align="center">
                    13.409.000
                    </StyledTableCell>
                    <StyledTableCell align="center">12.625.000</StyledTableCell>
                    <StyledTableCell align="center">14.069.000</StyledTableCell>
                    <StyledTableCell align="center">17.759.000</StyledTableCell>
                  </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
          {/* <Typography
            variant="subtitle1"
            align="center"
            sx={{  mt: 1 }}
          >
            * Aplica suplemento de 300.000 COP para las salidas del 17 de julio y 21 de agosto *
          </Typography>  */}
          <Typography
            variant="subtitle1"
            align="center"
            sx={{  mt: 1 }}
          >
            ** Precios en pesos colombianos **
          </Typography> 
        </Grid>

       
      </Grid>
    </Container>
  );
}

export default Incluye;
