import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import StarRateIcon from "@mui/icons-material/StarRate";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { Colors } from "../../../styles/theme";
import { SubtitleHeader } from "../../../styles/circuitsdetail";
import { Fragment } from "react";

export default function Hotelesprevistosdetail(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const StarRate = (estrellas) => {
    let content = [];
    for (let i = 0; i < estrellas; i++) {
      content.push(
        <StarRateIcon
          key={i}
          sx={{ fontSize: 16, color: Colors.orange_ibero }}
        />
      );
    }
    return content;
  };

  const renderCircuits = props.hoteles.map((circuit) => (
    <Grid
      item
      key={circuit.id}
      xs={2}
      sm={3}
      md={3}
      display="flex"
      flexDirection={"column"}
      alignItems="center"
    >
      {/* {matches ? (
        <SingleCircuit circuit={circuit} matches={matches} />
      ) : (
        <SingleCircuitDesktop circuit={circuit} matches={matches} />
      )} */}
      <Card sx={{ maxWidth: 285, maxHeight: 220, minHeight: 220 }}>
        <CardContent>
          <Typography
            sx={{ fontSize: 18, color: Colors.blue_ibero }}
            color="text.secondary"
            gutterBottom
          >
            {circuit.HOTEL}
            <br />
            {StarRate(circuit.ESTRELLAS)}
          </Typography>

          <Typography variant="body1">
            {circuit.DIRECCION}
            <br />
            {circuit.CODIGOPOSTAL}. {circuit.CIUDAD}, {circuit.PAIS}
          </Typography>
          <Typography variant="body2">
            Teléfono: {circuit.TELEFONO}
            <br />
            Email: {circuit.MAIL}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  ));
  return (
    <Fragment>
      <SubtitleHeader>HOTELES PREVISTOS</SubtitleHeader>
      <br />
      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <br></br>
        <Typography variant="h6" sx={{ color: Colors.orange_ibero }}>
        7 días antes del inicio de su viaje el operador le confirmará el hotel en cada ciudad
        </Typography>
        <br></br>
      </Grid>
      <br></br>
      <Grid
        container
        spacing={{ xs: 1, md: 1 }}
        justifyContent="center"
        sx={{ margin: `20px 4px 10px 4px` }}
        columns={{ xs: 3, sm: 8, md: 12 }}
      >
        {renderCircuits}
      </Grid>
    </Fragment>
  );
}
