import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import QuienesSomosDesktop from "./quienessomosDesktop";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export default function Titlebar() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const [folletos, setFolletos] = useState([]);
  const params = useParams();
  const tipo = params.tipo;

  useEffect(() => {
    // 👇️ only runs once
    window.scrollTo(0, 0)
  }, []);

    return (
    <>
    <QuienesSomosDesktop folletos={folletos} matches={matches} />
    </>
  );
}
