import * as React from "react";
import "react-vertical-timeline-component/style.min.css";
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

export default function Imagescircuitsdetail(props) {
// console.log(props);
  const getImages = (/** @type {number} */ n) => {
    const images = [];
    for (let i = 0; i <= n; i++) {
        images.push({
            original: props.imagenes[i].original,
            thumbnail: props.imagenes[i].thumbnail
        });
    }
    return images;
};

  return (
    <div style={{marginBotton: "15px", overflow: "auto"}}>
      {/* <ImageGallery items={props.imagenes} autoPlay={true} sx={{with: "1192px"}}/> */}
     <ImageGallery key="ooo" items={props.imagenes} autoPlay={true} sx={{with: "386px", marginBotton: "25px"}} showThumbnails={true} thumbnailPosition={"bottom"} slideInterval={5000} />
    </div>
  );
}
