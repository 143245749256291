import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import { ProductMetaTitleWrapper, ProductImage } from "../../styles/product";
import { Colors } from "../../styles/theme";

const options = {
  useGrouping: true,
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
};

const userLocale =
  navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.language;

export default function ProductMetaTitle({ product, matches }) {
  return (
    <Box sx={{ position: "relative", bgcolor: Colors.orange_light }}>
      <Link to={"/oferta/" + product.Id_Viaje}>
        <ProductImage src={product.URL_Imagen}></ProductImage>
      </Link>
      <Box
        sx={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          // bgcolor: "rgba(0, 0, 0, 0.54)",
          // bgcolor: "#0E67A2",
          // opacity: 0.7,
          color: "white",
          fontWeight: "bold",
          textShadow: "3px 3px 5px black",
          padding: "10px",
        }}
      >
        {/* <ProductMetaTitleWrapper> */}
        {/* <Typography variant="h5">{product.name}</Typography> */}
        <Typography variant={matches ? "h6" : "h6"} lineHeight={1}>
          {/* {product.name} */}
          Desde USD {Number(product.Precio_Desde).toLocaleString(userLocale.substring(0, 2), 
  options,
)}
        </Typography>
      </Box>
      {/* </ProductMetaTitleWrapper> */}
    </Box>
  );
}
