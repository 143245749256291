import {
  AppbarContainer,
  AppbarHeader,
  AppbarHeaderImage,
} from "../../styles/appbar";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import Actions from "./actions";
import { IconButton } from "@mui/material";
import { useUIContext } from "../../context/ui";
import { Link } from "react-router-dom";

export default function AppbarMobile({ matches }) {
  const { setDrawerOpen, setShowSearchBox } = useUIContext();
  return (
    <AppbarContainer sx={{ marginLeft: "30px" }}>
      <IconButton onClick={() => setDrawerOpen(true)}>
        <MenuIcon />
      </IconButton>
      {/*  <AppbarHeader textAlign={"center"} variant="h4">
        My Bags
      </AppbarHeader> */}
      <Link to={"/home"}>
        <AppbarHeaderImage src="/images/ColReservas/logocolreservasazul200.png"  alt="Colreservas" title="Colreservas"/>
      </Link>
      {/*  <IconButton onClick={() => setShowSearchBox(true)}>
        <SearchIcon />
      </IconButton> */}
      {/* <Actions matches={matches} /> */}
    </AppbarContainer>
  );
}
