import styled from "@emotion/styled";
import { List, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Colors } from "../theme";

export const DayImage = styled("img")(({ src, theme }) => ({
  src: `url(${src})`,
  width: "100%",
  borderRadius: "10%",
  background: Colors.light_gray,
  padding: "0px",
  maxWidth: "450px",
  maxHeight: "300px",
     [theme.breakpoints.down("md")]: {
    width: "100%",
    padding: "5px",
  },
}));

export const MapImage = styled("img")(({ src, theme }) => ({
  src: `url(${src})`,
  width: "100%",
  borderRadius: "10%",
  background: Colors.light_gray,
  padding: "0px",
  maxWidth: "500px",
  maxHeight: "400px",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    padding: "5px",
  },
}));

export const HeaderImage = styled("img")(({ src, theme }) => ({
  src: `url(${src})`,
  width: "100%",
  background: Colors.light_gray,
  padding: "0px",
  [theme.breakpoints.down("md")]: {
    width: "80%",
    padding: "24px",
  },
}));

export const TitleContainer = styled(Box)(() => ({
  display: "flex",
  // marginTop: 2,
  marginBottom: 10,
  justifyContent: "center",
  alignItems: "center",
  padding: "0px",
  borderRadius: "15px",
  backgroundColor: Colors.blue_ibero,
}));

export const TitleContainerMobile = styled(Box)(() => ({
  //display: "flex",
  // marginTop: 2,
  marginBottom: 10,
  //justifyContent: "center",
  //alignItems: "center",
  padding: "5px",
  borderRadius: "15px",
  backgroundColor: Colors.blue_ibero,
}));

export const TitleHeader = styled(Typography)(() => ({
  // padding: "5px 0px 0px 10px",
  // flexGrow: 1,
  fontSize: "1.7em",
  //fontFamily: '"Arial", "cursive"',
  color: Colors.yellow,
}));

export const TitleHeaderMobile = styled(Typography)(() => ({
  fontSize: "1.2em",
  color: Colors.yellow,
}));

export const TitleHeader2 = styled(Typography)(() => ({
  padding: "0px",
  flexGrow: 1,
  fontSize: "1em",
  //fontFamily: '"Arial", "cursive"',
  color: Colors.white,
}));
export const TitleHeader2Mobile = styled(Typography)(() => ({
  paddingLeft: "7px",
  flexGrow: 1,
  fontSize: "0.9em",
  fontFamily: '"Arial", "cursive"',
  color: Colors.white,
}));

export const SubtitleHeader = styled(Typography)(() => ({
  padding: "2px",
  flexGrow: 1,
  fontSize: "1.3em",
  //fontFamily: '"Arial", "cursive"',
  color: Colors.blue_ibero,
}));

export const SubtitleHeaderNoIncluye = styled(Typography)(() => ({
  padding: "2px",
  flexGrow: 1,
  fontSize: "1.5em",
  //fontFamily: '"Arial", "cursive"',
  color: Colors.orange_ibero,
}));

// export const AppbarHeaderImage = styled("img")(({ src, theme }) => ({
// src: `url(${src})`,
// // backgroundImage: `url(${src})`,
// // backgroundRepeat: "no-repeat",
// // backgroundPosition: "center",
// width: "250px",
// [theme.breakpoints.down("md")]: {
//   width: "150px",
// },
// [theme.breakpoints.down("sm")]: {
//   width: "100px"
//   // height: "300px",
// },
// marginRight:"100px"
// }));

export const ActionIconsContainerMobile = styled(Box)(() => ({
  display: "flex",
  background: Colors.shaft,
  position: "fixed",
  bottom: 0,
  left: 0,
  width: "100%",
  alignItems: "center",
  zIndex: 99,
  borderTop: `1px solid ${Colors.border}`,
}));

export const ActionIconsContainerDesktop = styled(Box)(() => ({
  flexGrow: 0,
}));

export const BoxItinerario = styled(Box)(() => ({
  /* maxWidth: "630px",
  minWidth: "630px", */ 
  width: "100%"
}));

export const MyList = styled(List)(({ type }) => ({
  display: type === "row" ? "flex" : "block",
  flexGrow: 1,
  justifyContent: "center",
  alignItems: "center",
  padding: "0px",
}));
