import styled from "@emotion/styled";
import { Button, Card, IconButton, useMediaQuery } from "@mui/material";
import { Box, height } from "@mui/system";
import { slideInBottom, slideInRight } from "../../animation";
import theme, { Colors } from "../theme";
import { useParams } from "react-router-dom";



export const Circuit = styled(Card)(({ tipo, theme }) => (
  {
    
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  
  [theme.breakpoints.up("md")]: {
    position: "relative",
  },
  backgroundColor: `${tipo === "Ofertas" ? Colors.orange_light : "aliceblue"}`,
}));

export const CircuitImage = styled("img")(({ src, theme }) => ({
  width: "100%",
  height: "100%",
  background: Colors.light_gray,
  padding: '0px',
  [theme.breakpoints.down("md")]: {
    width: "100%", 
    padding: '0px',
  },  
}));

export const CircuitActionButton = styled(IconButton)(() => ({
  background: Colors.orange_ibero,
    margin: 2,
    padding: 2,
  color: Colors.white,
  "&:hover": {
    background: Colors.blue_ibero
  }
}));


export const CircuitFavButton = styled(CircuitActionButton)(({ isfav, theme }) => ({
  //color: isfav ? Colors.orange_ibero : Colors.white,  
  [theme.breakpoints.up("md")]: {
    position: "absolute",
    right: 0,
    top: 0,
  }
}));

export const CircuitAddToCart = styled(Button, {
  shouldForwardProp: (prop) => prop !== "show",
})(({ show, theme }) => ({
  width: "120px",
  fontSize: "12px",
  [theme.breakpoints.up("md")]: {
    position: "absolute",    
    bottom: "2%",
    width: "300px",
    padding: "10px 5px",
    animation:
      show &&
      `${slideInBottom} 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both`,
  },
  background: Colors.secondary,
  opacity: 0.9,
}));

export const CircuitMetaWrapper = styled(Box)(({matches, tipo, theme}) => ({
  padding: "10px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  backgroundColor:  `${tipo === "Ofertas" ? Colors.orange_light : "aliceblue"}`,
  height: `${matches ? "80px" : "105px"}`
}));

/* export const CircuitMetaWrapperOfertas = styled(Box)(({theme}) => ({
  padding: "10px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  backgroundColor:  Colors.orange_light,
  height:"200px"
}));

export const CircuitMetaWrapperMD = styled(Box)(({theme}) => ({
  padding: "10px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  backgroundColor: "aliceblue",
  height:"150px"
}));

export const CircuitMetaWrapperOfertasMD = styled(Box)(({theme}) => ({
  padding: "10px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  backgroundColor:  Colors.orange_light,
  height:"150px"
})); */

export const CircuitMetaTitleWrapper = styled(Box)(({theme}) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  bgcolor: "rgba(0, 0, 0, 0.54) !important" ,
  color: "white",
  padding: "10px"
}));

export const CircuitActionsWrapper = styled(Box)(({ show, theme }) => ({ 
  [theme.breakpoints.up("md")]: {
    display: show ? 'visible' : 'none',
    position: "absolute",
    right: 0,
    top: '20%',
    animation: show && `${slideInRight} 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both`,
  }
}));

