import * as React from "react";
import ProductCategories from "./modules/views/ProductCategories";
import ProductSmokingHero from "./modules/views/ProductSmokingHero";
import AppFooter from "./modules/views/AppFooter";
import ProductHero from "./modules/views/ProductHero";
import ProductValues from "./modules/views/ProductValues";
import ProductHowItWorks from "./modules/views/ProductHowItWorks";
import ProductCTA from "./modules/views/ProductCTA";
import Incluye from "./modules/views/Incluye";
import Clausula from "./modules/views/Clausula";
import Hoteles from "./modules/views/Hoteles";
import Precios from "./modules/views/Precios";
import Europack from "./modules/views/Europack";
import AppAppBar from "./modules/views/AppAppBar";
import withRoot from "./modules/withRoot";
import Itinerario from "./modules/views/Itinerario";
import Condiciones from "./modules/views/Condiciones";
import { UIProvider } from "../../../context/ui";
import Appbar from "../../appbar";
import itinerarioData from "../../../data/landingpages/EuropaAlcance/Itinerario.json"
import incluyeData from "../../../data/landingpages/EuropaAlcance/Incluye.json"
import condicionesData from "../../../data/landingpages/EuropaAlcance/Condiciones.json"
import { useMediaQuery } from "@mui/material";
import Footer from "../../footer";
import theme from "./modules/theme";

function Index() {
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  React.useEffect(() => {
    document.title = "Viajes Iberoluna - Europa a su Alcance";
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <UIProvider>
        <Appbar />
        {/* <AppAppBar /> */}
        <ProductHero />
        <ProductValues />
        <Incluye incluyeData={incluyeData}/>
        <ProductHowItWorks />        
        <Precios/>
        {/* <Europack incluyeData={incluyeData}/> */}
        <ProductCategories /> 
        <Itinerario tipo={"Itinerario"} itinerarioData={itinerarioData} matches={matches}/>
        {/* <Hoteles/> */}
        <Condiciones condicionesData={condicionesData}/>
        {/* <ProductCTA tipo={"Itinerario"} itinerarioData={itinerarioData} matches={matches}/> */}
        {/* <ProductSmokingHero /> */}
        {/* <Clausula /> */}
        <Footer />
      </UIProvider>
    </React.Fragment>
  );
}

export default withRoot(Index);
