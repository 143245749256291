import {
  AppBar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Modal,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import MailIcon from "@mui/icons-material/Mail";
import CommentIcon from "@mui/icons-material/Comment";
import theme, { Colors } from "../../styles/theme";
import {
  AppbarContainer,
  AppbarHeaderImage,
  MyList,
} from "../../styles/appbar";
import { useUIContext } from "../../context/ui";
import { Link, useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useState, useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import ContactModal from "../ContactModal"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 1,
};

export default function AppbarDesktop({ matches }) {
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { setShowSearchBox } = useUIContext();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openM, setOpenM] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseM = () => setOpenM(false);

  const handleOnClickHome = useCallback(
    () => navigate("/Home", { replace: true }),
    [navigate]
  );
  const handleOnClickCatalogos = useCallback(
    () => navigate("/Catalogos", { replace: true }),
    [navigate]
  );
  const handleOnClickCircuitos = useCallback(
    () => navigate("/results/Circuitos/*", { replace: true }),
    [navigate]
  );
  const handleOnClickOfertas = useCallback(
    () => navigate("/results/Ofertas/*", { replace: true }),
    [navigate]
  );
  const handleOnClickQuienesSomos = useCallback(
    () => navigate("/quienessomos", { replace: true }),
    [navigate]
  );
  const handleOnClickContactenos = useCallback(
    () => navigate("/contacto", { replace: true }),
    [navigate]
  );
  const handleOnClickReserva = useCallback(
    () => navigate("/consultareserva/*/*", { replace: true }),
    [navigate]
  );
  const handleOnClickPresupuesto = useCallback(
    () => navigate("/consultapresupuesto/*/*", { replace: true }),
    [navigate]
  );

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     console.log("Function executed after a few minutes");
  //     setOpenM(true);
  //   }, 60000); 

  //   // Cleanup function to clear the timeout if the component unmounts before the timeout completes
  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, []);

 
  return (
    <AppbarContainer sx={{ backgroundColor: Colors.light_gray, boxShadow: 1 }}>
      {/* <AppbarHeader variant="h4">My Bags</AppbarHeader> */}
      <Link to={"/home"}>
        <AppbarHeaderImage
          src="/images/ColReservas/logocolreservasazul200.png"
          alt="Colreservas"
          title="Colreservas"
        />
      </Link>
      <MyList type="row">
        <ListItemButton align="center" onClick={handleOnClickHome}>
          <ListItemText align="center" primary="INICIO" />
        </ListItemButton>
        <ListItemButton align="center" onClick={handleOnClickCatalogos}>
          <ListItemText align="center" primary="DESTINOS" />
        </ListItemButton>
        <ListItemButton align="center" onClick={handleOnClickCircuitos}>
          <ListItemText align="center" primary="¿DONDE DESEA VIAJAR?" />
        </ListItemButton>
        {/* <ListItemButton align="center" onClick={handleOnClickOfertas}>
          <ListItemText align="center" primary="BUSCADOR DE OFERTAS" />
        </ListItemButton> */}
        <ListItemButton
          align="center"
          onClick={() =>
            window.open("https://colreservas.com/contacto.php", "_blank")
          }
        >
          <ListItemText align="center" primary="CONTÁCTENOS" />
        </ListItemButton>
        <ListItemButton align="center" onClick={handleClick}>
          <ListItemText align="center" primary="CONSULTAS" />
        </ListItemButton>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem onClick={handleOnClickReserva}>
            Consulta de Reservas
          </MenuItem>
          <MenuItem onClick={handleOnClickPresupuesto}>
            Consulta de Presupuesto
          </MenuItem>
        </Menu>
        {/*  <ListItemButton onClick={() => setShowSearchBox(true)}>
          <ListItemIcon>
            <SearchIcon />
          </ListItemIcon>
        </ListItemButton> */}
      </MyList>
      {/* <Actions matches={matches} /> */}

      <Dialog fullScreen={fullScreen} open={openM} onClose={handleCloseM}>
        <DialogTitle sx={{ m: 0, p: 1 }}>
          <br></br>
          <Typography
            align="center"
            gutterBottom
            variant="h4"
            component="div"
            sx={{
              color: Colors.orange_ibero,
              fontWeight: "Medium",
            }}
          >
            ¿Necesitas asesoría para tu viaje?
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleCloseM}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
        <ContactModal onClick={handleCloseM}></ContactModal>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Subscribe</Button>
        </DialogActions> */}
      </Dialog>

      {/* <Modal
        open={openM}
        onClose={handleCloseM}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack
            direction={matches ? "column" : "row"}          
            spacing={1}
          >
            <img
              src="/images/Contact.jpg"
              alt="Contacto"
              width={matches ? "250px" : "250px"}
            />
            <Card sx={{ width: "100%" }}>
              <CardContent>
                <AppBar position="static">
                  <Toolbar>
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{ flexGrow: 1 }}
                    ></Typography>
                    <Button color="inherit">Login</Button>
                  </Toolbar>
                </AppBar>
                <Typography
                  align="center"
                  gutterBottom
                  variant="h6"
                  component="div"
                  sx={{
                    color: Colors.orange_ibero,
                    fontWeight: "Medium",
                  }}
                >
                  Lo invitamos a dejar sus datos para ayudarlo a realizar el
                  viaje de sus sueños
                </Typography>
                <br></br>
                <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                  <InputLabel htmlFor="standard-adornment-amount">
                    Nombre y Apellido:
                  </InputLabel>
                  <Input
                    id="nombreApellido"
                    name="nombreApellido"                   
                    startAdornment={
                      <InputAdornment position="start">
                        <PersonIcon
                          sx={{
                            color: "action.active",
                            mr: 1,
                            my: 0.5,
                          }}
                        />
                      </InputAdornment>
                    }
                  />
                </FormControl>

                <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                  <InputLabel htmlFor="standard-adornment-amount">
                    Celular:
                  </InputLabel>
                  <Input
                    id="celular"
                    name="celular"              
                    startAdornment={
                      <InputAdornment position="start">
                        <PhoneIphoneIcon
                          sx={{
                            color: "action.active",
                            mr: 1,
                            my: 0.5,
                          }}
                        />
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                  <InputLabel htmlFor="standard-adornment-amount">
                    Email:
                  </InputLabel>
                  <Input
                    id="email"
                    name="email"                
                    startAdornment={
                      <InputAdornment position="start">
                        <MailIcon
                          sx={{
                            color: "action.active",
                            mr: 1,
                            my: 0.5,
                          }}
                        />
                      </InputAdornment>
                    }
                  />
                </FormControl>              
              </CardContent>
              <CardActions></CardActions>
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <LoadingButton
                  disabled={false}
                  variant="contained"
                  sx={{
                    minWidth: "400px",
                    backgroundColor: Colors.orange_ibero,
                  }}
                  loading={false}
                >
                  Enviar mensaje
                </LoadingButton>
              </Grid>
            </Card>
          </Stack>
        </Box>
      </Modal> */}
    </AppbarContainer>
  );
}
