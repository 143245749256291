import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import CatalogosDesktop from "./catalogosDesktop";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export default function Titlebar() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const [folletos, setFolletos] = useState([]);
  const params = useParams();
  const tipo = params.tipo;

  useEffect(() => {
    // 👇️ only runs once
    window.scrollTo(0, 0)
    //setPaises(fillPaises());
    fillFolletos();
  }, []);

  const fillFolletos = async () => {
        //  const url ="https://iberolunatravel.online/wse/api/GetFolletosCircuito";
        const url = "../../data/folletos.json";
       const resp = await axios.get(url, {
         headers: {
           "Access-Control-Allow-Origin": "*",
         },
       })
     
       setFolletos(resp.data);
   };

  return (
    <>
    <CatalogosDesktop folletos={folletos} matches={matches} />
    </>
  );
}
