import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';

const ImageBackdrop = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  background: '#000',
  opacity: 0.2,
  transition: theme.transitions.create('opacity'),
}));

const ImageIconButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  display: 'block',
  padding: 0,
  borderRadius: 0,
  height: '40vh',
  [theme.breakpoints.down('md')]: {
    width: '100% !important',
    height: 100,
  },
  '&:hover': {
    zIndex: 1,
    cursor: "default"
  },
  '&:hover .imageBackdrop': {
    opacity: 0.15,
  },
  '&:hover .imageMarked': {
    opacity: 0,
  },
  '&:hover .imageTitle': {
    border: '4px solid currentColor',
  },
  '& .imageTitle': {
    position: 'relative',
    padding: `${theme.spacing(2)} ${theme.spacing(4)} 14px`,
  },
  '& .imageMarked': {
    height: 3,
    width: 18,
    background: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
}));

const images = [
  {
    url: 'https://iberolunatravel.online//images/imageslarge/zaragoza_20190923133054.jpg?auto=format&fit=crop&w=400',
    title: 'Zaragoza',
    width: '30%',
  },
  {
    url: 'https://iberolunatravel.online//images/imageslarge/paris_20200810140257.jpg?auto=format&fit=crop&w=400',
    title: 'París',
    width: '40%',
  },
  {
    url: 'https://iberolunatravel.online//images/imageslarge/san%20sebastian_20210421181337.jpg?auto=format&fit=crop&w=400',
    title: 'San Sebastián',
    width: '30%',
  },
  {
    url: 'https://iberolunatravel.online//images/imageslarge/spain_04.jpg?auto=format&fit=crop&w=400',
    title: 'Barcelona',
    width: '38%',
  },
  {
    url: 'https://iberolunatravel.online//images/imageslarge/venecia_20201112135400.jpg?auto=format&fit=crop&w=400',
    title: 'Venecia',
    width: '38%',
  },
  {
    url: 'https://iberolunatravel.online//images/imageslarge/florencia_20201106115445.jpg?auto=format&fit=crop&w=400',
    title: 'Florencia',
    width: '24%',
  },
  {
    url: 'https://iberolunatravel.online//images/imageslarge/roma_20200821100348.jpg?auto=format&fit=crop&w=400',
    title: 'Roma',
    width: '30%',
  },
  {
    url: 'https://iberolunatravel.online//images/imageslarge/madrid_20211213113604.jpg?auto=format&fit=crop&w=400',
    title: 'Madrid',
    width: '30%',
  },
  {
    url: 'https://iberolunatravel.online//images/imageslarge/costa%20azul_20201117182930.jpg?auto=format&fit=crop&w=400',
    title: 'Costa Azul',
    width: '40%',
  },
  {
    url: 'https://iberolunatravel.online//images/imageslarge/pisa_20210120191145.jpg?auto=format&fit=crop&w=400',
    title: 'Pisa',
    width: '30%',
  },
  {
    url: 'https://iberolunatravel.online//images/imageslarge/Lourdes_20210419182706.jpg?auto=format&fit=crop&w=400',
    title: 'Lourdes',
    width: '40%',
  },
  {
    url: 'https://iberolunatravel.online//images/imageslarge/asis_20210922141141.jpg?auto=format&fit=crop&w=400',
    title: 'Asis',
    width: '30%',
  },
  {
    url: 'https://iberolunatravel.online//images/imageslarge/innsbruck_20201118192830.jpg?auto=format&fit=crop&w=400',
    title: 'Innsbruck',
    width: '30%',
  },
  {
    url: 'https://iberolunatravel.online//images/imageslarge/zurich_20201118201307.jpg?auto=format&fit=crop&w=400',
    title: 'Zurich',
    width: '40%',
  },
  {
    url: 'https://iberolunatravel.online//images/imageslarge/Lucerna_20210419182908.jpg?auto=format&fit=crop&w=400',
    title: 'Lucerna',
    width: '30%',
  }
];

export default function ProductCategories() {
  return (
    <Container component="section" sx={{ mt: 8, mb: 4 }}>
      <Typography variant="h4" marked="center" align="center" component="h2">
        Galerias de imagenes
      </Typography>
      <Box sx={{ mt: 8, display: 'flex', flexWrap: 'wrap' }}>
        {images.map((image) => (
          <ImageIconButton
            key={image.title}
            style={{
              width: image.width,
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                backgroundSize: 'cover',
                backgroundPosition: 'center 40%',
                backgroundImage: `url(${image.url})`,
              }}
            />
            <ImageBackdrop className="imageBackdrop" />
            <Box
              sx={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'common.white',
              }}
            >
              <Typography
                component="h3"
                variant="h6"
                color="inherit"
                className="imageTitle"
              >
                {image.title}
                <div className="imageMarked" />
              </Typography>
            </Box>
          </ImageIconButton>
        ))}
      </Box>
    </Container>
  );
}
