import * as React from "react";
import Box from "@mui/material/Box";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import LocalHotelOutlinedIcon from "@mui/icons-material/LocalHotelOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import ImportContactsOutlinedIcon from "@mui/icons-material/ImportContactsOutlined";
import { Colors } from "../../../styles/theme";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";
import { Fab, Grid, IconButton } from "@mui/material";

export default function InformationcircuitsdetailMobile(props) {
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleListItemClick = (event, index) => {
    switch (index) {
      case 5:
        window.open(props.word, "_blank");
        break;
      case 6:
        window.open(props.pdf, "_blank");
        break;
      default:
        setSelectedIndex(index);
        props.onClick(index);
    }
  };

  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={2} sm={2} md={2} sx={{textAlign: 'center'}} >
            <Fab
              variant="extended"
              aria-label="delete"
              size="small"
              sx={{
                bgcolor: Colors.orange_ibero,
                color: Colors.white,
                textTransform: "none",
                width: "170px",
                "&:hover": {
                  backgroundColor: Colors.blue_ibero}
              }}
              onClick={(event) => handleListItemClick(event, 0)}
            >
              <ImportContactsOutlinedIcon
                fontSize="inherit"
                sx={{ color: Colors.white, marginRight: "5px" }}
              />
              Incluye / Itinerario
            </Fab>
          </Grid>
          <Grid item xs={2} sm={2} md={2} sx={{textAlign: 'center'}} >
            <Fab
              variant="extended"
              aria-label="delete"
              size="small"
              sx={{
                bgcolor: Colors.orange_ibero,
                color: Colors.white,
                textTransform: "none",
                width: "170px",
                "&:hover": {
                  backgroundColor: Colors.blue_ibero
                }            
              }}
              onClick={(event) => handleListItemClick(event, 3)}
            >
              <ImportContactsOutlinedIcon
                fontSize="inherit"
                sx={{ color: Colors.white, marginRight: "5px" }}
              />
              Itinerario detallado
            </Fab>
          </Grid>
          <Grid item xs={2} sm={2} md={2} sx={{textAlign: 'center'}} >
            <Fab
              variant="extended"
              aria-label="delete"
              size="small"
              sx={{
                bgcolor: Colors.orange_ibero,
                color: Colors.white,
                textTransform: "none",
                width: "170px",
                "&:hover": {
                  backgroundColor: Colors.blue_ibero}
              }}
              onClick={(event) => handleListItemClick(event, 1)}
            >
              <CameraAltOutlinedIcon
                fontSize="inherit"
                sx={{ color: Colors.white, marginRight: "5px" }}
              />
              Fotos
            </Fab>
          </Grid>
          <Grid item xs={2} sm={2} md={2} sx={{textAlign: 'center'}} >
            <Fab
              variant="extended"
              aria-label="delete"
              size="small"
              sx={{
                bgcolor: Colors.orange_ibero,
                color: Colors.white,
                textTransform: "none",
                width: "170px",
                "&:hover": {
                  backgroundColor: Colors.blue_ibero}
              }}
              onClick={(event) => handleListItemClick(event, 2)}
            >
              <LocalHotelOutlinedIcon
                fontSize="inherit"
                sx={{ color: Colors.white, marginRight: "5px" }}
              />
              Hoteles previstos
            </Fab>
          </Grid>
          <Grid item xs={2} sm={2} md={2} sx={{textAlign: 'center'}} >
            <Fab
              variant="extended"
              aria-label="delete"
              size="small"
              sx={{
                bgcolor: Colors.orange_ibero,
                color: Colors.white,
                textTransform: "none",
                width: "170px",
                "&:hover": {
                  backgroundColor: Colors.blue_ibero}
              }}
              onClick={(event) => handleListItemClick(event, 5)}
            >
              <TextSnippetOutlinedIcon
                fontSize="inherit"
                sx={{ color: Colors.white, marginRight: "5px" }}
              />
              Itinerario Word
            </Fab>
          </Grid>
          <Grid item xs={2} sm={2} md={2} sx={{textAlign: 'center'}} >
            <Fab
              variant="extended"
              aria-label="delete"
              size="small"
              sx={{
                bgcolor: Colors.orange_ibero,
                color: Colors.white,
                textTransform: "none",
                width: "170px",
                "&:hover": {
                  backgroundColor: Colors.blue_ibero}
              }}
              onClick={(event) => handleListItemClick(event, 6)}
            >
              <PictureAsPdfOutlinedIcon
                fontSize="inherit"
                sx={{ color: Colors.white, marginRight: "5px" }}
              />
              Itinerario PDF
            </Fab>
          </Grid>
          <Grid item xs={2} sm={2} md={2} sx={{textAlign: 'center'}} >
            <Fab
              variant="extended"
              aria-label="delete"
              size="small"
              sx={{
                bgcolor: Colors.orange_ibero,
                color: Colors.white,
                textTransform: "none",
                width: "170px",
                "&:hover": {
                  backgroundColor: Colors.blue_ibero}
              }}
              onClick={(event) => handleListItemClick(event, 99)}
            >
              <CalculateOutlinedIcon
                fontSize="inherit"
                sx={{ color: Colors.white, marginRight: "5px" }}
              />
              Cotice y reserve su Viaje
            </Fab>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}
