import * as React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import { Colors } from "../../../styles/theme";
import { Grid, Stack, Typography } from "@mui/material";
import {
  DayImage,
  SubtitleHeader,
  BoxItinerario,
} from "../../../styles/circuitsdetail";
import parse from "html-react-parser";
import { Box } from "@mui/system";
import ReactPlayer from "react-player";
import { useEffect } from "react";

export default function Itinerary(props) {

  return (
    <React.Fragment>
      {props.flagWait ? (
        <></>
      ) : (
        <>
          <Box
            id="divItinerario"
            sx={{ padding: props.flagDialog ? "30px" : "0px" }}
          >
            <SubtitleHeader
              style={{ paddingBottom: "15px", marginTop: "45px" }}
            >
              ITINERARIO {props.tipo} DE VIAJE
            </SubtitleHeader>
            <VerticalTimeline
              lineColor={Colors.orange_ibero}
              layout="1-column-left"
              animate={!props.matches}
            >
              {props.itinerario.map((item, i) => (
                <VerticalTimelineElement
                  key={i}
                  className="vertical-timeline-element--work"
                  sx={{ paddingLeft: "2px", paddingRight: "0px" }}
                  //contentStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
                  contentArrowStyle={{
                    borderRight: "7px solid " + Colors.orange_ibero + "",
                  }}
                  iconStyle={{ background: Colors.blue_ibero, color: "#fff" }}
                  icon={<WorkHistoryIcon />}
                >
                  <h3
                    className="vertical-timeline-element-title"
                    style={{ color: Colors.orange_ibero }}
                  >
                    {item.TITULO}
                  </h3>
                  <h4
                    className="vertical-timeline-element-subtitle"
                    style={{ color: Colors.blue_ibero }}
                  >
                    Día {item.DIA}
                  </h4>

                  {!props.matches ? (
                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <BoxItinerario sx={{ textAlign: "justify" }}>
                          {parse(
                            props.tipo === "DETALLADO"
                              ? item.DESCRIPCIONDETALLADA
                              : item.DESCRIPCIONRESUMEN,
                            props.matches
                          )}
                        </BoxItinerario>
                      </Grid>
                      <Grid item xs={4}>
                        {item.VIDEO === "" ? (
                          <DayImage src={item.IMAGEN} alt={item.IMAGENNOMBRE} title={item.IMAGENNOMBRE}></DayImage>
                        ) : (
                          <ReactPlayer
                            url={item.VIDEO}
                            width="100%"
                            height="100%"
                            controls={true}
                          />
                        )}
                      </Grid>
                    </Grid>
                  ) : (
                    /*   <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="space-between"
                      >
                        <BoxItinerario sx={{ textAlign: "justify" }}>
                          {parse(
                            props.tipo === "DETALLADO"
                              ? item.DESCRIPCIONDETALLADA
                              : item.DESCRIPCIONRESUMEN,
                            props.matches
                          )}
                        </BoxItinerario>
                        {item.VIDEO === "" ? (
                          <DayImage src={item.IMAGEN}></DayImage>
                        ) : (
                          <ReactPlayer
                            url={item.VIDEO}
                            width="100%"
                            height="100%"
                            controls={true}
                          />
                        )}
                      </Stack> */
                    <Stack spacing={2} justifyContent="space-between">
                      <Box sx={{ textAlign: "justify" }}>
                        {parse(
                          props.tipo === "DETALLADO"
                            ? item.DESCRIPCIONDETALLADA
                            : item.DESCRIPCIONRESUMEN,
                          props.matches
                        )}
                      </Box>
                      {item.VIDEO === "" ? (
                        <DayImage src={item.IMAGEN}></DayImage>
                      ) : (
                        <ReactPlayer
                          url={item.VIDEO}
                          width="100%"
                          height="100%"
                          controls={true}
                        />
                      )}
                    </Stack>
                  )}
                </VerticalTimelineElement>
              ))}
            </VerticalTimeline>

            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <br></br>
              <Typography variant="h6" sx={{ color: Colors.orange_ibero }}>
                Circuitos operados y garantizados por nuestro aliado comercial
                Europamundo Vacaciones
              </Typography>
              <br></br>
            </Grid>
          </Box>
        </>
      )}
    </React.Fragment>
  );
}
