import * as React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";
import { Colors } from "../../../../../styles/theme";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import {
  BoxItinerario,
  DayImage
} from "../../../../../styles/circuitsdetail";
import ReactPlayer from "react-player";
import { Stack } from "@mui/material";

function Itinerario(props) {
  return (
    <Box
      component="section"
      sx={{ display: "flex", bgcolor: "secondary.light", overflow: "hidden" }}
    >
      <Container
        sx={{
          mt: 10,
          mb: 8,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          src="/Images/productCurvyLines.png"
          alt="curvy lines"
          sx={{
            pointerEvents: "none",
            position: "absolute",
            top: -180,
            opacity: 0.7,
          }}
        />
        <>
          <Typography
            variant="h4"
            marked="center"
            component="h2"
            sx={{ mb: 8 }}
            align="center"
          >
            Itinerario de viaje
          </Typography>
          <Box
            id="divItinerario"
            sx={{ padding: props.flagDialog ? "30px" : "0px" }}
          >
            {/* <SubtitleHeader
              style={{ paddingBottom: "15px", marginTop: "45px" }}
            >
              ITINERARIO DE VIAJE
            </SubtitleHeader> */}
            <VerticalTimeline
              lineColor={Colors.orange_ibero}
              layout="1-column-left"
              animate={!props.matches}
            >
              {props.itinerarioData.map((item, i) => (
                <VerticalTimelineElement
                  key={i}
                  className="vertical-timeline-element--work"
                  sx={{ paddingLeft: "2px", paddingRight: "0px" }}
                  //contentStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
                  contentArrowStyle={{
                    borderRight: "7px solid " + Colors.orange_ibero + "",
                  }}
                  iconStyle={{ background: Colors.blue_ibero, color: "#fff" }}
                  icon={<WorkHistoryIcon />}
                >
                  <h3
                    className="vertical-timeline-element-title"
                    style={{ color: Colors.orange_ibero }}
                  >
                    {item.TITULO}
                  </h3>
                  <br></br>
                  {/* <h4
                    className="vertical-timeline-element-subtitle"
                    style={{ color: Colors.blue_ibero }}
                  >
                    Día {item.DIA}
                  </h4> */}

                  {!props.matches ? (
                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <BoxItinerario sx={{ textAlign: "justify" }}>
                          {item.DESCRIPCIONDETALLADA}
                        </BoxItinerario>
                      </Grid>
                      <Grid item xs={4}>
                        {item.VIDEO === "" ? (
                          <DayImage
                            src={item.IMAGEN}
                            alt={item.IMAGENNOMBRE}
                            title={item.IMAGENNOMBRE}
                            style={{                            
                              boxShadow: '1px 2px 9px #F4AAB9',
                            }}
                          ></DayImage>
                        ) : (
                          <ReactPlayer
                            url={item.VIDEO}
                            width="100%"
                            height="100%"
                            controls={true}
                          />
                        )}
                      </Grid>
                    </Grid>
                  ) : (
                    /*   <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="space-between"
                      >
                        <BoxItinerario sx={{ textAlign: "justify" }}>
                          {parse(
                            props.tipo === "DETALLADO"
                              ? item.DESCRIPCIONDETALLADA
                              : item.DESCRIPCIONRESUMEN,
                            props.matches
                          )}
                        </BoxItinerario>
                        {item.VIDEO === "" ? (
                          <DayImage src={item.IMAGEN}></DayImage>
                        ) : (
                          <ReactPlayer
                            url={item.VIDEO}
                            width="100%"
                            height="100%"
                            controls={true}
                          />
                        )}
                      </Stack> */
                    <Stack
                      spacing={2}
                      direction="column"
                      justifyContent="space-between"
                    >
                      <Box sx={{ textAlign: "justify" }}>
                        {item.DESCRIPCIONDETALLADA}
                      </Box>
                      {item.VIDEO === "" ? (
                        <DayImage
                          src={item.IMAGEN}
                          style={{                            
                            boxShadow: '1px 2px 9px #F4AAB9',
                          }}
                        ></DayImage>
                      ) : (
                        <ReactPlayer
                          url={item.VIDEO}
                          width="100%"
                          height="100%"
                          controls={true}
                        />
                      )}
                    </Stack>
                  )}
                </VerticalTimelineElement>
              ))}
            </VerticalTimeline>
          </Box>
        </>
      </Container>
    </Box>
  );
}

export default Itinerario;
