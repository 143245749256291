import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import SalidasGrupalesDesktop from "./salidasGrupalesDesktop";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export default function Titlebar() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const [mosaicos, setMosaicos] = useState([]);
  const params = useParams();
  const tipo = params.tipo;
  

  useEffect(() => {
    // 👇️ only runs once
    window.scrollTo(0, 0)
    //setPaises(fillPaises());
    fillSalidasGrupales();
  }, []);

  const fillSalidasGrupales = async () => {
    // const url = "https://iberolunatravel.online/wse/api/getmosaicos?seccion=Grupal";
    const url = "../../data/salidasGrupales.json";
       const resp = await axios.get(url, {
         headers: {
           "Access-Control-Allow-Origin": "*",
         },
       })
     
       setMosaicos(resp.data);
   };

  return (
    <>
    <SalidasGrupalesDesktop mosaicos={mosaicos} matches={matches} />
    </>
  );
}
