import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import AsistenteDesktop from "./asistenteDesktop";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export default function Titlebar() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const [folletos, setFolletos] = useState([]);
  const params = useParams();
  const [ciudades, setCiudades] = useState([]);
  const [paises, setPaises] = useState([]);

  useEffect(() => {
    // 👇️ only runs once
    window.scrollTo(0, 0)
    fillPaises();    
    fillCiudades();    
  }, []);

  const fillPaises = async () => {
    // const url = "https://iberolunatravel.online/wse/api/getPaises";
    const url = "../../data/paises.json";
    const resp = await axios.get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });    
    setPaises(resp.data);   
  };

  const fillCiudades = async () => {
    // const url = "https://iberolunatravel.online/wse/api/getCiudades";
    const url = "../../data/ciudades.json";
    const resp = await axios.get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
    setCiudades(resp.data);
  };

    return (
    <>
    <AsistenteDesktop paises={paises} ciudades={ciudades} matches={matches} />
    </>
  );
}
