import * as React from "react";
import Button from "../components/Button";
import Typography from "../components/Typography";
import ProductHeroLayout from "./ProductHeroLayout";
import ReactImageGallery from "react-image-gallery";
import { Avatar, Box, createTheme, useMediaQuery } from "@mui/material";
import { Fragment } from "react";
import { ThemeProvider } from "@emotion/react";
import { Colors } from "../../../../../styles/theme";

const theme = createTheme({
  typography: {
    fontFamily: ["Merienda", "cursive"].join(","),
  },
});

// const backgroundImage =
//   "https://iberolunatravel.online//images/imagesoriginal/venecia_20201112135400.jpg?auto=format&fit=crop&w=1400";

const backgroundImage =
  "https://iberolunatravel.online//images/imagesoriginal/Roma_20210421235358.jpg";

export default function ProductHero() {
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Fragment>
      {/* Increase the network loading priority of the background image. */}

      {/* <div style={{height: 1000,
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize:"100%"
        }}> */}
      <div style={{ position: "relative", textAlign: "center" }}>
        <img
          src={backgroundImage}
          alt="increase priority"
          style={{ width: "100%", height: "auto", p: 0, m: 0 }}
        />
        {/* <div
          style={{
            position: "absolute",
            top: "50px",
            right: "50px",
          }}
        >
          <Avatar
            sx={{
              //bgcolor: Colors.blue_ibero,
              textAlign: "center",
              fontSize: "200px",

              cursor: "pointer",
            }}
          >
            <img src="/images/SuperOferta.png"></img>
          </Avatar>
        </div> */}

        {/* <div
          style={{
            position: "absolute",
            bottom: "50px",
            right: "16px",
          }}
        >
          <Avatar
            sx={{
              bgcolor: Colors.orange_ibero,
              textAlign: "center",
              fontSize: "48px",
              padding: 10,
            }}
          >
            20 días
          </Avatar>
        </div> */}
        <div
          style={!matches ? {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "rgba(201, 215, 224, 0.7)",
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
            padding: "10px",
          } : {           
            backgroundColor: "rgba(201, 215, 224, 0.7)",
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
            padding: "5px",
          }}
        >
          <ThemeProvider theme={theme}>
            <Typography
              color={Colors.blue_ibero}
              align="center"
              variant={!matches ? "h2": "h4"}
              marked="center"
            >
              Europa Completísima
            </Typography>
            <br></br>
            <Typography color={Colors.orange_ibero} align="center" variant={!matches ? "h4": "h5"}>
              Desde $ 13.120.000
            </Typography>
          </ThemeProvider>
        </div>

        {/* <Typography
          color="inherit"
          align="center"
          variant="h5"
          sx={{ mb: 4, mt: { xs: 4, sm: 10 } }}
        >
          Enjoy secret offers up to -70% off the best luxury hotels every
          Sunday.
        </Typography>
        <Button
          color="secondary"
          variant="contained"
          size="large"
          component="a"
          href="/premium-themes/onepirate/sign-up/"
          sx={{ minWidth: 200 }}
        >
          Register
        </Button> 
        <Typography variant="body2" color="inherit" sx={{ mt: 2 }}>
          Discover the experience
        </Typography> */}
      </div>
    </Fragment>
  );
}
