import * as React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import { Colors } from "../../../styles/theme";
import { CircularProgress, Grid, Stack, Typography } from "@mui/material";
import {
  DayImage,
  SubtitleHeader,
  BoxItinerario,
} from "../../../styles/circuitsdetail";
import parse from "html-react-parser";
import { Box } from "@mui/system";
import ReactPlayer from "react-player";
import { useEffect, useState } from "react";
import Fichacircuitdetail from "../fichacircuitdetail";
import Itinerary from "../itinerary";
import axios from "axios";

export default function ItinerarioFechas(props) {
  const [itinerarioFec, setItinerarioFec] = useState([]);
  useEffect(() => {
    // 👇️ only runs once
    var url = "https://iberolunatravel.online/wse/api/GetItinerarioFechas";

    axios
      .post(url, props.crit, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        setItinerarioFec(response.data);
      })
      .catch((error) => {}); //
  }, []);

  return (
    <React.Fragment>
      {itinerarioFec.length === 0 ? (
        <>
            <Stack alignItems="center">
                <br></br>
                <br></br>
                <br></br>
                <CircularProgress/>
                <br></br>
                <br></br>
              </Stack>
        </>
      ) : (
        <>
        <Fichacircuitdetail
            imagenes={props.imagenes}
            circuitoIncluye={props.circuitoIncluye}
            imagenmapa={props.imagenmapa}
            circuito={props.circuito}
            matches={props.matches}
            flagWait={props.flagWait}
            flagDialog={props.flagDialog}
          ></Fichacircuitdetail>
          <Itinerary
            itinerario={itinerarioFec}
            matches={props.matches}
            tipo={props.tipo}
            flagWait={props.flagWait}
            flagDialog={props.flagDialog}
          />
        </>
      )}
    </React.Fragment>
  );
}
