import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { masvendidos } from "../../data/masvendidos.js";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

//import "./styles.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";
import { Container } from "@mui/material";
import { ImagePromotionCarousel } from "../../styles/promotions";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { isContentEditable } from "@testing-library/user-event/dist/utils/index.js";
import LinkButton from "@mui/material/Link";

function AppHorizontalGalley(props) {
  const navigate = useNavigate();


// export default function App(props) {

  return (
    <Container
      maxWidth="100%"
      sx={{
        background: "#fff",
      }}
    >
      <>
        <Swiper
          slidesPerView={props.matches ? 1 : 2}
          spaceBetween={10}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          {props.lista.map((item, i) => (
            <SwiperSlide
              sx={{
                m: 0,
              }}
            >
              <LinkButton
                component="button"
                variant="body2"
                onClick={() => {
                  navigate("/" + item.nombreUrl);
                }}
              >
                <ImagePromotionCarousel key={i} src={item.imagen} />
              </LinkButton>

              {/*          <a href={item.enlace} target="_blank" rel="noreferrer">
                <ImagePromotion key={i} src={item.imagen} />
              </a> */}
            </SwiperSlide>
          ))}
          {/* promotions.map( (item, i) => <ImagePromotion src={item.image}/> ) */}
        </Swiper>
      </>
    </Container>
  );
}

export default AppHorizontalGalley;
