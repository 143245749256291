import {  Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import SingleCircuitDesktop from "./../circuitsresults/circuits/SingleCircuitDesktop";

export default function Products(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const renderProducts = props.circuitos.map((product) => (
    <Grid
      item
      key={product.id}
      xs={12}
      sm={4}
      md={3}
      display="flex"
      flexDirection={"column"}
      alignItems="center"
    >
      <SingleCircuitDesktop
        key={product.id}
        circuit={product}
        matches={matches}
        tipo={props.ofertas ? "Ofertas" : "Circuitos"}
        opcionhome={"resumido"}
        tabFecha={"*"}
      />
      {/*  {matches ? (
        <SingleProductDesktop product={product} matches={matches} />
      ) : (
        <SingleProductDesktop product={product} matches={matches} />
      )} */}
    </Grid>
  ));

  return (
    // <Container>
    <Grid
      container
      spacing={{ xs: 1, md: 4 }}
      justifyContent="center"
      sx={{ margin: `20px 4px 10px 4px` }}
      columns={{ xs: 4, sm: 8, md: 12 }}
    >
      {renderProducts}
    </Grid>
    // </Container>
  );
}
