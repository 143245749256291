import * as React from "react";
import "react-vertical-timeline-component/style.min.css";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import theme, { Colors } from "../../styles/theme";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputLabel,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { AppbarHeaderImage } from "../../styles/appbar";
import axios from "axios";
import { LoadingButton } from "@mui/lab";

export default function Itinerary(props) {
  const options = {
    useGrouping: true,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  const userLocale =
    navigator.languages && navigator.languages.length
      ? navigator.languages[0].substring(0, 2)
      : navigator.language.substring(0, 2);

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [flagInput, setFlagInput] = React.useState(false);
  const [opcionPago, setOpcionPago] = React.useState("Total");
  const [montoPorEnviar, setMontoPorEnviar] = React.useState();
  const [flagDialogMensaje, setFlagDialogMensaje] = React.useState(false);
  const [loadingPago, setLoadingPago] = React.useState(false);
  const [mensajeDialog, setMensajeDialog] = React.useState("");
  const [idPagoRegistration, setIdPagoRegistration] = React.useState(0);
  const [mensajePagoTotal, setMensajePagoTotal] = React.useState(
    "Pago total en COP (" +
    Number(props.precioTotalLocal).toLocaleString(userLocale, options) +
    ")"
  );

  const handleChangeOpcionesPago = (event) => {
    if (event.target.value === "Parcial") {
      setOpcionPago("Parcial");
      setFlagInput(true);
    } else {
      setOpcionPago("Total");
      setFlagInput(false);
    }
  };

  const handleChangeMontoPorEnviar = (event) => {
    setMontoPorEnviar(event.target.value);
  };

  const handleCloseDialogMensaje = (event, reason) => {
    if (reason !== "backdropClick") {
      setFlagDialogMensaje(false);
      props.cerrarPago();
    }
  };

  const ActivaPago = async () => {
    var flagPay = false;
    var flag = true;
    var montoPago = 0;

    if (opcionPago === "Parcial") {
      if (isNaN(montoPorEnviar)) {
        setMensajeDialog("Favor indicar el monto a pagar.");
        setFlagDialogMensaje(true);
        flag = false;
        return flagPay;
      }
      montoPago = Math.ceil(parseFloat(montoPorEnviar) * 100);
      /*  if (parseFloat(montoPorEnviar) < parseFloat(props.precioTotalLocal) * 0.25) {
         setMensajeDialog("El monto mínimo de pago debe ser el 25% del valor total del circuito.");
         setFlagDialogMensaje(true);
         flag = false;
         return flagPay;
       } else {
         if (parseFloat(montoPorEnviar) > parseFloat(props.precioTotalLocal)) {
           setMensajeDialog("El monto a pagar no puede ser superior al pago total.");
           setFlagDialogMensaje(true);
           flag = false;
           return flagPay;
         }
       } */
    } else {
      montoPago = Math.ceil(parseFloat(props.precioTotalLocal) * 100);
    }

    if (flag) {
      setLoadingPago(true);
      //RegistraPago............
      var pay = {
        Expediente: props.expediente,
        LocalizadorOperacion: "IB-" + props.localizadorOnline,
        ProveedorPago: "Wompi",
        MontoEnviado: montoPago,
        MonedaEnviado: "COP",
        LocalizadorReserva: props.prereserva,
      };

      var url = "https://iberolunatravel.online/wse/api/RegisterPagoTC";
      const resp = await axios.post(url, pay, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });
      if (resp.data !== null) {
        console.log(resp.data);
        setIdPagoRegistration(resp.data.Id);
        switch (resp.data.EstadoDescripcion) {
          case "PRE-RESERVA":
            ActivaWompi(resp.data.Id);
            flagPay = true;
            props.cerrarPago();
            break;
          case "CANCELADO":
            setMensajeDialog("La Pre-Reserva indicada esta cancelada.");
            setFlagDialogMensaje(true);

            break;
          case "RESERVA":
            setMensajeDialog("El circuito indicado es una reserva.");
            setFlagDialogMensaje(true);

            break;
          default:
            break;
        }
        /*  if (resp.data === "OK") {
           ActivaWompi();
           flagPay = true;
           props.cerrarPago();
         } else {
           
         } */
      }
    }

    return flagPay;
  };

  const ActivaWompi = (idPagosTC) => {
    var montoPago = 0;
    if (opcionPago === "Parcial") {
      montoPago = Math.ceil(parseFloat(montoPorEnviar) * 100);
    } else {
      montoPago = Math.ceil(parseFloat(props.precioTotalLocal) * 100);
    }

    //------------------------WOMPI------------------

    var checkout = new window.WidgetCheckout({
      currency: "COP",
      amountInCents: montoPago,
      reference: "IB-" + props.localizadorOnline + "-" + idPagosTC,
      publicKey: "pub_prod_m486jgBG3DIJypiRGDOgZrryhleYxoJR",
      redirectUrl: "https://iberolunatravel.online/#/resultadotransaccion", // Opcional
      customerData: {
        // Opcional
        email: props.email,
        fullName: props.titular,
        phoneNumber: props.celular,
        phoneNumberPrefix: "+57",
      },
    });

    checkout.open(function (result) {
      var transaction = result.transaction;
      console.log("Transaction ID: ", transaction.id);
      console.log("Transaction object: ", transaction);
    });

    //-------------------
  };

  return (
    <React.Fragment>
      <Box
        sx={{ m: 3 }}
      /*   sx={{
        backgroundColor: "red",
        "&:hover": {
          backgroundColor: "primary.main",
          opacity: [0.9, 0.8, 0.7],
        },
      }} */
      >
        <AppbarHeaderImage src="/images/ColReservas/logocolreservasazul200.png"  alt="Colreservas" title="Colreservas"/>
        <br></br>
        <br></br>
        <br></br>
        <Typography>
          <Box
            fontWeight="Medium"
            display="inline"
            sx={{ color: Colors.orange_ibero }}
          >
            Favor seleccione la opción de pago:
          </Box>{" "}
        </Typography>
        <br></br>
        <Box
          display="flex"
          width={500}
          height={80}
          bgcolor={Colors.light_gray}
          alignItems="center"
          justifyContent="center"
        >
          <FormControl>
            <RadioGroup
              column
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              defaultValue="total"
            >
              <FormControlLabel
                value="total"
                id="total"
                control={<Radio />}
                label={mensajePagoTotal}
                onChange={handleChangeOpcionesPago}
              />
              {props.permiteAbono ?
                <>
                  <FormControlLabel
                    id="parcial"
                    value="Parcial"
                    control={<Radio />}
                    label="Pago Parcial en COP (mínimo el 25%)"
                    onChange={handleChangeOpcionesPago}
                  />


                </>

                : <></>}
            </RadioGroup>
          </FormControl>
        </Box>
        <br></br>
        {flagInput ? (
          <FormControl fullWidth sx={{ m: 2 }} variant="standard">
            <InputLabel htmlFor="standard-adornment-amount">
              Indique monto a pagar:
            </InputLabel>
            <Input
              id="standard-adornment-monto"
              type="number"
              value={montoPorEnviar}
              onChange={handleChangeMontoPorEnviar}
            />
            <br></br>

            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <br></br>
              <Typography sx={{ color: Colors.black }}>
               Esta una opción de pago, no un financiamiento.
              </Typography>
              <br></br>
            </Grid>
          </FormControl>
        ) : (
          <>
            <br></br>
            <br></br>
          </>
        )}
        <br></br>
        <Typography>
          <Box m={1} display="flex" justifyContent="center" alignItems="center">
            <LoadingButton
              variant="contained"
              onClick={ActivaPago}
              sx={{ height: 50, paddingLeft: 5, paddingRight: 5, backgroundColor: Colors.orange_ibero }}
              loading={loadingPago}
            >
              Realizar Pago
            </LoadingButton>
            {/*  <Button
              variant="contained"
              color="primary"
              sx={{ height: 50, paddingLeft: 5, paddingRight: 5 }}
              onClick={ActivaPago}
            >
              Realizar Pago
            </Button> */}
          </Box>
        </Typography>
        <br></br>
      </Box>

      <Dialog
        fullScreen={fullScreen}
        open={flagDialogMensaje}
        onClose={handleCloseDialogMensaje}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <AppbarHeaderImage src="/images/ColReservas/logocolreservasazul200.png"  alt="Colreservas" title="Colreservas"/>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography>
              <Box
                fontWeight="Medium"
                //display="inline"
                sx={{ width: "100%" }}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {mensajeDialog}
              </Box>{" "}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogMensaje} autoFocus>
            <CloseOutlinedIcon
              fontSize="large"
              sx={{ color: "gray" }}
            ></CloseOutlinedIcon>
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
