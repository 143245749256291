import * as React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LocalHotelOutlinedIcon from "@mui/icons-material/LocalHotelOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import ImportContactsOutlinedIcon from "@mui/icons-material/ImportContactsOutlined";
import { Colors } from "../../../styles/theme";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";

export default function SelectedListItem(props) {
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleListItemClick = (event, index) => {
    switch (index) {
      case 5:
        window.open(props.word, "_blank");
        break;
      case 6:
        window.open(props.pdf, "_blank");
        break;
      default:
        setSelectedIndex(index);
        props.onClick(index);
    }
  };

  return (
    <Box flex={1} sx={{ maxWidth: "160px" }}>
      <List component="nav" aria-label="main mailbox folders">
        <ListItemButton
          selected={selectedIndex === 0}
          onClick={(event) => handleListItemClick(event, 0)}
        >
          <ListItemIcon>
            <ImportContactsOutlinedIcon sx={{ color: Colors.orange_ibero }} />
          </ListItemIcon>
          <ListItemText
            primary="Incluye / Itinerario"
            sx={{ color: Colors.blue_ibero }}
          />
        </ListItemButton>
        <ListItemButton
          selected={selectedIndex === 3}
          onClick={(event) => handleListItemClick(event, 3)}
        >
          <ListItemIcon>
            <ImportContactsOutlinedIcon sx={{ color: Colors.orange_ibero }} />
          </ListItemIcon>
          <ListItemText
            primary="Itinerario detallado"
            sx={{ color: Colors.blue_ibero }}
          />
        </ListItemButton>
        {/* <ListItemButton
          selected={selectedIndex === 1}
          onClick={(event) => handleListItemClick(event, 1)}
        >
          <ListItemIcon>
            <CameraAltOutlinedIcon sx={{ color: Colors.orange_ibero }} />
          </ListItemIcon>
          <ListItemText primary="Fotos" sx={{ color: Colors.blue_ibero }} />
        </ListItemButton> */}
        <ListItemButton
          selected={selectedIndex === 2}
          onClick={(event) => handleListItemClick(event, 2)}
        >
          <ListItemIcon>
            <LocalHotelOutlinedIcon sx={{ color: Colors.orange_ibero }} />
          </ListItemIcon>
          <ListItemText
            primary="Hoteles previstos"
            sx={{ color: Colors.blue_ibero }}
          />
        </ListItemButton>
        {/*   <ListItemButton
          selected={selectedIndex === 3}
          onClick={(event) => handleListItemClick(event, 3)}
        >
          <ListItemIcon>
            <LocalDiningOutlinedIcon sx={{ color: Colors.orange_ibero }} />
          </ListItemIcon>
          <ListItemText
            primary="Suplementos de comidas"
            sx={{ color: Colors.blue_ibero }}
          />
        </ListItemButton>
        <ListItemButton
          selected={selectedIndex === 4}
          onClick={(event) => handleListItemClick(event, 4)}
        >
          <ListItemIcon>
            <AttractionsOutlinedIcon sx={{ color: Colors.orange_ibero }} />
          </ListItemIcon>
          <ListItemText
            primary="Excursiones opcionales"
            sx={{ color: Colors.blue_ibero }}
          />
        </ListItemButton> */}
        {props.word !== "" ? (
          <ListItemButton
            selected={selectedIndex === 5}
            onClick={(event) => handleListItemClick(event, 5)}
          >
            <ListItemIcon>
              <TextSnippetOutlinedIcon sx={{ color: Colors.orange_ibero }} />
            </ListItemIcon>
            <ListItemText
              primary="Itinerario Word"
              sx={{ color: Colors.blue_ibero }}
            />
          </ListItemButton>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        {props.pdf !== "" ? (
          <ListItemButton
            selected={selectedIndex === 6}
            onClick={(event) => handleListItemClick(event, 6)}
          >
            <ListItemIcon>
              <PictureAsPdfOutlinedIcon sx={{ color: Colors.orange_ibero }} />
            </ListItemIcon>
            <ListItemText
              primary="Itinerario PDF"
              sx={{ color: Colors.blue_ibero }}
            />
          </ListItemButton>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        <ListItemButton
          selected={selectedIndex === 99}
          onClick={(event) => handleListItemClick(event, 99)}
        >
          <ListItemIcon>
            <CalculateOutlinedIcon sx={{ color: Colors.orange_ibero }} />
          </ListItemIcon>
          <ListItemText
            primary="Cotice y reserve su Viaje"
            sx={{ color: Colors.blue_ibero }}
          />
        </ListItemButton>
      </List>
      {/* <List component="nav" aria-label="secondary mailbox folder">
        <ListItemButton
          selected={selectedIndex === 2}
          onClick={(event) => handleListItemClick(event, 2)}
        >
          <ListItemIcon>
            <TextSnippetOutlinedIcon sx={{color: Colors.orange_ibero}}/>
          </ListItemIcon>
          <ListItemText primary="Itinerario Word" sx={{color: Colors.blue_ibero}} />
        </ListItemButton>
        <ListItemButton
          selected={selectedIndex === 3}
          onClick={(event) => handleListItemClick(event, 3)}
        >
          <ListItemIcon>
            <PictureAsPdfOutlinedIcon sx={{color: Colors.orange_ibero}}/>
          </ListItemIcon>
          <ListItemText primary="Itinerario PDF" sx={{color: Colors.blue_ibero}} />
        </ListItemButton>
      </List> */}
    </Box>
  );
}
