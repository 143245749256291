import * as React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";
import { Colors } from "../../../../../styles/theme";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { styled } from "@mui/material/styles";

function Hoteles(props) {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: Colors.blue_ibero,
      color: theme.palette.common.white,
      fontSize: 18,      
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: 0,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <Container
      component="section"
      sx={{
        mt: 10,
        mb: 10,       
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <Typography
            variant="h4"
            marked="center"
            component="h2"
            sx={{ mb: 3 }}
            align="center"
          >
            Hoteles previstos o similares
          </Typography>
          <TableContainer  component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Ciudades</StyledTableCell>
                  <StyledTableCell align="center">Hoteles</StyledTableCell>
                  <StyledTableCell align="center">Categoría</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell component="th" scope="row" align="center">
                    Madrid
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <List dense={true} key={"L-IT"}>
                    <ListItem sx={{paddingBottom: 0, paddingTop: 0}}>
                        <ListItemIcon>
                          <ArrowRightIcon sx={{ color: Colors.orange_ibero }} />
                        </ListItemIcon>
                        <ListItemText primary="Praga" />
                      </ListItem>
                      <ListItem sx={{paddingBottom: 0, paddingTop: 0}}>
                        <ListItemIcon>
                          <ArrowRightIcon sx={{ color: Colors.orange_ibero }} />
                        </ListItemIcon>
                        <ListItemText primary="Florida Norte" />
                      </ListItem>
                    </List>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Primera Moderada
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell component="th" scope="row" align="center">
                    Barcelona
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <List dense={true} key={"L-IT"}>
                    <ListItem sx={{paddingBottom: 0, paddingTop: 0}}>
                        <ListItemIcon>
                          <ArrowRightIcon sx={{ color: Colors.orange_ibero }} />
                        </ListItemIcon>
                        <ListItemText primary="Sant Cugat" />
                      </ListItem>
                    </List>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Turista Superior
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell component="th" scope="row" align="center">
                    Costa Azul
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <List dense={true} key={"L-IT"}>
                    <ListItem sx={{paddingBottom: 0, paddingTop: 0}}>
                        <ListItemIcon>
                          <ArrowRightIcon sx={{ color: Colors.orange_ibero }} />
                        </ListItemIcon>
                        <ListItemText primary="Le Noailles Nice Gare (Niza)" />
                      </ListItem>
                      <ListItem sx={{paddingBottom: 0, paddingTop: 0}}>
                        <ListItemIcon>
                          <ArrowRightIcon sx={{ color: Colors.orange_ibero }} />
                        </ListItemIcon>
                        <ListItemText primary="Du Pin Nice Port (Niza)" />
                      </ListItem>
                    </List>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Turista Superior
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell component="th" scope="row" align="center">
                    Roma
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <List dense={true} key={"L-IT"}>
                    <ListItem sx={{paddingBottom: 0, paddingTop: 0}}>
                        <ListItemIcon>
                          <ArrowRightIcon sx={{ color: Colors.orange_ibero }} />
                        </ListItemIcon>
                        <ListItemText primary="Pisana Palace" />
                      </ListItem>
                      <ListItem sx={{paddingBottom: 0, paddingTop: 0}}>
                        <ListItemIcon>
                          <ArrowRightIcon sx={{ color: Colors.orange_ibero }} />
                        </ListItemIcon>
                        <ListItemText primary="Pineta Palace" />
                      </ListItem>
                      <ListItem sx={{paddingBottom: 0, paddingTop: 0}}>
                        <ListItemIcon>
                          <ArrowRightIcon sx={{ color: Colors.orange_ibero }} />
                        </ListItemIcon>
                        <ListItemText primary="Cristofor Colombo" />
                      </ListItem>
                      <ListItem sx={{paddingBottom: 0, paddingTop: 0}}>
                        <ListItemIcon>
                          <ArrowRightIcon sx={{ color: Colors.orange_ibero }} />
                        </ListItemIcon>
                        <ListItemText primary="B&B Tuscolana San Giovanni" />
                      </ListItem>
                    </List>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Primera Moderada / Turista Superior
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell component="th" scope="row" align="center">
                    Florencia
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <List dense={true} key={"L-IT"}>
                      <ListItem sx={{paddingBottom: 0, paddingTop: 0}}>
                        <ListItemIcon>
                          <ArrowRightIcon sx={{ color: Colors.orange_ibero }} />
                        </ListItemIcon>
                        <ListItemText primary="West Florence" />
                      </ListItem>
                      <ListItem sx={{paddingBottom: 0, paddingTop: 0}}>
                        <ListItemIcon>
                          <ArrowRightIcon sx={{ color: Colors.orange_ibero }} />
                        </ListItemIcon>
                        <ListItemText primary="B&B Firenze Novoli" />
                      </ListItem>
                      <ListItem sx={{paddingBottom: 0, paddingTop: 0}}>
                        <ListItemIcon>
                          <ArrowRightIcon sx={{ color: Colors.orange_ibero }} />
                        </ListItemIcon>
                        <ListItemText primary="B&B Nuovo Palazzo di Giustizia" />
                      </ListItem>                      
                    </List>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Turista Superior
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell component="th" scope="row" align="center">
                    Venecia (Mestre)
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <List dense={true} key={"L-IT"}>
                      <ListItem sx={{paddingBottom: 0, paddingTop: 0}}>
                        <ListItemIcon>
                          <ArrowRightIcon sx={{ color: Colors.orange_ibero }} />
                        </ListItemIcon>
                        <ListItemText primary="Albatros" />
                      </ListItem>
                      <ListItem sx={{paddingBottom: 0, paddingTop: 0}}>
                        <ListItemIcon>
                          <ArrowRightIcon sx={{ color: Colors.orange_ibero }} />
                        </ListItemIcon>
                        <ListItemText primary="Michelangelo" />
                      </ListItem>
                      <ListItem sx={{paddingBottom: 0, paddingTop: 0}}>
                        <ListItemIcon>
                          <ArrowRightIcon sx={{ color: Colors.orange_ibero }} />
                        </ListItemIcon>
                        <ListItemText primary="Campanile Hotel Venice Mestre" />
                      </ListItem>                      
                    </List>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Turista Superior
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell component="th" scope="row" align="center">
                    Zurich
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <List dense={true} key={"L-IT"}>
                      <ListItem sx={{paddingBottom: 0, paddingTop: 0}}>
                        <ListItemIcon>
                          <ArrowRightIcon sx={{ color: Colors.orange_ibero }} />
                        </ListItemIcon>
                        <ListItemText primary="B&B Zurich Airport Rumlang" />
                      </ListItem>                                        
                    </List>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Turista Superior
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell component="th" scope="row" align="center">
                    París
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <List dense={true} key={"L-IT"}>
                      <ListItem sx={{paddingBottom: 0, paddingTop: 0}}>
                        <ListItemIcon>
                          <ArrowRightIcon sx={{ color: Colors.orange_ibero }} />
                        </ListItemIcon>
                        <ListItemText primary="Ibis Paris Porte de Montreuil" />
                      </ListItem>
                      <ListItem sx={{paddingBottom: 0, paddingTop: 0}}>
                        <ListItemIcon>
                          <ArrowRightIcon sx={{ color: Colors.orange_ibero }} />
                        </ListItemIcon>
                        <ListItemText primary="Greet" />
                      </ListItem>
                      <ListItem sx={{paddingBottom: 0, paddingTop: 0}}>
                        <ListItemIcon>
                          <ArrowRightIcon sx={{ color: Colors.orange_ibero }} />
                        </ListItemIcon>
                        <ListItemText primary="Lodge Inn M.I.S. Paris" />
                      </ListItem>                      
                    </List>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Turista Superior
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell component="th" scope="row" align="center">
                    Lourdes
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <List dense={true} key={"L-IT"}>
                      <ListItem sx={{paddingBottom: 0, paddingTop: 0}}>
                        <ListItemIcon>
                          <ArrowRightIcon sx={{ color: Colors.orange_ibero }} />
                        </ListItemIcon>
                        <ListItemText primary="Angelic" />
                      </ListItem>
                      <ListItem sx={{paddingBottom: 0, paddingTop: 0}}>
                        <ListItemIcon>
                          <ArrowRightIcon sx={{ color: Colors.orange_ibero }} />
                        </ListItemIcon>
                        <ListItemText primary="Alliance" />
                      </ListItem>                                      
                    </List>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Turista Superior
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Hoteles;
