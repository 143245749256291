import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import "../../App.css";
import { Container, Typography, Stack, Grid } from "@mui/material";
import theme from "../../styles/theme";
import Appbar from "../appbar";
import { Fragment, useCallback, useEffect, useState } from "react";
import Footer from "../footer";
import { UIProvider } from "../../context/ui";
import AppDrawer from "../drawer";
import { Colors } from "../../styles/theme";
import axios from "axios";
import { AppTitleOrange } from "../../styles/app";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import MailIcon from "@mui/icons-material/Mail";
import CommentIcon from "@mui/icons-material/Comment";
import { LoadingButton } from "@mui/lab";
import { AppbarHeaderImage } from "../../styles/appbar";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useNavigate } from "react-router-dom";

export default function Circuitsdetaildesktop(props) {
  const [nombreApellido, setNombreApellido] = useState("");
  const [celular, setCelular] = useState("");
  const [email, setEmail] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [flagCierre, setFlagCierre] = useState(false);

  //----------------DIALOG NENSAJE----------------
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [flagDialogMensaje, setFlagDialogMensaje] = useState(false);
  const [mensajeDialog, setMensajeDialog] = useState("");
  const navigate = useNavigate();
  const handleCloseDialogMensaje = (event, reason) => {
    if (reason !== "backdropClick") {
      setFlagDialogMensaje(false);
      if (flagCierre) handleOnClickHome();
      props.onClick();
    }
  };

  const handleOnClickHome = useCallback(
    () => navigate("/Home", { replace: true }),
    [navigate]
  );

  useEffect(() => {
    document.title = "Colreservas - Contáctenos";
  }, []);

  const handleChange = (event) => {
    switch (event.target.name) {
      case "nombreApellido":
        setNombreApellido(event.target.value);
        break;
      case "celular":
        setCelular(event.target.value);
        break;
      case "email":
        setEmail(event.target.value);
        break;
      case "mensaje":
        setMensaje(event.target.value);
        break;
      default:
        break;
    }
  };

  function ValidateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    return false;
  }

  const enviarMensaje = async () => {
    if (!ValidateEmail(email)) {
      setMensajeDialog("Favor indicar el email correctamente.");
      setFlagDialogMensaje(true);
    } else {
      var mensajeRQ = {
        nombreApellido: nombreApellido,
        celular: celular,
        email: email,
        mensaje: "Formulario. Solicitud asesoría.",
      };
    
      var url = "https://iberolunatravel.online/wse/api/ContactenosRegistration";
      const resp = await axios.post(url, mensajeRQ, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });
      if (resp.data === "OK") {
        setMensajeDialog(
          "Gracias por contactarnos. En breve nos comunicamos con usted."
        );
        setFlagCierre(true);
        setFlagDialogMensaje(true);        
      }
    }
  };

  const cuadroContactos = () => {
    let content = [];
    content.push(
      <Stack spacing={2}>
        <Card>
          <CardActionArea>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{
                  color: Colors.blue_ibero,
                  fontWeight: "Medium",
                }}
              >
                Teléfono
              </Typography>
              <Typography variant="body2" color="text.secondary">
                +57 601 6215010 <br></br>+57 601 2362803
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card>
          <CardActionArea>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{
                  color: Colors.blue_ibero,
                  fontWeight: "Medium",
                }}
              >
                Línea Nacional
              </Typography>
              <Typography variant="body2" color="text.secondary">
                018000 111 974
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card>
          <CardActionArea>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{
                  color: Colors.blue_ibero,
                  fontWeight: "Medium",
                }}
              >
                WhatsApp
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <a
                  aria-label="Chat on WhatsApp"
                  href="whatsapp://send?text=Colreservas&phone=+573156714878&abid=+573156714878"
                >
                  {" "}
                  <img
                    alt="Chat on WhatsApp"
                    src="/images/WhatsAppButtonGreenSmall.svg"
                  />{" "}
                </a>
                <br></br>
                <a href="whatsapp://send?text=Colreservas&phone=+573156714878&abid=+573156714878">
                  +57 314 7007919
                </a>
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card>
          <CardActionArea>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{
                  color: Colors.blue_ibero,
                  fontWeight: "Medium",
                }}
              >
                Email
              </Typography>
              <Typography variant="body2" color="text.secondary">
                cliente@iberolunatravel.online
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card>
          <CardActionArea>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{
                  color: Colors.blue_ibero,
                  fontWeight: "Medium",
                }}
              >
                Dirección
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Carrera 13 # 85 - 39 Ofic. 202 Bogotá - Colombia
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Stack>
    );
    return content;
  };

  const formulario = () => {
    let content = [];
    content.push(
      <Stack
      direction={props.matches ? "column" : "row"}
      // divider={
      //   <Divider
      //     orientation={matches ? "horizontal" : "vertical"}
      //     flexItem
      //   />
      // }
      spacing={1}
    >
      <img
        src="/images/Contact.jpg"
        alt="Contacto"
        width={props.matches ? "270px" : "270px"}
      />
      <Card sx={{ width: "100%" }}>
        <CardContent>
          <br></br>
          <Grid item xs={12} sx={{ textAlign: "center", mb: "20px" }}>
            {/* <LoadingButton
              disabled={false}
              variant="contained"
              sx={{
                minWidth: "200px",
                backgroundColor: Colors.orange_ibero,
              }}
              loading={false}
              // onClick={enviarMensaje}
            >
              Escribanos por WhatsApp
            </LoadingButton>
             */}
            <a
              aria-label="Chat on WhatsApp"
              href="whatsapp://send?text=Colreservas&phone=+573156714878&abid=+573156714878"
            >
              {" "}
              <img
                alt="Chat on WhatsApp"
                src="/images/WhatsAppButtonGreenSmall.svg"
              />{" "}
            </a>
            <br></br>
          </Grid>

          <Divider></Divider>
          <br></br>
          <Typography
            align="center"
            gutterBottom
            variant="h6"
            component="div"
            sx={{
              color: Colors.blue_ibero,
              fontWeight: "Medium",
            }}
          >
            Déjenos sus datos para contactarlo
          </Typography>
          <FormControl fullWidth sx={{ m: 1 }} variant="standard">
            <InputLabel htmlFor="standard-adornment-amount">
              Nombre y Apellido:
            </InputLabel>
            <Input
              id="nombreApellido"
              name="nombreApellido"
              value={nombreApellido}
              onChange={handleChange}
              startAdornment={
                <InputAdornment position="start">
                  <PersonIcon
                    sx={{
                      color: "action.active",
                      mr: 1,
                      my: 0.5,
                    }}
                  />
                </InputAdornment>
              }
            />
          </FormControl>

          <FormControl fullWidth sx={{ m: 1 }} variant="standard">
            <InputLabel htmlFor="standard-adornment-amount">
              Celular:
            </InputLabel>
            <Input
              id="celular"
              name="celular"
              value={celular}
              onChange={handleChange}
              startAdornment={
                <InputAdornment position="start">
                  <PhoneIphoneIcon
                    sx={{
                      color: "action.active",
                      mr: 1,
                      my: 0.5,
                    }}
                  />
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl fullWidth sx={{ m: 1 }} variant="standard">
            <InputLabel htmlFor="standard-adornment-amount">
              Email:
            </InputLabel>
            <Input
              id="email"
              name="email"
              value={email}
              onChange={handleChange}
              startAdornment={
                <InputAdornment position="start">
                  <MailIcon
                    sx={{
                      color: "action.active",
                      mr: 1,
                      my: 0.5,
                    }}
                  />
                </InputAdornment>
              }
            />
          </FormControl>
          {/* <FormControl fullWidth sx={{ m: 1 }} variant="standard">
            <InputLabel htmlFor="standard-adornment-amount">
              Mensaje:
            </InputLabel>
            <Input
              id="mensaje"
              name="mensaje"
              multiline
              rows={8}
              // value={mensaje}
              // onChange={handleChange}
              startAdornment={
                <InputAdornment position="start">
                  <CommentIcon
                    sx={{
                      color: "action.active",
                      mr: 1,
                      my: 0.5,
                    }}
                  />
                </InputAdornment>
              }
            />
          </FormControl> */}
        </CardContent>
        <CardActions></CardActions>
        <Grid item xs={12} sx={{ textAlign: "center", mb: "20px" }}>
          <LoadingButton
            disabled={false}
            variant="contained"
            sx={{
              minWidth: "200px",
              backgroundColor: Colors.orange_ibero,
            }}
            loading={false}
            onClick={enviarMensaje}
          >
            Enviar
          </LoadingButton>
        </Grid>
      </Card>
    </Stack>
    );
    return content;
  };

  return (
    <Fragment>
       {formulario()}
      <Dialog
        fullScreen={fullScreen}
        open={flagDialogMensaje}
        onClose={handleCloseDialogMensaje}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <AppbarHeaderImage src="/images/ColReservas/logocolreservasazul200.png"  alt="Colreservas" title="Colreservas"/>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography>
              <Box
                fontWeight="Medium"
                //display="inline"
                sx={{ width: "100%" }}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {mensajeDialog}
              </Box>{" "}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogMensaje} autoFocus>
            <CloseOutlinedIcon
              fontSize="large"
              sx={{ color: "gray" }}
            ></CloseOutlinedIcon>
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
