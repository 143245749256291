import * as React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import { Colors } from "../../../styles/theme";
import {
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import {
  DayImage,
  MapImage,
  SubtitleHeader,
  SubtitleHeaderNoIncluye,
} from "../../../styles/circuitsdetail";
import { Box } from "@mui/system";
import Titlebar from "../titlebar";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import UnpublishedOutlinedIcon from "@mui/icons-material/UnpublishedOutlined";
import parse from "html-react-parser";
import useDialogModal from "../../../hooks/useDialogModal";
import MapView from "../../mapview";
import ReactImageGallery from "react-image-gallery";

export default function Fichacircuitdetail(props) {
  console.log(props.circuitoIncluye);
  const [MapViewDialog, showMapViewDialog, closeMapView] =
    useDialogModal(MapView);

  const Itinerario = () => {
    let content = [];
    content.push(
      <>
        {props.flagWait ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            marginTop="100px"
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <SubtitleHeader key={"T-IT"}>INCLUYE</SubtitleHeader>
            <List dense={true} key={"L-IT"}>
              {props.circuitoIncluye
                .filter((item) => item.DESCRIPCION !== "noincluye")
                .map((item, i) => (
                  <ListItem key={i}>
                    <ListItemIcon>
                      <CheckCircleOutlinedIcon
                        key={i}
                        sx={{ color: Colors.orange_ibero }}
                      />
                    </ListItemIcon>
                    <ListItemText key={i} primary={parse(item.INCLUYE)} />
                  </ListItem>
                ))}
            </List>
            {props.circuitoIncluye.filter(
              (item) => item.DESCRIPCION === "noincluye"
            ).length > 0 ? (
              <>
                <br></br>
                <SubtitleHeaderNoIncluye key={"T-ITN"}>NO INCLUYE</SubtitleHeaderNoIncluye>
                <List dense={true} key={"L-IT"}>
                  {props.circuitoIncluye
                    .filter((item) => item.DESCRIPCION === "noincluye")
                    .map((item, i) => (
                      <ListItem key={i}>
                        <ListItemIcon>
                          <UnpublishedOutlinedIcon
                            key={i}
                            sx={{ color: Colors.orange_ibero }}
                          />
                        </ListItemIcon>
                        <ListItemText key={i} primary={parse(item.INCLUYE)} />
                      </ListItem>
                    ))}
                </List>
              </>
            ) : (
              <></>
            )}
          </>
        )}
      </>
    );
    return content;
  };

  const Mapa = () => {
    let content = [];
    content.push(
      <>
        {props.flagWait ? (
          <></>
        ) : (
          <>
            <SubtitleHeader style={{ paddingBottom: "15px" }}>
              MAPA DE RECORRIDO
            </SubtitleHeader>
            <MapImage src={props.imagenmapa} />
          </>
        )}
      </>
    );
    return content;
  };

  const Imagenes = () => {
    let content = [];
    content.push(
      <>
        {props.flagWait ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            marginTop="100px"
          >
            <CircularProgress />
          </Box>
        ) : (
          <React.Fragment>
            {!props.flagIncluye ? (
              <>
                <SubtitleHeader key={"IX-IT"}>
                  FOTOS DEL CIRCUITO
                </SubtitleHeader>
                <div style={{ marginBotton: "15px", overflow: "auto" }}>
                  <ReactImageGallery
                    key="ooo"
                    items={props.imagenes}
                    autoPlay={true}
                    sx={{ with: "100px", marginBotton: "25px" }}
                    showThumbnails={true}
                    thumbnailPosition={"bottom"}
                    slideInterval={5000}
                  />
                </div>
              </>
            ) : (
              <></>
            )}
          </React.Fragment>
        )}
      </>
    );
    return content;
  };

  return (
    <React.Fragment>
      {!props.matches ? (
        <>
        <Box   sx={{ padding:  props.flagDialog ?  "30px" : "0px" }}>
          <Stack
            key={"IT"}
            direction="row"
            spacing={2}
            justifyContent="space-between"
            sx={{ paddingBottom: "40px" }}
          >
            <Box key={"B-IT"} sx={{ textAlign: "justify", maxWidth: "750px" }}>
              {Imagenes()}
            </Box>
            <Box key={"B2-IT"} sx={{ margin: "0", padding: "0" }}>
              {Mapa()}
            </Box>
          </Stack>
          {/* </React.Fragment><Box key={"B-IT"} sx={{ textAlign: "justify", maxWidth: "800px" }}> */}
          {Itinerario()}
          {/* </Box> */}
          </Box>
        </>
      ) : (
        <>
          <Stack
            key={"IT-C"}
            spacing={2}
            justifyContent="space-between"
            sx={{ paddingBottom: "40px" }}
          >
            <Box sx={{ textAlign: "justify" }}>{Imagenes()}</Box>
            <Box sx={{ margin: "0", padding: "0" }}>{Mapa()}</Box>
            <br></br>
            <Box sx={{ textAlign: "justify" }}>{Itinerario()}</Box>
          </Stack>
        </>
      )}
      <MapViewDialog product={props.circuito} />
    </React.Fragment>
  );
}
