import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import TitleDesktop from "./titleDesktop";
//import TitleMobile from "./titleMobile";

export default function title(props) {

  const OnClick = (index) => {
           props.onClick(index);
    };

  return (
    <>
      {/* {matches ? <TitleMobile matches={matches}/> : <TitleDesktop matches={matches}/>} */}
      <TitleDesktop circuitoData={props.circuitoData} onClick={OnClick} matches={props.matches} flagAction={props.flagAction}></TitleDesktop>
    </>
  );
}
