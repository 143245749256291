import {
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  styled,
} from "@mui/material";
import { useUIContext } from "../../context/ui";
import CloseIcon from "@mui/icons-material/Close";
import { DrawerCloseButton } from "../../styles/appbar";
import { lighten } from "polished";
import { Colors } from "../../styles/theme";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";

const MiddleDivider = styled((props) => (
  <Divider variant="middle" {...props} />
))``;


export default function AppDrawer() {
  const { drawerOpen, setDrawerOpen } = useUIContext();
  const navigate = useNavigate();
  const handleOnClickHome = useCallback(
    () => navigate("/Home", { replace: true }),
    [navigate]
  );
  const handleOnClickCatalogos = useCallback(
    () => navigate("/Catalogos", { replace: true }),
    [navigate]
  );
  const handleOnClickCircuitos = useCallback(
    () => navigate("/results/Circuitos/*", { replace: true }),
    [navigate]
  );
  const handleOnClickOfertas = useCallback(
    () => navigate("/results/Ofertas/*", { replace: true }),
    [navigate]
  );
  const handleOnClickQuienesSomos = useCallback(
    () => navigate("/quienessomos", { replace: true }),
    [navigate]
  );

  const handleOnClickContactenos = useCallback(
    () => navigate("/contacto", { replace: true }),
    [navigate]
  );
  const handleOnClickReserva = useCallback(
    () => navigate("/consultareserva/*/*", { replace: true }),
    [navigate]
  );
  const handleOnClickPresupuesto = useCallback(
    () => navigate("/consultapresupuesto/*/*", { replace: true }),
    [navigate]
  );
  return (
    <>
      {drawerOpen && (
        <DrawerCloseButton onClick={() => setDrawerOpen(false)}>
          <CloseIcon
            sx={{
              fontSize: "2.5rem",
              color: lighten(0.09, Colors.secondary),
            }}
          />
        </DrawerCloseButton>
      )}
      <Drawer open={drawerOpen}>
        <List>
        <ListItemButton onClick={handleOnClickHome}>
          <ListItemText primary="INICIO" />
        </ListItemButton>
        <MiddleDivider />
        <ListItemButton onClick={handleOnClickCatalogos}>
          <ListItemText primary="DESTINOS" />
        </ListItemButton>
        <MiddleDivider />
        <ListItemButton onClick={handleOnClickCircuitos}>
          <ListItemText primary="¿DONDE DESEA VIAJAR?" />
        </ListItemButton>
        <MiddleDivider />
        {/* <ListItemButton onClick={handleOnClickOfertas}>
          <ListItemText primary="BUSCADOR DE OFERTAS" />
        </ListItemButton> */}
        <MiddleDivider />
        <ListItemButton  onClick={() =>
                window.open(
                  "https://colreservas.com/contacto.php",
                  "_blank"
                )
              }>
          <ListItemText primary="CONTÁCTENOS" />
        </ListItemButton>
        <MiddleDivider />
        <ListItemButton onClick={handleOnClickReserva}>
          <ListItemText primary="CONSULTA DE RESERVA" />
        </ListItemButton>
        <MiddleDivider />
        <ListItemButton onClick={handleOnClickPresupuesto}>
          <ListItemText primary="CONSULTA DE PRESUPUESTO" />
        </ListItemButton>
        {/* 
          <ListItemButton>
            <ListItemText>Home</ListItemText>
          </ListItemButton>
          
          <ListItemButton>
            <ListItemText>Categories</ListItemText>
          </ListItemButton>
          <MiddleDivider />
          <ListItemButton>
            <ListItemText>Products</ListItemText>
          </ListItemButton>
          <MiddleDivider />
          <ListItemButton>
            <ListItemText>About Us</ListItemText>
          </ListItemButton>
          <MiddleDivider />
          <ListItemButton>
            <ListItemText>Contact Us</ListItemText>
          </ListItemButton>
          <MiddleDivider /> */}
        </List>
      </Drawer>
    </>
  );
}
