import * as React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";
import { Colors } from "../../../../../styles/theme";
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";

function Condiciones(props) {
  //console.log(props.condicionesData);
  return (
    <Box
      component="section"
      sx={{ display: "flex", bgcolor: "secondary.light", overflow: "hidden" }}
    >
      <Container
        sx={{
          mt: 10,
          mb: 8,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          src="/Images/productCurvyLines.png"
          alt="curvy lines"
          sx={{
            pointerEvents: "none",
            position: "absolute",
            top: -180,
            opacity: 0.7,
          }}
        />
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h4"
              marked="center"
              component="h2"
              sx={{ mb: 3 }}
              align="center"
            >
              Condiciones de venta
            </Typography>
            <List dense={true} key={"L-IT"}>
              {props.condicionesData
                .filter((item) => item.DESCRIPCION === "condiciones")
                .map((item, i) => (
                  <ListItem key={i}>
                    <ListItemIcon>
                      <CheckCircleOutlinedIcon
                        key={i}
                        sx={{ color: Colors.orange_ibero }}
                      />
                    </ListItemIcon>
                    <ListItemText key={i} primary={item.INCLUYE} />
                  </ListItem>
                ))}
            </List>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography
              variant="h4"
              marked="center"
              component="h2"
              align="center"
              sx={{ mb: 3 }}
            >
              Gastos de cancelación
            </Typography>
            <Typography variant="h5" component="h2" align="center">
              Tiquete aéreo
            </Typography>
            <List dense={true} key={"L-IT"}>
              {props.condicionesData
                .filter((item) => item.DESCRIPCION === "cancelacionaereo")
                .map((item, i) => (
                  <ListItem key={i}>
                    <ListItemIcon>
                      <CheckCircleOutlinedIcon
                        key={i}
                        sx={{ color: Colors.orange_ibero }}
                      />
                    </ListItemIcon>
                    <ListItemText key={i} primary={item.INCLUYE} />
                  </ListItem>
                ))}
            </List>
            <Typography variant="h5" component="h2" align="center">
              Porción terrestre
            </Typography>
            <List dense={true} key={"L-IT"}>
              {props.condicionesData
                .filter((item) => item.DESCRIPCION === "cancelacionterrestre")
                .map((item, i) => (
                  <ListItem key={i}>
                    <ListItemIcon>
                      <CheckCircleOutlinedIcon
                        key={i}
                        sx={{ color: Colors.orange_ibero }}
                      />
                    </ListItemIcon>
                    <ListItemText key={i} primary={item.INCLUYE} />
                  </ListItem>
                ))}
            </List>
          </Grid>
        </Grid>

        <Grid container spacing={4} sx={{marginTop: "25px"}}>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h4"
              marked="center"
              component="h2"
              sx={{ mb: 3 }}
              align="center"
            >
              Requisitos de viaje
            </Typography>
            <List dense={true} key={"L-IT"}>
              {props.condicionesData
                .filter((item) => item.DESCRIPCION === "requisitos")
                .map((item, i) => (
                  <ListItem key={i}>
                    <ListItemIcon>
                      <CheckCircleOutlinedIcon
                        key={i}
                        sx={{ color: Colors.orange_ibero }}
                      />
                    </ListItemIcon>
                    <ListItemText key={i} primary={item.INCLUYE} />
                  </ListItem>
                ))}
            </List>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography
              variant="h4"
              marked="center"
              component="h2"
              align="center"
              sx={{ mb: 3 }}
            >
              Formas de pago
            </Typography>
            <List dense={true} key={"L-IT"}>
              {props.condicionesData
                .filter((item) => item.DESCRIPCION === "pagos")
                .map((item, i) => (
                  <ListItem key={i}>
                    <ListItemIcon>
                      <CheckCircleOutlinedIcon
                        key={i}
                        sx={{ color: Colors.orange_ibero }}
                      />
                    </ListItemIcon>
                    <ListItemText key={i} primary={item.INCLUYE} />
                  </ListItem>
                ))}
            </List>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Condiciones;
