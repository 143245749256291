import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";
import TextField from "../components/TextField";
import Snackbar from "../components/Snackbar";
import Button from "../components/Button";
import {
  BoxItinerario,
  DayImage,
  SubtitleHeader,
} from "../../../../../styles/circuitsdetail";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { Colors } from "../../../../../styles/theme";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import ReactPlayer from "react-player";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import UnpublishedOutlinedIcon from "@mui/icons-material/UnpublishedOutlined";

function Incluye(props) {
  return (
    <Container
      component="section"
      sx={{
        mt: 10,
        mb: 10,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <Typography
            variant="h4"
            marked="center"
            component="h2"
            sx={{ mb: 3 }}
            align="center"
          >
            Cláusulas de responsabilidad
          </Typography>

          <Typography variant="body1" align="center" sx={{ mb: 2 }}>
            Viajes Iberoluna S.A.S con RNT No. 4168 del Ministerio de Comercio,
            Industria y Turismo, se hace totalmente responsable de los productos
            y calidad de los servicios descritos en sus programas de acuerdo con
            lo estipulado en la Ley 300 de 1996, el Decreto 1075/97 y Cláusula
            de responsabilidad sobre el decreto 2438/2010 y las normas que las
            modifiquen, adicionen o reformen. Viajes Iberoluna S.A.S no asume
            responsabilidad frente a los usuarios por el servicio de transporte
            aéreo, salvo que se trate de vuelo fletado y de acuerdo con las
            condiciones del transporte. Estamos comprometidos con el
            cumplimiento de la Resolución 3860 de 2015 sobre sostenibilidad
            turística y de la Ley 679 de 2001 sobre la prevención de la
            prostitución, la pornografía, el turismo sexual, y demás formas de
            explotación sexual con niños, niñas y adolescentes.                        
          </Typography>

          <Typography variant="body1" align="center" sx={{ mb: 2 }}>
          Políticas y condiciones se deben consultar en nuestra página web www.iberolunatravel.com.                      
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Incluye;
