import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  ThemeProvider,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DangerousIcon from "@mui/icons-material/Dangerous";
import "../../App.css";
import { Container, Typography, Box, Stack} from "@mui/material";
import theme from "../../styles/theme";
import Appbar from "../appbar";
import { Fragment, useEffect } from "react";
import Footer from "../footer";
import { UIProvider } from "../../context/ui";
import AppDrawer from "../drawer";
import { Colors } from "../../styles/theme";


function Resultadotransacciondesktop(props) {
  useEffect(() => {
    document.title = "Colreservas - Europamundo";
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="xl"
        sx={{
          background: "#fff",
          padding: "0px !important",
        }}
      >
        <Stack>
        <UIProvider>
        <Appbar />                      
            <Stack>
              <Stack
                direction={!props.matches ? "row" : "column"}
                spacing={2}
                justifyContent="center"
              >
                <Stack alignItems="middle" justifyContent="center">
                  <br></br>
                  <br></br>
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      background: "#fff",
                      borderColor: Colors.blue_ibero,
                      padding: "15px",
                      borderRadius: "15px",
                      borderStyle: "solid",
                      borderWidth: "1px",
                      with: "100%",
                      minWidth: "800px",
                      minHeight: "600px",
                      m: "15px"
                    }}
                  >
                    {props.resultado.estadoTransaccion == null ? (
                      <CircularProgress />
                    ) : (
                      <Fragment>
                        <br></br>
                        <Box
                          display="inline"
                          color={
                            props.resultado.estadoTransaccion === "APROBADA"
                              ? "green"
                              : "red"
                          }
                        >
                          {props.resultado.estadoTransaccion === "APROBADA" ? (
                            <CheckCircleIcon
                              style={{ fontSize: "60px" }}
                            ></CheckCircleIcon>
                          ) : (
                            <DangerousIcon
                              style={{ fontSize: "60px" }}
                            ></DangerousIcon>
                          )}
                        </Box>{" "}
                        <Typography variant="h4">
                          <Box
                            display="inline"
                            color={
                              props.resultado.estadoTransaccion === "APROBADA"
                                ? "green"
                                : "red"
                            }
                          >
                            Transacción{" "}
                            {props.resultado.estadoTransaccion === "APROBADA" ? "en proceso" : props.resultado.estadoTransaccion.toLowerCase()}
                          </Box>{" "}
                        </Typography>
                        <br></br>
                        <br></br>
                        <TableContainer component={Paper}>
                          <Table aria-label="simple table">
                            <TableBody>
                              <TableRow>
                                <TableCell
                                  variant="head"
                                  align="right"
                                  sx={{ borderBottom: "0px" }}
                                >
                                  Número de Pre-Reserva:
                                </TableCell>
                                <TableCell align="left">
                                  {props.resultado.numeroPrereserva}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  variant="head"
                                  align="right"
                                  sx={{ borderBottom: "0px" }}
                                >
                                  Nombre del circuito:
                                </TableCell>
                                <TableCell align="left">
                                  {props.resultado.nombreCircuito}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  variant="head"
                                  align="right"
                                  sx={{ borderBottom: "0px" }}
                                >
                                  Transacción #:
                                </TableCell>
                                <TableCell align="left">
                                  {props.resultado.numeroTransaccion}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  variant="head"
                                  align="right"
                                  sx={{ borderBottom: "0px" }}
                                >
                                  Estado de transacción:
                                </TableCell>
                                <TableCell align="left">
                                  {props.resultado.estadoTransaccion}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  variant="head"
                                  align="right"
                                  sx={{ borderBottom: "0px" }}
                                >
                                  Pago efectuado por:
                                </TableCell>
                                <TableCell align="left">
                                  {props.resultado.tipoPago}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  variant="head"
                                  align="right"
                                  sx={{ borderBottom: "0px" }}
                                >
                                  Pago efectuado a:
                                </TableCell>
                                <TableCell align="left">
                                  {props.resultado.pagoEfectuado}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  variant="head"
                                  align="right"
                                  sx={{ borderBottom: "0px" }}
                                >
                                  Fecha de la transacción:
                                </TableCell>
                                <TableCell align="left">
                                  {props.resultado.fechaInicio}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  variant="head"
                                  align="right"
                                  sx={{ borderBottom: "0px" }}
                                >
                                  Monto pagado:
                                </TableCell>
                                <TableCell align="left">
                                  {props.resultado.montoTransaccion}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                        {props.resultado.estadoTransaccion === "APROBADA" ? (
                          <Typography variant="h6">
                            <Box
                              display="inline"                             
                            >
                              Su transacción está en proceso de validación, en las próximas horas le enviaremos a través de un correo con el resultado del mismo.
                            </Box>{" "}
                          </Typography>
                        ) : (
                          <></>
                        )}
                      </Fragment>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <AppDrawer />
          </UIProvider>
        </Stack>
        <Footer />
      </Container>
    </ThemeProvider>
  );
}

export default Resultadotransacciondesktop;
