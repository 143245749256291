import * as React from "react";
import "react-vertical-timeline-component/style.min.css";
import PropTypes from "prop-types";
import theme, { Colors } from "../../../styles/theme";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import PagoOpciones from "../../pagoOpciones";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Box } from "@mui/system";
import "react-image-gallery/styles/css/image-gallery.css";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Fab,
  FormControlLabel,
  Link,
  ListSubheader,
  Paper,
  Radio,
  RadioGroup,
  Switch,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
// import styled from "@emotion/styled";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useEffect } from "react";
import { DataGrid, GridColDef, gridClasses } from "@mui/x-data-grid";
import { alpha, styled } from "@mui/material/styles";
import axios from "axios";
import Grid from "@mui/material/Grid";
import { LoadingButton } from "@mui/lab";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { AppbarHeaderImage } from "../../../styles/appbar";
import { useNavigate } from "react-router-dom";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const EVEN_OPACITY = 0.2;
const ODD_OPACITY = 0.6;
const colorEven = "#fcefdb";
const colorOdd = "#e8f5fc";

const options = {
  useGrouping: true,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const userLocale =
  navigator.languages && navigator.languages.length
    ? navigator.languages[0].substring(0, 2)
    : navigator.language.substring(0, 2);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: Colors.blue_ibero,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "8px",
  },
}));

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: colorEven,
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(Colors.blue_ibero, EVEN_OPACITY),
      cursor: "pointer",
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        Colors.blue_ibero,
        EVEN_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          Colors.blue_ibero,
          EVEN_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),

        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            Colors.blue_ibero,
            EVEN_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: colorOdd,
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(Colors.orange_ibero, ODD_OPACITY),
      cursor: "pointer",
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        Colors.orange_ibero,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          Colors.orange_ibero,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),

        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            Colors.orange_ibero,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function Cotizacioncircuitdetail(props) {
  const [maxRooms, setMaxRoom] = React.useState(3);
  const [value, setValue] = React.useState(
    props.tabFecha === "*" ? 0 : props.tabFecha
  );
  const [cantSencilla, setCantSencilla] = React.useState(0);
  const [cantCompartida, setCantCompartida] = React.useState(0);
  const [cantDoble, setCantDoble] = React.useState(0);
  const [cantTriple, setCantTriple] = React.useState(0);
  const [arrSencilla, setArrSencilla] = React.useState([]);
  const [arrCompartida, setArrCompartida] = React.useState([]);
  const [arrDoble, setArrDoble] = React.useState([]);
  const [arrTriple, setArrTriple] = React.useState([]);
  const [loadingPrereserva, setLoadingPrereserva] = React.useState(false);
  const [loadingPresupuesto, setLoadingPresupuesto] = React.useState(false);
  const [loadingCotiza, setLoadingCotiza] = React.useState(false);
  const [checkedCondiciones, setCheckedCondiciones] = React.useState(false);
  const [checkedTratamiento, setCheckedTratamiento] = React.useState(false);
  const [checkedCondicionesPresupuesto, setCheckedCondicionesPresupuesto] =
    React.useState(false);
  const [checkedTratamientoPresupuesto, setCheckedTratamientoPresupuesto] =
    React.useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [flagReserva, setFlagReserva] = React.useState(false);
  const [flagPresupuesto, setFlagPresupuesto] = React.useState(false);
  const [openPago, setOpenPago] = React.useState(false);
  const [flagRelacionado, setFlagRelacionado] = React.useState(false);
  const [textoViajerelacionado, setTextoViajerelacionado] = React.useState("");
  const [circuitoRelacionado, setCircuitoRelacionado] = React.useState(0);
  const [iberolunaWeb, setIberolunaWeb] = React.useState(
    window.location.href.toLowerCase().includes("iberolunatravel.online")
  );
  const [arrHabSencilla, setArrHabSencilla] = React.useState([
    {
      NOMBRE: "",
      APELLIDO: "",
      EDAD: "",
      TIPO: "",
      SEXO: "F",
      MEDIA: "",
      HABITACION: "",
      CLUB: "",
      DNI: "",
      PASAPORTE: "",
      NACIMIENTO: "",
      NACIONALIDAD: "",
    },
  ]);
  const [arrHabCompartida, setArrHabCompartida] = React.useState([
    {
      NOMBRE: "",
      APELLIDO: "",
      EDAD: "",
      TIPO: "",
      SEXO: "F",
      MEDIA: "",
      HABITACION: "",
      CLUB: "",
      DNI: "",
      PASAPORTE: "",
      NACIMIENTO: "",
      NACIONALIDAD: "",
    },
  ]);
  const [arrHabDoble, setArrHabDoble] = React.useState([
    {
      NOMBRE: "",
      APELLIDO: "",
      EDAD: "",
      TIPO: "",
      SEXO: "F",
      MEDIA: "",
      HABITACION: "",
      CLUB: "",
      DNI: "",
      PASAPORTE: "",
      NACIMIENTO: "",
      NACIONALIDAD: "",
    },
    {
      NOMBRE: "",
      APELLIDO: "",
      EDAD: "",
      TIPO: "",
      SEXO: "F",
      MEDIA: "",
      HABITACION: "",
      CLUB: "",
      DNI: "",
      PASAPORTE: "",
      NACIMIENTO: "",
      NACIONALIDAD: "",
    },
  ]);
  const [arrHabTriple, setArrHabTriple] = React.useState([
    {
      NOMBRE: "",
      APELLIDO: "",
      EDAD: "",
      TIPO: "",
      SEXO: "F",
      MEDIA: "",
      HABITACION: "",
      CLUB: "",
      DNI: "",
      PASAPORTE: "",
      NACIMIENTO: "",
      NACIONALIDAD: "",
    },
    {
      NOMBRE: "",
      APELLIDO: "",
      EDAD: "",
      TIPO: "",
      SEXO: "F",
      MEDIA: "",
      HABITACION: "",
      CLUB: "",
      DNI: "",
      PASAPORTE: "",
      NACIMIENTO: "",
      NACIONALIDAD: "",
    },
    {
      NOMBRE: "",
      APELLIDO: "",
      EDAD: "",
      TIPO: "",
      SEXO: "F",
      MEDIA: "",
      HABITACION: "",
      CLUB: "",
      DNI: "",
      PASAPORTE: "",
      NACIMIENTO: "",
      NACIONALIDAD: "",
    },
  ]);
  //const [sexoSencilla, setSexoSencilla] = React.useState([]);
  const [precios, setPrecios] = React.useState([
    {
      SENCILLA: "",
      COMPARTIDA: "",
      DOBLE: "",
      TRIPLE: "",
    },
  ]);
  const [precioTrasladoLlegada, setPrecioTrasladoLlegada] = React.useState([
    {
      LUGAR: "",
      NOMBRE_LUGAR: "",
      PRECIO_TOTAL: 0,
      CARGO_EXTRA: 0,
      MONEDA: "",
      TIPO: "",
    },
  ]);
  const [precioTrasladoSalida, setPrecioTrasladoSalida] = React.useState([
    {
      LUGAR: "",
      NOMBRE_LUGAR: "",
      PRECIO_TOTAL: 0,
      CARGO_EXTRA: 0,
      MONEDA: "",
      TIPO: "",
    },
  ]);
  const [indNochesAntes, setIndNochesAntes] = React.useState(false);
  const [indNochesDespues, setIndNochesDespues] = React.useState(false);
  const [horaMinimaLlegada, setHoraMinimaLlegada] = React.useState(0);
  const [horaMaximaLlegada, setHoraMaximaLlegada] = React.useState(0);
  const [horaMinimaSalida, setHoraMinimaSalida] = React.useState(0);
  const [horaMaximaSalida, setHoraMaximaSalida] = React.useState(0);
  const [fechas, setFechas] = React.useState([]);
  const [idfecha, setIdFecha] = React.useState("");
  const [idPresupuesto, setIdPresupuesto] = React.useState("");
  const [fechaLimitePresupuesto, setFechaLimitePresupuesto] =
    React.useState("");
  const [nombreContacto, setNombreContacto] = React.useState("");
  const [apellidoContacto, setApellidoContacto] = React.useState("");
  const [celularContacto, setCelularContacto] = React.useState("");
  const [emailContacto, setEmailContacto] = React.useState("");
  const [errSencillaAges, setErrSencillaAges] = React.useState(false);
  const [errCompartidaAges, setErrCompartidaAges] = React.useState(false);
  const [errDobleAges, setErrDobleAges] = React.useState(false);
  const [errTripleAges, setErrTripleAges] = React.useState(false);
  const [flagCotiza, setFlagCotiza] = React.useState(true);
  const [flagCheck, setFlagCheck] = React.useState(false);
  const [flag, setFlag] = React.useState(false);
  const [flagPrecios, setFlagPrecios] = React.useState(false);
  const [flagReservaDialog, setFlagReservaDialog] = React.useState(false);
  const [flagPresupuestoDialog, setFlagPresupuestoDialog] =
    React.useState(false);
  const [flagDialogMensaje, setFlagDialogMensaje] = React.useState(false);
  const [flagPreReserva, setFlagPreReserva] = React.useState(false);
  const [nochesAdicionalesInicio, setNochesAdicionalesInicio] =
    React.useState("0");
  const [nochesAdicionalesFin, setNochesAdicionalesFin] = React.useState(0);
  const [trasladoLlegada, setTrasladoLlegada] = React.useState(
    props.circuito.TRASLADOLLEGADA
  );
  const [mensajeTraslado, setMensajeTraslado] = React.useState(false);
  const [selPrecioTrasladoLlegada, setSelPrecioTrasladoLlegada] =
    React.useState("");
  const [selPrecioTrasladoSalida, setSelPrecioTrasladoSalida] =
    React.useState("No");
  const [selTrasladoLlegada, setSelTrasladoLlegada] = React.useState("");
  const [selTrasladoSalida, setSelTrasladoSalida] = React.useState("");
  const [trasladoSalida, setTrasladoSalida] = React.useState(
    props.circuito.TRASLADOSALIDA
  );
  const [pasajeros, setPasajeros] = React.useState([]);
  const [coti, setCoti] = React.useState({});
  const [idCotizacion, setIdCotizacion] = React.useState(0);
  const [trasladoTipoLlegada, setTrasladoTipoLlegada] =
    React.useState("aeropuerto");
  const [trasladoTipoSalida, setTrasladoTipoSalida] =
    React.useState("aeropuerto");
  const [trasladoTipoX, setTrasladoTipoX] = React.useState("Llegada");
  const [observaciones, setObservaciones] = React.useState("");
  const [mensajeDialog, setMensajeDialog] = React.useState("");
  const [objTrasladoAeropuerto, setObjTrasladoLlegadaAeropuerto] =
    React.useState([
      {
        CIUDAD: "",
        LINEA: "",
        AEROPUERTO: "",
        VUELO: "",
        HORA: "",
        FECHASALIDA: "",
        FECHALLEGADA: "",
      },
      {
        CIUDAD: "",
        LINEA: "",
        AEROPUERTO: "",
        VUELO: "",
        HORA: "",
        FECHASALIDA: "",
        FECHALLEGADA: "",
      },
    ]);
  const [objTrasladoHotel, setObjTrasladoHotel] = React.useState([
    {
      CIUDAD: "",
      HOTEL: "",
      DIRECCION: "",
      HORA: "",
    },
    {
      CIUDAD: "",
      HOTEL: "",
      DIRECCION: "",
      HORA: "",
    },
  ]);
  const [aeropuertoLlegada, setAeropuertoLlegada] = React.useState("");
  const [aeropuertoSalida, setAeropuertoSalida] = React.useState("");
  const [nochesAntes, setNochesAntes] = React.useState(0);
  const [nochesDespues, setNochesDespues] = React.useState(0);
  const [cardNumber, setCardNumber] = React.useState(1);
  const [codigoReserva, setCodigoReserva] = React.useState("");
  const [expediente, setExpediente] = React.useState("");
  const [fechaPrereserva, setFechaPrereserva] = React.useState("");
  const [horaPreReserva, setHoraPreReserva] = React.useState("");
  const [permiteAbono, setPermiteAbono] = React.useState(false);
  const [fechaLimiteConfirma, setFechaLimiteConfirma] = React.useState("");
  const [flagReservaOK, setFlagReservaOK] = React.useState(false);
  const [flagPresupuestoOK, setFlagPresupuestoOK] = React.useState(false);
  const [LlegadaFDCheck, setLlegadaFDCheck] = React.useState(true);
  const [SalidaFDCheck, setSalidaFDCheck] = React.useState(true);
  const [vueloIberolunaCheck, setVueloIberolunaCheck] = React.useState(true);
  const navigate = useNavigate();
  const columns: GridColDef[] = [
    { field: "Id", hide: true },
    {
      field: "SALIDA",
      headerName: "Salida",
      headerAlign: "center",
      width: !props.matches ? 250 : 135,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "TEMPORADA",
      headerName: "Temporada",
      width: !props.matches ? 200 : 100,
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "PLAZAS",
      headerName: !props.matches ? "Plazas Disponibles" : "Plazas",
      width: !props.matches ? 200 : 80,
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
    },
  ];

  const handleChangeAceptacion = (event) => {
    setCheckedCondiciones(event.target.checked);
  };

  const handleChangeAceptacionTratamiento = (event) => {
    setCheckedTratamiento(event.target.checked);
  };

  const handleChangeAceptacionPresupuesto = (event) => {
    setCheckedCondicionesPresupuesto(event.target.checked);
  };

  const handleChangeAceptacionTratamientoPresupuesto = (event) => {
    setCheckedTratamientoPresupuesto(event.target.checked);
  };

  const jump = (h) => {
    var top = document.getElementById(h).offsetTop; //Getting Y of target element
    window.scrollTo(0, top);
  };

  const handleClickOpenDialog = () => {
    setFlagReservaDialog(true);
  };

  const handleClickOpenDialogPresupuesto = () => {
    setFlagPresupuestoDialog(true);
  };

  const handleCloseDialogPresupuesto = (event, reason) => {
    if (reason !== "backdropClick") {
      if (flagPresupuestoOK) {
        navigate("/home", { replace: true });
      } else {
        jump("AC-inicio");
        setFlagPresupuestoDialog(false);
      }
    }
  };

  const handleCloseDialog = (event, reason) => {
    if (reason !== "backdropClick") {
      if (flagReservaOK) {
        navigate("/home", { replace: true });
      } else {
        jump("AC-inicio");
        setFlagReservaDialog(false);
      }
    }
  };

  const handleCloseDialogMensaje = (event, reason) => {
    if (reason !== "backdropClick") {
      setFlagDialogMensaje(false);
    }
  };

  useEffect(() => {
    if (idfecha !== "") {
      GetPrecios(idfecha.split("-")[0], idfecha.split("-")[1]);
    }
  }, [idfecha]);

  useEffect(() => {
    setFlag(true);
    checkFields();
  }, [flagCheck]);

  useEffect(() => {
    if (flag) {
      if (
        errSencillaAges === true ||
        errCompartidaAges === true ||
        errDobleAges === true ||
        errTripleAges === true ||
        idfecha === ""
      ) {
        setFlagCotiza(true);
        setCardNumber(3);
        props.actualizaCardNumber(3);
      } else {
        ////GetNochesAdicionales(idfecha.split("-")[0],idfecha.split("-")[2]);
        /* GetPreciosTraslados(idfecha.split("-")[0], "Llegada");
        GetPreciosTraslados(idfecha.split("-")[0], "Salida"); */
        setFlagCotiza(false);
        props.actualizaCardNumber(4);
        setCardNumber(4);
        jump("AC-Complete");
      }
    }
  }, [errSencillaAges, errCompartidaAges, errDobleAges, errTripleAges]);

  useEffect(() => {
    ActualizaPreciosTraslados();
  }, [cantSencilla, cantDoble, cantCompartida, cantTriple]);

 useEffect(() => {
    var CurrentDate = new Date();
    var texto = "";
    // console.log(
    //   props.circuito.TEMPORADA +
    //     " 8888 " +
    //     CurrentDate.getMonth() +
    //     " ----- " +
    //     props.circuito.ID_ViajeAnterior +
    //     " ----- " +
    //     props.circuito.ID_ViajeSiguiente
    // );
    if (
      parseInt(props.circuito.TEMPORADA) <
        parseInt(CurrentDate.getFullYear()) &&
      props.circuito.ID_ViajeSiguiente !== 0
    ) {
      texto = "Viajar a partir de Abril " + CurrentDate.getFullYear();
      setFlagRelacionado(true);
      setCircuitoRelacionado(props.circuito.ID_ViajeSiguiente);
    }
 
    //  console.log(props.circuito.TEMPORADA + " 8888 " + CurrentDate.getMonth() + " ----- "+ props.circuito.ID_ViajeAnterior+ " ----- "+ props.circuito.ID_ViajeSiguiente);
    if (
      parseInt(props.circuito.TEMPORADA) ===
        parseInt(CurrentDate.getFullYear()) &&
      parseInt(CurrentDate.getMonth()) < 5 &&
      props.circuito.ID_ViajeAnterior !== 0
    ) {
      texto = "Viajar antes de Abril " + CurrentDate.getFullYear();
      setFlagRelacionado(true);
      setCircuitoRelacionado(props.circuito.ID_ViajeAnterior);
    }
    setTextoViajerelacionado(texto);
  }, [flagCotiza]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const disablePrecios = (event, newValue) => {
    setFlagPrecios(false);
    setFlagReserva(false);
    //setFlagCotiza(true);setTrasladoSalida
  };

  const enableReserva = () => {
    //descargaPDF();
    setFlagReserva(true);
    setFlagPresupuesto(false);
    setCardNumber(6);
    props.actualizaCardNumber(6);
    jump("AC-Prereserva");
  };

  const enablePresupuesto = () => {
    setFlagPresupuesto(true);
    setFlagReserva(false);
    setCardNumber(6);
    props.actualizaCardNumber(6);
    jump("AC-Prereserva");
  };

  const muestraIncluye = () => {
    var criterio = {
      id: coti.IdLogCotizacion,
      IdViaje: idfecha.split("-")[0],
      FechaInicio: idfecha.split("-")[2],
      NochesInicio: nochesAdicionalesInicio,
      NochesFin: nochesAdicionalesFin,
    };
    props.muestraIncluye(criterio);
  };

  const handleChangeNombreContacto = (event) => {
    setNombreContacto(event.target.value);
  };

  const handleChangeApellidoContacto = (event) => {
    setApellidoContacto(event.target.value);
  };

  const handleChangeCelularContacto = (event) => {
    setCelularContacto(event.target.value);
  };

  const handleChangeEmailContacto = (event) => {
    setEmailContacto(event.target.value);
  };

  const handleChangeNochesAdicionalesInicio = (event, newValue) => {
    disablePrecios();
    setNochesAdicionalesInicio(event.target.value);
  };

  const handleChangeNochesAdicionalesFin = (event, newValue) => {
    disablePrecios();
    setNochesAdicionalesFin(event.target.value);
  };

  const handleChangeTrasladoSalida = (event, newValue) => {
    disablePrecios();

    setSelTrasladoSalida(event.target.value);
    if (event.target.value === "Si") {
      //Colocado para evitar que el usuario seleccione detalle de traslado, si es "Si" se cotizará como sin traslado con la nota que canbiará de precio al gestionar
      setSelPrecioTrasladoSalida("No");
      setTrasladoSalida(false);
    } else {
      setSelPrecioTrasladoSalida(event.target.value);
      setTrasladoSalida(event.target.value !== "No");
    }

    /*     setSelPrecioTrasladoSalida(event.target.value);
    setTrasladoSalida(event.target.value !== "No"); */
    const myArray = event.target.value.split("-");
    if (myArray[1] === "HH") {
      setTrasladoTipoSalida("hotel");
    } else {
      setTrasladoTipoSalida("aeropuerto");
    }
  };

  const handleChangeTrasladoLlegada = (event, newValue) => {
    disablePrecios();

    setSelTrasladoLlegada(event.target.value);
    if (event.target.value === "Si") {
      //Colocado para evitar que el usuario seleccione detalle de traslado, si es "Si" se cotizará como sin traslado con la nota que canbiará de precio al gestionar
      const filtered = precioTrasladoLlegada.filter((a) => a.TIPO === "AH");
      if (filtered.length > 0) {
        filtered.sort((a, b) => a.PRECIO_TOTAL - a.PRECIO_TOTAL);
        setSelPrecioTrasladoLlegada(filtered[0].LUGAR + "-" + filtered[0].TIPO);
        setTrasladoLlegada(true);
      } else {
        setSelPrecioTrasladoLlegada("No");
        setTrasladoLlegada(false);
      }
    } else {
      setSelPrecioTrasladoLlegada(event.target.value);
      setTrasladoLlegada(event.target.value !== "No");
    }
    const myArray = event.target.value.split("-");
    if (myArray[1] === "HH") {
      setTrasladoTipoLlegada("hotel");
    } else {
      setTrasladoTipoLlegada("aeropuerto");
    }
  };

  const handleChangeTipoLlegada = (event, newValue) => {
    //disablePrecios();
    setTrasladoTipoLlegada(event.target.value);
    setTrasladoTipoX(event.target.value);
  };
  const handleChangeTipoSalida = (event, newValue) => {
    //disablePrecios();
    setTrasladoTipoSalida(event.target.value);
    setTrasladoTipoX(event.target.value);
  };

  const selectedChangeGrid = (event) => {
    if (event.row.PLAZAS === "0") {
      setIdFecha("");
      ShowMensaje("La fecha seleccionada no tiene plazas disponible.");
      /* setMensajeDialog("La fecha seleccionada no tiene plazas disponible.");
      setFlagDialogMensaje(true); */
      //alert("La fecha seleccionada no tiene plazas disponible.");
      setCardNumber(1);
      props.actualizaCardNumber(1);
    } else {
      GetNochesAdicionales(props.circuito.ID_VIAJE, event.row.id);
      setIdFecha(
        props.circuito.ID_VIAJE +
          "-" +
          event.row.TEMPORADA.replace("-", "&") +
          "-" +
          event.row.id
      );
      setCantSencilla(0);
      setCantCompartida(0);
      setCantDoble(0);
      setCantTriple(0);
      setArrSencilla([]);
      setArrCompartida([]);
      setArrDoble([]);
      setArrTriple([]);
      setCardNumber(2);
      props.actualizaCardNumber(2);
      jump("AC-habitacion");
    }
  };

  const checkArr = (arr) => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] === "") {
        return false;
      }
    }
    return true;
  };

  function checkFields() {
    //setFlagCotiza(false);
    if (cantSencilla > 0) {
      if (!checkArr(arrSencilla)) {
        setErrSencillaAges(true);
      } else {
        setErrSencillaAges(false);
      }
    } else {
      setErrSencillaAges(false);
    }
    if (cantDoble > 0) {
      if (!checkArr(arrDoble)) {
        setErrDobleAges(true);
      } else {
        setErrDobleAges(false);
      }
    } else {
      setErrDobleAges(false);
    }
    if (cantCompartida > 0) {
      if (!checkArr(arrCompartida)) {
        setErrCompartidaAges(true);
      } else {
        setErrCompartidaAges(false);
      }
    } else {
      setErrCompartidaAges(false);
    }
    if (cantTriple > 0) {
      if (!checkArr(arrTriple)) {
        setErrTripleAges(true);
      } else {
        setErrTripleAges(false);
      }
    } else {
      setErrTripleAges(false);
    }
  }

  const GetPrecios = async (idviaje, temporada) => {
    const url =
      "https://iberolunatravel.online/wse/api/getprecioscircuito?id=" +
      idviaje +
      "&temporada=" +
      temporada.replace("&", "-");
    const resp = await axios.get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
    setPrecios(resp.data);
  };

  const GetPreciosTraslados = async (idviaje, traslado) => {
    let numpaxs =
      cantSencilla + cantCompartida + cantDoble * 2 + cantTriple * 3;
    if (numpaxs > 0) {
      let ciudad = "";
      if (traslado === "Llegada") {
        ciudad = props.circuito.CIUDADINICIO;
      } else {
        ciudad = props.circuito.CIUDADFIN;
      }
      const url =
        "https://iberolunatravel.online/wse//api/GetPrecioTrasladoCircuito?temporada=2022&ciudad=" +
        ciudad +
        "&numPaxs=" +
        numpaxs +
        "&id_viaje=" +
        idviaje +
        "&tipoTraslado=" +
        traslado +
        "&logid=" +
        props.logid;

      const resp = await axios.get(url, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });
      if (traslado === "Llegada") {
        setPrecioTrasladoLlegada([...resp.data]);
        setHoraMinimaLlegada(resp.data[0].HORA_MINIMA);
        setHoraMaximaLlegada(resp.data[0].HORA_MAXIMA);
        setIndNochesAntes(resp.data.length > 0 ? true : false);
      } else {
        setPrecioTrasladoSalida(resp.data);
        setHoraMinimaSalida(resp.data[0].HORA_MINIMA);
        setHoraMaximaSalida(resp.data[0].HORA_MAXIMA);
        setIndNochesDespues(resp.data.length > 0 ? true : false);
      }
    }
  };

  const GetNochesAdicionales = async (idviaje, fecha) => {
    const url =
      "https://iberolunatravel.online/wse/api/GetNochesAdicionales?&id_viaje=" +
      idviaje +
      "&fecha=" +
      fecha +
      "&logid=" +
      props.logid;

    const resp = await axios.get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
    console.log(resp.data.NochesAntes);
    setNochesAntes(resp.data.NochesAntes);
    setNochesDespues(resp.data.NochesDespues);
  };

  const handleObservaciones = (event) => {
    event.persist();
    setObservaciones(event.target.value);
  };

  const handleSencillaChange = (event) => {
    event.persist();
    var ind = event.target.name.substring(
      event.target.name.length - 1,
      event.target.name.length
    );
    arrSencilla[ind] = event.target.value;
    setArrSencilla(arrSencilla);
    setFlagCheck(!flagCheck);
  };

  const handleCompartidaChange = (event) => {
    event.persist();
    var ind = event.target.name.substring(
      event.target.name.length - 1,
      event.target.name.length
    );
    arrCompartida[ind] = event.target.value;
    setArrCompartida(arrCompartida);
    setFlagCheck(!flagCheck);
  };

  const handleDobleChange = (event) => {
    event.persist();
    var ind = event.target.name.substring(
      event.target.name.length - 1,
      event.target.name.length
    );
    arrDoble[ind] = event.target.value;
    setArrDoble(arrDoble);
    setFlagCheck(!flagCheck);
  };

  const handleTripleChange = (event) => {
    event.persist();
    var ind = event.target.name.substring(
      event.target.name.length - 1,
      event.target.name.length
    );
    arrTriple[ind] = event.target.value;
    setArrTriple(arrTriple);
    setFlagCheck(!flagCheck);
  };

  const asignaPax = (campo, valor, paxGGG) => {
    switch (campo) {
      case "nombre":
        paxGGG.NOMBRE = valor;
        break;
      case "apellido":
        paxGGG.APELLIDO = valor;
        break;
      case "edad":
        paxGGG.EDAD = valor;
        break;
      case "nacimiento":
        paxGGG.NACIMIENTO = valor;
        break;
      case "pasaporte":
        paxGGG.PASAPORTE = valor;
        break;
      case "sexo":
        paxGGG.SEXO = valor;
        break;
      case "nacionalidad":
        paxGGG.NACIONALIDAD = valor;
        break;
      default:
        break;
    }
    //return pax;
  };
  const handleHabitaciones = (event) => {
    //event.persist();
    console.log(event.target.name);
    if (event.target.name.split("-")[4] == 0) {
      if (event.target.name.split("-")[1] === "nombre")
        setNombreContacto(event.target.value);
      if (event.target.name.split("-")[1] === "apellido")
        setApellidoContacto(event.target.value);
    }
    switch (event.target.name.split("-")[0]) {
      case "Sencilla":
        asignaPax(
          event.target.name.split("-")[1],
          event.target.value,
          arrHabSencilla[event.target.name.split("-")[3]]
        );
        setArrHabSencilla((prev) => [...arrHabSencilla]);
        //setArrHabSencilla({ ...arrHabSencilla });
        //setArrHabSencilla(arrHabSencilla);
        //setArrHabSencilla((prev) => [...prev, arrHabSencilla]);
        break;
      case "Compartida":
        asignaPax(
          event.target.name.split("-")[1],
          event.target.value,
          arrHabCompartida[event.target.name.split("-")[3]]
        );
        //setArrHabCompartida({ ...arrHabCompartida });
        setArrHabCompartida((prev) => [...arrHabCompartida]);
        //setArrHabCompartida((prev) => [...prev, arrHabCompartida]);
        break;
      case "Doble":
        asignaPax(
          event.target.name.split("-")[1],
          event.target.value,
          arrHabDoble[event.target.name.split("-")[3]]
        );
        //setArrHabDoble(arrHabDoble);
        //setArrHabDoble((prev) => [...prev, arrHabDoble]);
        setArrHabDoble((prev) => [...arrHabDoble]);
        break;
      case "Triple":
        asignaPax(
          event.target.name.split("-")[1],
          event.target.value,
          arrHabTriple[event.target.name.split("-")[3]]
        );
        //setArrHabTriple({...arrHabTriple});
        setArrHabTriple((prev) => [...arrHabTriple]);
        break;
      default:
        break;
    }
  };

  const handleChangeVIChecked = (event) => {
    setVueloIberolunaCheck(event.target.checked);
  };

  const handleChangeFDChecked = (event) => {
    if (event.target.name.split("FD")[0] === "Llegada") {
      setLlegadaFDCheck(event.target.checked);
    } else {
      setSalidaFDCheck(event.target.checked);
    }
  };

  const handleTrasladoAeropuerto = (event) => {
    //event.persist();

    switch (event.target.name.split("-")[2]) {
      case "ciudad":
        if (event.target.name.split("-")[0] === "Llegada") {
          objTrasladoAeropuerto[0].CIUDAD = event.target.value;
        } else {
          objTrasladoAeropuerto[1].CIUDAD = event.target.value;
        }
        break;
      case "linea":
        if (event.target.name.split("-")[0] === "Llegada") {
          objTrasladoAeropuerto[0].LINEA = event.target.value;
          setAeropuertoLlegada(event.target.value);
        } else {
          objTrasladoAeropuerto[1].LINEA = event.target.value;
          setAeropuertoSalida(event.target.value);
        }
        break;
      /*  case "aeropuerto":
        if (event.target.name.split("-")[0] === "Llegada") {
          objTrasladoAeropuerto[0].AEROPUERTO = event.target.value;
        } else {
          objTrasladoAeropuerto[1].AEROPUERTO = event.target.value;
        }
        break; */
      case "vuelo":
        if (event.target.name.split("-")[0] === "Llegada") {
          objTrasladoAeropuerto[0].VUELO = event.target.value;
        } else {
          objTrasladoAeropuerto[1].VUELO = event.target.value;
        }
        break;
      case "hora":
        if (event.target.name.split("-")[0] === "Llegada") {
          objTrasladoAeropuerto[0].HORA = event.target.value;
        } else {
          objTrasladoAeropuerto[1].HORA = event.target.value;
        }
        break;
      case "FechaSalida":
        if (event.target.name.split("-")[0] === "Llegada") {
          objTrasladoAeropuerto[0].FECHASALIDA = event.target.value;
        } else {
          objTrasladoAeropuerto[1].FECHASALIDA = event.target.value;
        }
        break;
      case "FechaLlegada":
        if (event.target.name.split("-")[0] === "Llegada") {
          objTrasladoAeropuerto[0].FECHALLEGADA = event.target.value;
        } else {
          objTrasladoAeropuerto[1].FECHALLEGADA = event.target.value;
        }
        break;
      default:
        break;
    }
    if (event.target.name.split("-")[0] === "Llegada") {
      objTrasladoAeropuerto[0].AEROPUERTO =
        selPrecioTrasladoLlegada.split("-")[0];
    } else {
      objTrasladoAeropuerto[1].AEROPUERTO =
        selPrecioTrasladoSalida.split("-")[0];
    }
    /*  if (event.target.name.split("-")[0] === "Llegada") {
      if (
        (objTrasladoAeropuerto[0].LINEA != "") &
        (objTrasladoAeropuerto[0].VUELO != "") &
        (objTrasladoAeropuerto[0].HORA != "")
      ) {
        chequeoVuelo(
          event.target.name.split("-")[0],
          objTrasladoAeropuerto[0].LINEA,
          objTrasladoAeropuerto[0].VUELO,
          objTrasladoAeropuerto[0].FECHASALIDA,
          objTrasladoAeropuerto[0].FECHALLEGADA,
          objTrasladoAeropuerto[0].HORA,
          objTrasladoAeropuerto[0].AEROPUERTO
        );
      }
    } else {
      if (
        (objTrasladoAeropuerto[1].LINEA != "") &
        (objTrasladoAeropuerto[1].VUELO != "") &
        (objTrasladoAeropuerto[1].HORA != "")
      ) {
        chequeoVuelo(
          event.target.name.split("-")[0],
          objTrasladoAeropuerto[1].LINEA,
          objTrasladoAeropuerto[1].VUELO,
          objTrasladoAeropuerto[1].FECHASALIDA,
          "",
          objTrasladoAeropuerto[1].HORA,
          objTrasladoAeropuerto[1].AEROPUERTO
        );
      }
    } */
    //setObjTrasladoLlegadaAeropuerto(objTrasladoAeropuerto);
  };

  const chequeoVuelo = async (
    tipo,
    linea,
    vuelo,
    fechaSalida,
    fechaLlegada,
    hora,
    aeropuerto
  ) => {
    /* var fecha =
      idfecha.split("-")[2].slice(0, 4) +
      "-" +
      idfecha.split("-")[2].slice(4, 6) +
      "-" +
      idfecha.split("-")[2].slice(6, 8);
    var mydate = new Date(
      idfecha.split("-")[2].slice(0, 4),
      idfecha.split("-")[2].slice(4, 6),
      idfecha.split("-")[2].slice(6, 8),
      0,
      0
    );
    mydate.setDate(mydate.getDate() - 1);
    ver fechaVuelo=      fec.getFullYear() * 1e4 +
      (fec.getMonth() + 1) * 100 +
      fec.getDate() +
      ""; */
    var fechaVueloSalida = fechaSalida.replaceAll("-", "");
    var fechaVueloLlegada = fechaLlegada.replaceAll("-", "");
    var horaVuelo = hora.replaceAll(":", "");
    const url =
      "https://iberolunatravel.online/wse///api/getvueloscircuito?lineaAerea=" +
      linea +
      "&numeroVuelo=" +
      vuelo +
      "&fechaVuelo=" +
      fechaVueloSalida;
    const resp = await axios.get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    if (tipo === "Llegada") {
      if (resp.data.VueloLlegada === null) {
        ShowMensaje("El vuelo de llegada indicado no existe.");
        return false;
      } else {
        if (
          resp.data.VueloLlegada.fecha !== fechaVueloLlegada ||
          resp.data.VueloLlegada.hora !== horaVuelo ||
          resp.data.VueloLlegada.lugar !== aeropuerto
        ) {
          ShowMensaje("Los datos del vuelo de llegada están errado.");
          return false;
        }
      }
    } else {
      if (resp.data.VueloSalida === null) {
        ShowMensaje("El vuelo de Salida indicado no existe.");
        return false;
      } else {
        if (
          resp.data.VueloSalida.fecha !== fechaVueloSalida ||
          resp.data.VueloSalida.hora !== horaVuelo ||
          resp.data.VueloSalida.lugar !== aeropuerto
        ) {
          ShowMensaje("Los datos del vuelo de salida están errado.");
          return false;
        }
      }
    }
  };

  const ShowMensaje = (mensaje) => {
    setMensajeDialog(mensaje);
    setFlagDialogMensaje(true);
  };

  const handleTrasladoHotel = (event) => {
    //event.persist();
    switch (event.target.name.split("-")[2]) {
      case "ciudad":
        if (event.target.name.split("-")[0] === "Llegada") {
          objTrasladoHotel[0].CIUDAD = event.target.value;
        } else {
          objTrasladoHotel[1].CIUDAD = event.target.value;
        }
        break;
      case "hotel":
        if (event.target.name.split("-")[0] === "Llegada") {
          objTrasladoHotel[0].HOTEL = event.target.value;
        } else {
          objTrasladoHotel[1].HOTEL = event.target.value;
        }
        break;
      case "direccion":
        if (event.target.name.split("-")[0] === "Llegada") {
          objTrasladoHotel[0].DIRECCION = event.target.value;
        } else {
          objTrasladoHotel[1].DIRECCION = event.target.value;
        }
        break;
      case "hora":
        if (event.target.name.split("-")[0] === "Llegada") {
          objTrasladoHotel[0].HORA = event.target.value;
        } else {
          objTrasladoHotel[1].HORA = event.target.value;
        }
        break;
      default:
        break;
    }
    setObjTrasladoHotel(objTrasladoHotel);
  };

  const SelectSencilla = (cantidad) => {
    let content = [];
    for (let i = 0; i < cantidad; i++) {
      content.push(
        <Box>
          <Typography variant="body2" sx={{ mt: 1 }}>
            Habitación {i + 1}
          </Typography>
          <FormControl size="small" fullWidth sx={{ mb: 0.5 }}>
            <TextField
              error={errSencillaAges}
              label=""
              id={"sencilla-" + i}
              name={"sencilla-" + i}
              defaultValue=""
              size="small"
              onChange={handleSencillaChange}
            />
          </FormControl>
        </Box>
      );
    }
    return content;
  };

  const generateRoom = () => {
    let content = [];
    var conta = 0;
    var cuentaLinea = 0;
    for (let i = 0; i < cantSencilla; i++) {
      content.push(Room("Sencilla", i, 1, conta, cuentaLinea));
      conta = conta + 1;
      cuentaLinea = cuentaLinea + 1;
    }

    conta = 0;
    for (let i = 0; i < cantCompartida; i++) {
      content.push(Room("Compartida", i, 1, conta, cuentaLinea));
      conta = conta + 1;
      cuentaLinea = cuentaLinea + 1;
    }
    conta = 0;
    for (let i = 0; i < cantDoble; i++) {
      content.push(Room("Doble", i, 2, conta, cuentaLinea));
      conta = conta + 2;
      cuentaLinea = cuentaLinea + 1;
    }
    conta = 0;
    for (let i = 0; i < cantTriple; i++) {
      content.push(Room("Triple", i, 3, conta, cuentaLinea));
      conta = conta + 3;
      cuentaLinea = cuentaLinea + 1;
    }
    return content;
  };

  const Room = (tipo, posicion, cantLine, conta, cuentaLinea) => {
    let content = [];
    content.push(
      <>
        <Grid
          container
          spacing={{ xs: 1, md: 1 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ p: 1, border: "1px dashed grey" }}
        >
          <Grid item xs={12} sm={12} md={12}>
            <Typography
              variant="h6"
              color="text.secondary"
              sx={{ textAlign: "center", fontWeight: "bold" }}
            >
              Habitación {tipo} {posicion + 1}:
            </Typography>
          </Grid>
          {!props.matches ? (
            <>
              <Grid item xs={2} sm={2} md={2}>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{ textAlign: "center" }}
                >
                  Nombre
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2} md={2}>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{ textAlign: "center" }}
                >
                  Apellido
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2} md={2}>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{ textAlign: "center" }}
                >
                  Fec.Nacimiento
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2} md={2}>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{ textAlign: "center" }}
                >
                  Pasaporte
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2} md={2}>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{ textAlign: "center" }}
                >
                  Nacionalidad
                </Typography>
              </Grid>
              {/* <Grid item xs={2} sm={2} md={1}>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ textAlign: "center" }}
            >
              Edad
            </Typography>
          </Grid> */}
              <Grid item xs={2} sm={2} md={2}>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{ textAlign: "center" }}
                >
                  Sexo
                </Typography>
              </Grid>
            </>
          ) : (
            <></>
          )}

          {generateLineRooms(tipo, posicion, cantLine, conta, cuentaLinea)}
        </Grid>
        <br />
      </>
    );
    return content;
  };

  const generateLineRooms = (tipo, posicion, cantLine, conta, cuentaLinea) => {
    let content = [];
    for (let i = 0; i < cantLine; i++) {
      content.push(lineRoom(tipo, posicion, i + conta, cuentaLinea));
      cuentaLinea = cuentaLinea + 1;
    }
    return content;
  };

  const lineRoom = (tipo, posicion, cantLine, cuentaLinea) => {
    let content = [];

    content.push(
      <>
        {cantLine > 0 && props.matches ? (
          <Grid item xs={12} sm={12} md={12}>
            <Divider sx={{ paddingTop: "8px", paddingBottom: "8px" }}></Divider>
          </Grid>
        ) : (
          <></>
        )}
        <Grid item xs={2} sm={2} md={2}>
          {props.matches ? (
            <>
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{ textAlign: "center" }}
              >
                Nombre
              </Typography>
            </>
          ) : (
            <></>
          )}
          <TextField
            id={
              tipo + "-nombre-" + posicion + "-" + cantLine + "-" + cuentaLinea
            }
            name={
              tipo + "-nombre-" + posicion + "-" + cantLine + "-" + cuentaLinea
            }
            label=""
            size="small"
            onChange={handleHabitaciones}
          />
        </Grid>
        <Grid item xs={2} sm={2} md={2}>
          {props.matches ? (
            <>
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{ textAlign: "center" }}
              >
                Apellido
              </Typography>
            </>
          ) : (
            <></>
          )}
          <TextField
            id={
              tipo +
              "-apellido-" +
              posicion +
              "-" +
              cantLine +
              "-" +
              cuentaLinea
            }
            name={
              tipo +
              "-apellido-" +
              posicion +
              "-" +
              cantLine +
              "-" +
              cuentaLinea
            }
            label=""
            size="small"
            onChange={handleHabitaciones}
          />
        </Grid>
        <Grid item xs={2} sm={2} md={2}>
          {props.matches ? (
            <>
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{ textAlign: "center" }}
              >
                Fec.Nacimiento
              </Typography>
            </>
          ) : (
            <></>
          )}
          <TextField
            id={
              tipo +
              "-nacimiento-" +
              posicion +
              "-" +
              cantLine +
              "-" +
              cuentaLinea
            }
            name={
              tipo +
              "-nacimiento-" +
              posicion +
              "-" +
              cantLine +
              "-" +
              cuentaLinea
            }
            label=""
            size="small"
            type="date"
            onChange={handleHabitaciones}
          />
        </Grid>
        <Grid item xs={2} sm={2} md={2}>
          {props.matches ? (
            <>
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{ textAlign: "center" }}
              >
                Pasaporte
              </Typography>
            </>
          ) : (
            <></>
          )}
          <TextField
            id={
              tipo +
              "-pasaporte-" +
              posicion +
              "-" +
              cantLine +
              "-" +
              cuentaLinea
            }
            name={
              tipo +
              "-pasaporte-" +
              posicion +
              "-" +
              cantLine +
              "-" +
              cuentaLinea
            }
            label=""
            size="small"
            onChange={handleHabitaciones}
          />
        </Grid>
        <Grid item xs={2} sm={2} md={2}>
          {props.matches ? (
            <>
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{ textAlign: "center" }}
              >
                Nacionalidad
              </Typography>
            </>
          ) : (
            <></>
          )}
          <TextField
            id={
              tipo +
              "-nacionalidad-" +
              posicion +
              "-" +
              cantLine +
              "-" +
              cuentaLinea
            }
            name={
              tipo +
              "-nacionalidad-" +
              posicion +
              "-" +
              cantLine +
              "-" +
              cuentaLinea
            }
            label=""
            size="small"
            onChange={handleHabitaciones}
          />
        </Grid>
        {/*       <Grid item xs={2} sm={2} md={1}>
          <TextField
            id={tipo + "-edad-" + posicion + "-" + cantLine + "-" + cuentaLinea}
            name={tipo + "-edad-" + posicion + "-" + cantLine + "-" + cuentaLinea}
            label=""
            size="small"
            type="number"
          />
        </Grid> */}
        <Grid item xs={2} sm={2} md={2}>
          {props.matches ? (
            <>
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{ textAlign: "center" }}
              >
                Sexo
              </Typography>
            </>
          ) : (
            <></>
          )}
          {tipo === "Doble" ? (
            <Select
              labelId="demo-select-small"
              id={
                tipo + "-sexo-" + posicion + "-" + cantLine + "-" + cuentaLinea
              }
              name={
                tipo + "-sexo-" + posicion + "-" + cantLine + "-" + cuentaLinea
              }
              value={arrHabDoble[cantLine].SEXO}
              /*  value={
              tipo === "Doble"
                ? arrHabDoble[cantLine].SEXO
                : tipo === "Compartida"
                ? arrHabCompartida[cantLine].SEXO
                : tipo === "Sencilla"
                ? arrHabSencilla[cantLine].SEXO
                : arrHabTriple[cantLine].SEXO
            } */
              label=""
              size="small"
              // onChange={handleChange}
              onChange={handleHabitaciones}
              sx={{ width: "100%" }}
            >
              <MenuItem value={"F"}>Femenino</MenuItem>
              <MenuItem value={"M"}>Masculino</MenuItem>
            </Select>
          ) : (
            <></>
          )}
          {tipo === "Sencilla" ? (
            <Select
              labelId="demo-select-small"
              id={
                tipo + "-sexo-" + posicion + "-" + cantLine + "-" + cuentaLinea
              }
              name={
                tipo + "-sexo-" + posicion + "-" + cantLine + "-" + cuentaLinea
              }
              value={arrHabSencilla[cantLine].SEXO}
              /*  value={
              tipo === "Doble"
                ? arrHabDoble[cantLine].SEXO
                : tipo === "Compartida"
                ? arrHabCompartida[cantLine].SEXO
                : tipo === "Sencilla"
                ? arrHabSencilla[cantLine].SEXO
                : arrHabTriple[cantLine].SEXO
            } */
              label=""
              size="small"
              // onChange={handleChange}
              onChange={handleHabitaciones}
              sx={{ width: "100%" }}
            >
              <MenuItem value={"F"}>Femenino</MenuItem>
              <MenuItem value={"M"}>Masculino</MenuItem>
            </Select>
          ) : (
            <></>
          )}
          {tipo === "Compartida" ? (
            <Select
              labelId="demo-select-small"
              id={
                tipo + "-sexo-" + posicion + "-" + cantLine + "-" + cuentaLinea
              }
              name={
                tipo + "-sexo-" + posicion + "-" + cantLine + "-" + cuentaLinea
              }
              value={arrHabCompartida[cantLine].SEXO}
              /*  value={
              tipo === "Doble"
                ? arrHabDoble[cantLine].SEXO
                : tipo === "Compartida"
                ? arrHabCompartida[cantLine].SEXO
                : tipo === "Sencilla"
                ? arrHabSencilla[cantLine].SEXO
                : arrHabTriple[cantLine].SEXO
            } */
              label=""
              size="small"
              // onChange={handleChange}
              onChange={handleHabitaciones}
              sx={{ width: "100%" }}
            >
              <MenuItem value={"F"}>Femenino</MenuItem>
              <MenuItem value={"M"}>Masculino</MenuItem>
            </Select>
          ) : (
            <></>
          )}
          {tipo === "Triple" ? (
            <Select
              labelId="demo-select-small"
              id={
                tipo + "-sexo-" + posicion + "-" + cantLine + "-" + cuentaLinea
              }
              name={
                tipo + "-sexo-" + posicion + "-" + cantLine + "-" + cuentaLinea
              }
              value={arrHabTriple[cantLine].SEXO}
              /*  value={
              tipo === "Doble"
                ? arrHabDoble[cantLine].SEXO
                : tipo === "Compartida"
                ? arrHabCompartida[cantLine].SEXO
                : tipo === "Sencilla"
                ? arrHabSencilla[cantLine].SEXO
                : arrHabTriple[cantLine].SEXO
            } */
              label=""
              size="small"
              // onChange={handleChange}
              onChange={handleHabitaciones}
              sx={{ width: "100%" }}
            >
              <MenuItem value={"F"}>Femenino</MenuItem>
              <MenuItem value={"M"}>Masculino</MenuItem>
            </Select>
          ) : (
            <></>
          )}
        </Grid>
      </>
    );
    return content;
  };

  const generateNochesAdicionales = (cantNoches) => {
    let content = [];
    for (let i = 1; i <= cantNoches; i++) {
      content.push(
        <MenuItem key={i} value={i}>
          {i} noche{i != 1 ? "s" : ""} adicional{i != 1 ? "es" : ""}
        </MenuItem>
      );
    }
    return content;
  };

  const DatosContacto = (opcion) => {
    let content = [];
    content.push(
      <>
        <Grid
          container
          spacing={{ xs: 1, md: 1 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ p: 1, border: "1px dashed grey" }}
        >
          <Grid item xs={12} sm={12} md={12}>
            {/*  <Typography
              variant="h6"
              color="text.secondary"
              sx={{ textAlign: "center", fontWeight: "bold" }}
            >
              Habitación {tipo} {posicion}:
            </Typography> */}
          </Grid>
          {/*  <Grid item xs={2} sm={2} md={3}>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ textAlign: "center" }}
            >
              Nombre
            </Typography>
          </Grid> 
          <Grid item xs={2} sm={2} md={3}>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ textAlign: "center" }}
            >
              Apellido
            </Typography>
          </Grid>
          <Grid item xs={2} sm={2} md={3}>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ textAlign: "center" }}
            >
              Celular
            </Typography>
          </Grid>
          <Grid item xs={2} sm={2} md={3}>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ textAlign: "center" }}
            >
              Email
            </Typography>
          </Grid> */}
          <Grid item xs={2} sm={2} md={3}>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ textAlign: "center" }}
            >
              Nombre
            </Typography>
            <TextField
              id={"nombre" + opcion}
              value={nombreContacto}
              onChange={handleChangeNombreContacto}
              label=""
              size="small"
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={2} sm={2} md={3}>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ textAlign: "center" }}
            >
              Apellido
            </Typography>
            <TextField
              id={"apellido" + opcion}
              value={apellidoContacto}
              onChange={handleChangeApellidoContacto}
              label=""
              size="small"
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={2} sm={2} md={3}>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ textAlign: "center" }}
            >
              Celular
            </Typography>
            <TextField
              id={"celular" + opcion}
              value={celularContacto}
              onChange={handleChangeCelularContacto}
              label=""
              size="small"
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={2} sm={2} md={3}>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ textAlign: "center" }}
            >
              Email
            </Typography>
            <TextField
              id={"email" + opcion}
              value={emailContacto}
              onChange={handleChangeEmailContacto}
              label=""
              size="small"
              type="email"
              sx={{ width: "100%" }}
            />
          </Grid>
        </Grid>
        <br />
      </>
    );
    return content;
  };

  const GenerateTransfer = (tipo) => {
    let content = [];
    content.push(
      <>
        <Grid
          container
          spacing={{ xs: 1, md: 1 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ p: 1, border: "1px dashed grey" }}
          alignItems="center"
          justifyContent="center"
        >
          {/*           <Grid item xs={12} sm={12} md={12}  style={{ display: "flex", alignItems: "center" }}>
           
          </Grid> */}
          <Grid item xs={12} sm={12} md={12}>
            <Typography
              variant="h6"
              color="text.secondary"
              sx={{ textAlign: "center", fontWeight: "bold" }}
            >
              Traslado de {tipo}
            </Typography>
            <br></br>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ width: "100%" }}
            >
              <Stack direction="row" spacing={2}>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  sx={{ textAlign: "center" }}
                >
                  ¿Informar luego detalle de los vuelos?
                </Typography>
                {tipo === "Llegada" ? (
                  <FormControlLabel
                    id="LlegadaFDCheck"
                    control={
                      <Switch
                        defaultChecked
                        name="LlegadaFDCheck"
                        checked={LlegadaFDCheck}
                        onChange={handleChangeFDChecked}
                      />
                    }
                    label="Si"
                  />
                ) : (
                  <FormControlLabel
                    id="SalidaFDCheck"
                    control={
                      <Switch
                        defaultChecked
                        name="SalidaFDCheck"
                        checked={SalidaFDCheck}
                        onChange={handleChangeFDChecked}
                      />
                    }
                    label="Si"
                  />
                )}
              </Stack>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ width: "100%" }}
            >
              {tipo === "Llegada" ? (
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={trasladoTipoLlegada}
                    onChange={handleChangeTipoLlegada}
                    size="small"
                    disabled
                  >
                    <FormControlLabel
                      value="aeropuerto"
                      control={<Radio />}
                      label="Aeropuerto - Hotel"
                      disabled
                    />
                    <FormControlLabel
                      value="hotel"
                      control={<Radio />}
                      label="Hotel - Hotel"
                      disabled
                    />
                  </RadioGroup>
                </FormControl>
              ) : (
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={trasladoTipoSalida}
                    onChange={handleChangeTipoSalida}
                    size="small"
                  >
                    <FormControlLabel
                      value="aeropuerto"
                      control={<Radio />}
                      label="Hotel - Aeropuerto"
                      disabled
                    />
                    <FormControlLabel
                      value="hotel"
                      control={<Radio />}
                      label="Hotel - Hotel"
                      disabled
                    />
                  </RadioGroup>
                </FormControl>
              )}
            </Box>
          </Grid>
          {tipo === "Llegada" ? (
            <>
              {trasladoTipoLlegada === "aeropuerto"
                ? TransferAeropuerto(tipo)
                : TransferHotel(tipo)}
            </>
          ) : (
            <>
              {trasladoTipoSalida === "aeropuerto"
                ? TransferAeropuerto(tipo)
                : TransferHotel(tipo)}
            </>
          )}
        </Grid>
        <br />
      </>
    );
    return content;
  };

  const TransferAeropuerto = (tipo) => {
    let content = [];
    content.push(
      <>
        {(!LlegadaFDCheck && tipo === "Llegada") ||
        (!SalidaFDCheck && tipo === "Salida") ? (
          <>
            <Grid item xs={2} sm={4} md={2}>
              {tipo === "Llegada" ? (
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{ textAlign: "center" }}
                >
                  Ciudad procedencia
                </Typography>
              ) : (
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{ textAlign: "center" }}
                >
                  Ciudad destino
                </Typography>
              )}
            </Grid>
            <Grid item xs={2} sm={2} md={2}>
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{ textAlign: "center" }}
              >
                Línea aérea
              </Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={1}>
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{ textAlign: "center" }}
              >
                Aeropuerto
              </Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={tipo === "Llegada" ? 2 : 3}>
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{ textAlign: "center" }}
              >
                Fec.Salida
              </Typography>
            </Grid>
            {tipo === "Llegada" ? (
              <Grid item xs={2} sm={2} md={2}>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{ textAlign: "center" }}
                >
                  Fec.Llegada
                </Typography>
              </Grid>
            ) : (
              <></>
            )}
            <Grid item xs={2} sm={2} md={tipo === "Llegada" ? 1 : 2}>
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{ textAlign: "center" }}
              >
                # vuelo
              </Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={2}>
              {tipo === "Llegada" ? (
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{ textAlign: "center" }}
                >
                  Hora de llegada
                </Typography>
              ) : (
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{ textAlign: "center" }}
                >
                  Hora de salida
                </Typography>
              )}
            </Grid>
            {lineTransferAeropuerto(tipo)}

            <br />
          </>
        ) : (
          <></>
        )}
      </>
    );
    return content;
  };

  const lineTransferAeropuerto = (tipo) => {
    let content = [];
    content.push(
      <>
        <Grid item xs={2} sm={4} md={2}>
          <TextField
            id={tipo + "-Aeropuerto-ciudad"}
            name={tipo + "-Aeropuerto-ciudad"}
            label=""
            size="small"
            sx={{ width: "100%" }}
            onChange={handleTrasladoAeropuerto}
          />
        </Grid>
        <Grid item xs={2} sm={2} md={2}>
          <Select
            labelId="demo-select-small"
            id={tipo + "-Aeropuerto-linea"}
            name={tipo + "-Aeropuerto-linea"}
            //value={aeropuertoLlegada}
            value={tipo === "Llegada" ? aeropuertoLlegada : aeropuertoSalida}
            label=""
            size="small"
            onChange={handleTrasladoAeropuerto}
            sx={{ width: "100%" }}
          >
            {props.lineasaereas.map((item) => (
              <MenuItem
                key={item.ID_LINEA}
                value={item.ID_LINEA}
                // style={getStyles(name, personName, theme)}
              >
                {item.NOMBRE}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={2} sm={2} md={1}>
          <TextField
            disabled
            id={tipo + "-Aeropuerto-aeropuerto"}
            name={tipo + "-Aeropuerto-aeropuerto"}
            label=""
            value={
              tipo === "Llegada"
                ? selPrecioTrasladoLlegada.split("-")[0]
                : selPrecioTrasladoSalida.split("-")[0]
            }
            size="small"
            sx={{ width: "100%" }}
            onChange={handleTrasladoAeropuerto}
          />
        </Grid>
        <Grid item xs={2} sm={2} md={tipo === "Llegada" ? 2 : 3}>
          <TextField
            id={tipo + "-Aeropuerto-FechaSalida"}
            name={tipo + "-Aeropuerto-FechaSalida"}
            label=""
            size="small"
            type="date"
            sx={{ width: "100%" }}
            onChange={handleTrasladoAeropuerto}
          />
        </Grid>
        {tipo === "Llegada" ? (
          <Grid item xs={2} sm={2} md={2}>
            <TextField
              id={tipo + "-Aeropuerto-FechaLlegada"}
              name={tipo + "-Aeropuerto-FechaLlegada"}
              label=""
              size="small"
              type="date"
              onChange={handleTrasladoAeropuerto}
            />
          </Grid>
        ) : (
          <></>
        )}
        <Grid item xs={2} sm={2} md={tipo === "Llegada" ? 1 : 2}>
          <TextField
            id={tipo + "-Aeropuerto-vuelo"}
            name={tipo + "-Aeropuerto-vuelo"}
            label=""
            size="small"
            type="number"
            sx={{ width: "100%" }}
            onChange={handleTrasladoAeropuerto}
          />
        </Grid>
        <Grid item xs={2} sm={2} md={2}>
          <TextField
            id={tipo + "-Aeropuerto-hora"}
            name={tipo + "-Aeropuerto-hora"}
            label=""
            size="small"
            type="time"
            sx={{ width: "100%" }}
            onChange={handleTrasladoAeropuerto}
          />
        </Grid>
      </>
    );
    return content;
  };

  //----------------------
  const TransferHotel = (tipo) => {
    let content = [];
    content.push(
      <>
        <Grid item xs={2} sm={4} md={2}>
          {tipo === "Llegada" ? (
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ textAlign: "center" }}
            >
              Ciudad procedencia
            </Typography>
          ) : (
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ textAlign: "center" }}
            >
              Ciudad destino
            </Typography>
          )}
        </Grid>
        <Grid item xs={2} sm={2} md={3}>
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ textAlign: "center" }}
          >
            Nombre hotel
          </Typography>
        </Grid>
        <Grid item xs={2} sm={2} md={5}>
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ textAlign: "center" }}
          >
            Dirección hotel
          </Typography>
        </Grid>
        <Grid item xs={2} sm={2} md={2}>
          {tipo === "Llegada" ? (
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ textAlign: "center" }}
            >
              Hora de llegada
            </Typography>
          ) : (
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ textAlign: "center" }}
            >
              Hora de salida
            </Typography>
          )}
        </Grid>
        {lineTransferHotel(tipo)}
        <br />
      </>
    );
    return content;
  };

  const lineTransferHotel = (tipo) => {
    let content = [];
    content.push(
      <>
        <Grid item xs={2} sm={4} md={2}>
          <TextField
            id={tipo + "-Hotel-ciudad"}
            name={tipo + "-Hotel-ciudad"}
            label=""
            size="small"
            sx={{ width: "100%" }}
            onChange={handleTrasladoHotel}
          />
        </Grid>
        <Grid item xs={2} sm={2} md={3}>
          <TextField
            id={tipo + "-Hotel-hotel"}
            name={tipo + "-Hotel-hotel"}
            label=""
            size="small"
            sx={{ width: "100%" }}
            onChange={handleTrasladoHotel}
          />
        </Grid>
        <Grid item xs={2} sm={2} md={5}>
          <TextField
            id={tipo + "-Hotel-direccion"}
            name={tipo + "-Hotel-direccion"}
            label=""
            size="small"
            sx={{ width: "100%" }}
            onChange={handleTrasladoHotel}
          />
        </Grid>
        <Grid item xs={2} sm={2} md={2}>
          <TextField
            id={tipo + "-Hotel-hora"}
            name={tipo + "-Hotel-hora"}
            label=""
            size="small"
            type="time"
            sx={{ width: "100%" }}
            onChange={handleTrasladoHotel}
          />
        </Grid>
      </>
    );
    return content;
  };
  //--------------------

  const SelectCompartida = (cantidad) => {
    let content = [];
    for (let i = 0; i < cantidad; i++) {
      content.push(
        <Box>
          <Typography variant="body2" sx={{ mt: 1 }}>
            Habitación {i + 1}
          </Typography>
          <FormControl size="small" fullWidth sx={{ mb: 0.5 }}>
            <TextField
              error={errCompartidaAges}
              label=""
              id={"Compartida-" + i}
              name={"Compartida-" + i}
              defaultValue=""
              size="small"
              onChange={handleCompartidaChange}
            />
          </FormControl>
        </Box>
      );
    }
    return content;
  };

  const SelectDoble = (cantidad) => {
    let content = [];
    var multi = 0;
    for (let i = 0; i < cantidad; i++) {
      content.push(
        <Box>
          <Typography variant="body2" sx={{ mt: 1 }}>
            Habitación {i + 1}
          </Typography>
          <FormControl size="small" fullWidth sx={{ mb: 0.5 }}>
            <TextField
              error={errDobleAges}
              label=""
              id={"doble-" + (0 + multi)}
              name={"doble-" + (0 + multi)}
              defaultValue=""
              size="small"
              onChange={handleDobleChange}
            />
          </FormControl>
          <FormControl size="small" fullWidth>
            <TextField
              error={errDobleAges}
              label=""
              id={"doble-" + (1 + multi)}
              name={"doble-" + (1 + multi)}
              defaultValue=""
              size="small"
              onChange={handleDobleChange}
            />
          </FormControl>
        </Box>
      );
      multi = multi + 2;
    }
    return content;
  };

  const SelectTriple = (cantidad) => {
    let content = [];
    var multi = 0;
    for (let i = 0; i < cantidad; i++) {
      content.push(
        <Box>
          <Typography variant="body2" sx={{ mt: 1 }}>
            Habitación {i + 1}
          </Typography>
          <FormControl size="small" fullWidth sx={{ mb: 0.5 }}>
            <TextField
              error={errTripleAges}
              label=""
              id={"Triple-" + (0 + multi)}
              name={"Triple-" + (0 + multi)}
              defaultValue=""
              size="small"
              onChange={handleTripleChange}
            />
          </FormControl>
          <FormControl size="small" fullWidth sx={{ mb: 0.5 }}>
            <TextField
              error={errTripleAges}
              label=""
              id={"Triple-" + (1 + multi)}
              name={"Triple-" + (1 + multi)}
              defaultValue=""
              size="small"
              onChange={handleTripleChange}
            />
          </FormControl>
          <FormControl size="small" fullWidth sx={{ mb: 0.5 }}>
            <TextField
              error={errTripleAges}
              label=""
              id={"Triple-" + (2 + multi)}
              name={"Triple-" + (2 + multi)}
              defaultValue=""
              size="small"
              onChange={handleTripleChange}
            />
          </FormControl>
        </Box>
      );
      multi = multi + 3;
    }
    return content;
  };

  const GridFechas = (fechas, id) => {
    let content = [];
    if (fechas == null) {
      fechas = [];
    }

    content.push(
      <div style={{ height: 300, width: "100%" }}>
        {fechas.length > 0 ? (
          <StripedDataGrid
            key={id}
            rows={fechas}
            columns={columns}
            density="compact"
            hideFooterPagination={true}
            hideFooterSelectedRowCount={true}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
            onRowClick={selectedChangeGrid}
          />
        ) : (
          <CircularProgress />
        )}
      </div>
    );

    return content;
  };

  const LineaCotizacion = (texto1, texto2, texto3, tipo, color = false) => {
    let content = [];
    const blue = "#e8f5fc";
    content.push(
      <>
        <Grid
          item
          xs={2}
          sm={6}
          md={8}
          sx={
            color
              ? { backgroundColor: blue }
              : tipo === "Total"
              ? { backgroundColor: "transparent" }
              : { backgroundColor: "#fcefdb" }
          }
        >
          <Typography
            variant="body1"
            color="text.secondary"
            sx={
              tipo === "Titulo" || tipo === "Total"
                ? tipo === "Total"
                  ? {
                      fontWeight: "bolder",
                      color: Colors.blue_ibero,
                      fontSize: "16px",
                    }
                  : { fontWeight: "bolder" }
                : tipo === "SubItem"
                ? {
                    fontWeight: "normal",
                    paddingLeft: !props.matches ? "30px" : "3px",
                  }
                : { fontWeight: "normal" }
            }
          >
            {texto1 +
              (!props.matches ? "" : texto2 === "" ? "" : " (" + texto2 + ")")}
          </Typography>
        </Grid>
        {!props.matches ? (
          <>
            <Grid
              item
              xs={2}
              sm={2}
              md={2}
              sx={
                color
                  ? { backgroundColor: blue }
                  : tipo === "Total"
                  ? { backgroundColor: "transparent" }
                  : { backgroundColor: "#fcefdb" }
              }
            >
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{ textAlign: "center" }}
              >
                {texto2}
              </Typography>
            </Grid>
          </>
        ) : (
          <></>
        )}

        <Grid
          item
          xs={2}
          sm={2}
          md={2}
          sx={
            color
              ? { backgroundColor: blue }
              : tipo === "Total"
              ? { backgroundColor: "transparent" }
              : { backgroundColor: "#fcefdb" }
          }
        >
          <Typography
            variant="body1"
            color="text.secondary"
            sx={
              tipo === "Titulo" || tipo === "Total"
                ? tipo === "Total"
                  ? {
                      fontWeight: "bolder",
                      color: Colors.blue_ibero,
                      fontSize: "16px",
                      textAlign: "right",
                      paddingRight: "7px",
                    }
                  : {
                      fontWeight: "bolder",
                      textAlign: "right",
                      paddingRight: "7px",
                    }
                : {
                    fontWeight: "normal",
                    textAlign: "right",
                    paddingRight: "7px",
                  }
            }
          >
            {texto3}
          </Typography>
        </Grid>
      </>
    );

    return content;
  };

  const LineaHabitaciones = () => {
    let content = [];
    if (cantSencilla !== 0) {
      content.push(
        LineaCotizacion(
          cantSencilla +
            (cantSencilla === 1
              ? " Habitación Sencilla"
              : " Habitaciones Sencillas"),
          "",
          Number(coti.PrecioSencilla).toLocaleString(userLocale, options),
          "SubItem",
          true
        )
      );
    }
    if (cantCompartida !== 0) {
      content.push(
        LineaCotizacion(
          cantCompartida +
            (cantCompartida === 1
              ? " Habitación Compartida"
              : " Habitaciones Compartidas"),
          "",
          Number(coti.PrecioCompartida).toLocaleString(userLocale, options),
          "SubItem",
          true
        )
      );
    }
    if (cantDoble !== 0) {
      content.push(
        LineaCotizacion(
          cantDoble +
            (cantDoble === 1 ? " Habitación Doble" : " Habitaciones Dobles"),
          "",
          Number(coti.PrecioDoble).toLocaleString(userLocale, options),
          "SubItem",
          true
        )
      );
    }
    if (cantTriple !== 0) {
      content.push(
        LineaCotizacion(
          cantTriple +
            (cantTriple === 1 ? " Habitación Triple" : " Habitaciones Triples"),
          "",
          Number(coti.PrecioTriple).toLocaleString(userLocale, options),
          "SubItem",
          true
        )
      );
    }
    return content;
  };

  const LineaItems = (posRow) => {
    let content = [];
    if (coti.PrecioNochesInicio !== 0) {
      posRow = !posRow;
      content.push(
        LineaCotizacion(
          "Noches Adicionales al inicio del viaje",
          nochesAdicionalesInicio,
          Number(coti.PrecioNochesInicio).toLocaleString(userLocale, options),
          "Item",
          posRow
        )
      );
    }
    if (coti.PrecioNochesFin !== 0) {
      posRow = !posRow;
      content.push(
        LineaCotizacion(
          "Noches Adicionales al final del viaje",
          nochesAdicionalesFin,
          Number(coti.PrecioNochesFin).toLocaleString(userLocale, options),
          "Item",
          posRow
        )
      );
    }
    // if  (coti.PrecioTrasladoLlegada !== 0) {
    if (trasladoLlegada) {
      posRow = !posRow;
      content.push(
        LineaCotizacion(
          "Traslado de Llegada",
          coti.IncluyeTrasladoLlegada,
          Number(coti.PrecioTrasladoLlegada).toLocaleString(
            userLocale,
            options
          ),
          "Item",
          posRow
        )
      );
    }

    // };
    // if  (coti.PrecioTrsaladoSalida !== 0) {
    if (trasladoSalida) {
      posRow = !posRow;
      content.push(
        LineaCotizacion(
          "Traslado de Salida",
          coti.IncluyeTrasladoSalida,
          Number(coti.PrecioTrsaladoSalida).toLocaleString(userLocale, options),
          "Item",
          posRow
        )
      );
    }

    // };
    return content;
  };

  const UpdateArray = (arr, long) => {
    if (arr.length === 0 || arr.length < long) {
      for (let i = arr.length; i < long; i++) {
        arr.push("");
      }
    } else {
      var p = arr.length - long;
      for (let i = 1; i <= p; i++) {
        arr.pop();
      }
    }
  };

  const checkDisableSelect = (tipo) => {
    if (idfecha === "") {
      return true;
    } else {
      switch (tipo) {
        case "select-sencilla":
          // if (!props.circuito.PERMITESINGLE) {
          //   return true;
          // } else {
          //   return false;
          // }
          if (precios[0].SENCILLA === 0) {
            return true;
          } else {
            return false;
          }
        case "select-compartida":
          // if (!props.circuito.PERMITECOMPATIR) {
          //   return true;
          // } else {
          //   return false;
          // }
          if (precios[0].COMPARTIDA === 0) {
            return true;
          } else {
            return false;
          }
        case "select-triple":
          // if (!props.circuito.PERMITETRIPLE) {
          //   return true;
          // } else {
          //   return false;
          // }
          if (precios[0].TRIPLE === 0) {
            return true;
          } else {
            return false;
          }
        default:
          return false;
      }
    }
  };

  const handleChangeSelect = (event, newValue) => {
    switch (event.target.name) {
      case "select-sencilla":
        setCantSencilla(event.target.value);
        UpdateArray(arrSencilla, event.target.value);
        break;
      case "select-doble":
        setCantDoble(event.target.value);
        UpdateArray(arrDoble, event.target.value * 2);
        break;
      case "select-compartida":
        setCantCompartida(event.target.value);
        UpdateArray(arrCompartida, event.target.value);
        break;
      case "select-triple":
        setCantTriple(event.target.value);
        UpdateArray(arrTriple, event.target.value * 3);
        break;
      default:
        break;
    }

    setFlagCheck(!flagCheck);
    setCardNumber(3);
    props.actualizaCardNumber(3);
    disablePrecios();
  };

  const ActualizaPreciosTraslados = () => {
    GetPreciosTraslados(idfecha.split("-")[0], "Llegada");
    GetPreciosTraslados(idfecha.split("-")[0], "Salida");
  };
  const ResetArrHab = () => {
    /*     arrHabSencilla.length = 0;
    arrHabCompartida.length = 0;
    arrHabDoble.length = 0;
    arrHabTriple.length = 0; */
    setArrHabSencilla([]);
    setArrHabCompartida([]);
    setArrHabDoble([]);
    setArrHabDoble([]);
    setArrHabTriple([]);
    let paxR = {
      NOMBRE: "",
      APELLIDO: "",
      EDAD: "",
      TIPO: "",
      SEXO: "F",
      MEDIA: "",
      HABITACION: "",
      CLUB: "",
      DNI: "",
      PASAPORTE: "",
      NACIMIENTO: "",
      NACIONALIDAD: "",
    };
    setArrHabSencilla((prev) => [...prev, { ...paxR }]);
    setArrHabCompartida((prev) => [...prev, { ...paxR }]);
    setArrHabDoble((prev) => [...prev, { ...paxR }]);
    setArrHabDoble((prev) => [...prev, { ...paxR }]);
    setArrHabTriple((prev) => [...prev, { ...paxR }]);
    setArrHabTriple((prev) => [...prev, { ...paxR }]);
    setArrHabTriple((prev) => [...prev, { ...paxR }]);

    for (let i = 0; i < cantSencilla - 1; i++) {
      setArrHabSencilla((prev) => [...prev, { ...paxR }]);
    }
    for (let i = 0; i < cantCompartida - 1; i++) {
      setArrHabCompartida((prev) => [...prev, { ...paxR }]);
    }
    for (let i = 0; i < cantDoble - 1; i++) {
      for (let j = 0; j < 2; j++) {
        setArrHabDoble((prev) => [...prev, { ...paxR }]);
      }
    }
    for (let i = 0; i < cantTriple - 1; i++) {
      for (let j = 0; j < 3; j++) {
        setArrHabTriple((prev) => [...prev, { ...paxR }]);
      }
    }
  };

  function getYearDiff(date1, date2) {
    return Math.abs(date2.getFullYear() - date1.getFullYear());
  }

  function getAge(dateString) {
    // convert user input values into date object
    /* var startDate = new Date(idfecha.split("-")[2].substring(4, 0) + "-" + idfecha.split("-")[2].substring(4, 6) + "-" + idfecha.split("-")[2].substring(6) + " 0:0")
    var endDate  = new Date(dateString + " 0:0");
             
    var  ageDate = new Date(endDate.getTime() - startDate.getTime()); 
    var ageInMilliseconds = startDate - endDate; */
    const d = new Date();
    let diff = d.getTimezoneOffset() / 60;
    let d1 =
      diff > 0
        ? "-" + Math.abs(diff).toString().padStart(2, "0")
        : "+" + Math.abs(diff).toString().padStart(2, "0");

    const pp = new Date("2022-12-29T00:00:00.000" + d1 + ":00");

    var startDate = new Date(
      idfecha.split("-")[2].substring(4, 0) +
        "-" +
        idfecha.split("-")[2].substring(4, 6) +
        "-" +
        idfecha.split("-")[2].substring(6) +
        "T00:00:00.000" +
        d1 +
        ":00"
    );
    var endDate = new Date(dateString + "T00:00:00.000" + d1 + ":00");
    var checkDate = new Date(
      startDate.getUTCFullYear() +
        "-" +
        (endDate.getUTCMonth() + 1) +
        "-" +
        endDate.getUTCDate() +
        "T00:00:00.000" +
        d1 +
        ":00"
    );
    var age = 0;
    if (checkDate > endDate) {
      age = startDate.getUTCFullYear() - endDate.getUTCFullYear() - 1;
    } else {
      age = startDate.getUTCFullYear() - endDate.getUTCFullYear();
    }

    return age;
  }

  const descargaPDF = () => {
    const url =
      "https://iberolunatravel.online/wse/api/GetPDFCircuito?logid=" +
      props.logid +
      "&circuito=" +
      idfecha.split("-")[0];
    axios
      .get(url, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {});
  };

  const handleCloseDialogPago = () => {
    setOpenPago(false);
  };

  const ActivaOpciones = () => {
    setOpenPago(true);
    //handleCloseDialog();
  };

  const calculaPrecio = () => {
    //setOpenPago(true);
    var paxes = [];
    var pax = [];

    if (flagPresupuesto) {
      if (!ValidateEmail(emailContacto)) {
        ShowMensaje(
          "Favor indicar el email correctamente del Titular del presupuesto."
        );
        return false;
      }
      setLoadingPresupuesto(true);
    }
    setLoadingCotiza(true);

    let flagContinue = true;
    if (selPrecioTrasladoLlegada === "") {
      ShowMensaje("Favor indicar el traslado de llegada.");
      setLoadingCotiza(false);
      flagContinue = false;
    }
    if (selPrecioTrasladoSalida === "") {
      setLoadingCotiza(false);
      ShowMensaje("Favor indicar el traslado de salida.");
      flagContinue = false;
    }

    if (flagContinue) {
      ResetArrHab();
      disablePrecios();

      for (let i = 0; i < arrSencilla.length; i++) {
        pax = { TIPO: "SG", EDAD: arrSencilla[i] };
        paxes.push(pax);
      }
      for (let i = 0; i < arrCompartida.length; i++) {
        pax = { TIPO: "", EDAD: arrCompartida[i] };
        paxes.push(pax);
      }
      for (let i = 0; i < arrDoble.length; i++) {
        pax = { TIPO: "DB", EDAD: arrDoble[i] };
        paxes.push(pax);
      }
      for (let i = 0; i < arrTriple.length; i++) {
        pax = { TIPO: "TR", EDAD: arrTriple[i] };
        paxes.push(pax);
      }
      var book = {
        NOCHESADICIONALESINICIO: nochesAdicionalesInicio,
        NOCHESADICIONALESFIN: nochesAdicionalesFin,
        TRASLADOLLEGADA: trasladoLlegada,
        TRASLADOSALIDA: trasladoSalida,
        LUGARTRASLADOLLEGADA: selPrecioTrasladoLlegada.split("-")[0],
        LUGARTRASLADOSALIDA: selPrecioTrasladoSalida.split("-")[0],
        FECHA: idfecha,
        PASAJEROS: paxes,
        TEMPORADA: props.circuito.TEMPORADA,
        logid: props.logid,
        presupuesto: flagPresupuesto,
        nombreCircuito: props.circuito.NOMBREVIAJE,
        nombre: nombreContacto,
        apellido: apellidoContacto,
        celular: celularContacto,
        email: emailContacto,
        tokenwb: "oTn84s(3Ms95=",
      };

      //var urlBooking = "https://iberolunatravel.online/wse/api/CotizacionCircuito";
      var urlBooking =
        "https://iberolunatravel.online/wse/api/CotizacionCircuito";
      /*   if (resultX.AgenciaWeb !== "Ibero" || resultX.AgenciaWeb !== "MC") {
      urlBooking = "https://webtravel2-online.com/APIhoteles/api/ReservaServicios/agencia=Ibero";  
    } */
      axios
        .post(urlBooking, book, {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((response) => {
          setLoadingCotiza(false);
          if (response.data.CantPersonas === 0) {
            ShowMensaje(
              "No es posible cotizar el viaje con las condiciones indicadas."
            );
          } else {
            if (flagPresupuesto) {
              setLoadingPresupuesto(false);
              if (response.data.IdPresupuesto !== 0) {
                setIdPresupuesto(response.data.IdPresupuesto);
                var fecX = new Date(response.data.FechaLimitePresupuesto);
                var fec =
                  fecX.toLocaleDateString("es-ES", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  }) +
                  ", " +
                  fecX.toLocaleTimeString("es-ES", {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  });
                setFechaLimitePresupuesto(fec);
                setFlagPresupuestoOK(true);
              } else {
                setFlagPresupuestoOK(false);
              }

              handleClickOpenDialogPresupuesto();
            } else {
              setCoti(response.data);
              setFlagPrecios(true);
              setCardNumber(5);
              props.actualizaCardNumber(5);
              jump("AC-Cotizacion");
            }
          }
        })
        .catch((error) => {
          setFlagPrecios(false);
          setCardNumber(4);
          props.actualizaCardNumber(4);
          setLoadingCotiza(false);
          ShowMensaje(
            "No es posible cotizar el viaje con las condiciones indicadas."
          );
        }); //
    }
  };

  /*   const armaPax = (pax) => {
    pax.NOMBRE = hab.NOMBRE;
    pax.APELLIDO = hab.APELLIDO;
    pax.TIPO =
      getYearDiff(new Date(hab.NACIMIENTO), new Date(Date.now())) > 15
        ? "A"
        : "N";
    pax.SEXO = hab.SEXO;
    pax.PASAPORTE = hab.PASAPORTE;
    pax.NACIONALIDAD = hab.NACIONALIDAD;
    pax.NACIMIENTO = hab.NACIMIENTO;
  }; */

  const checkArrHabs = (arr, arrEdades) => {
    let i = 0;
    let flagH = 0;
    arr.forEach((hab) => {
      if (
        hab.NOMBRE === "" ||
        hab.APELLIDO === "" ||
        hab.NACIMIENTO === "" ||
        hab.PASAPORTE === "" ||
        hab.NACIONALIDAD === "" ||
        hab.SEXO === ""
      ) {
        flagH = 1;
        //return false;
      } else {
        if (getAge(hab.NACIMIENTO).toString() !== arrEdades[i]) {
          flagH = 2;
          //return false;
        }
      }
      i = i + 1;
    });
    return flagH;
  };

  const checkHabitaciones = () => {
    if (arrSencilla.length > 0) {
      return checkArrHabs(arrHabSencilla, arrSencilla);
    }
    if (arrDoble.length > 0) {
      return checkArrHabs(arrHabDoble, arrDoble);
      /* if (!checkArrHabs(arrHabDoble, arrDoble)) {
        return false;
      } */
    }
    if (arrCompartida.length > 0) {
      if (!checkArrHabs(arrHabCompartida, arrCompartida)) {
        return false;
      }
    }
    if (arrTriple.length > 0) {
      if (!checkArrHabs(arrHabTriple, arrTriple)) {
        return false;
      }
    }
    return true;
  };

  function ValidateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    return false;
  }

  // const preReservarPrueba = () => {
  //   let flagVal = checkHabitaciones();
  //   switch (flagVal) {
  //     case 1:
  //       ShowMensaje("Favor indique los datos conmpletos de los pasajeros.");
  //       return false;
  //       break;
  //     case 2:
  //       ShowMensaje("La fecha de nacimiento no coincide con la edad indicada.");
  //       return false;
  //       break;
  //     default:
  // }

  const OpenViajeRelacionado = () => {
    window.open("#/circuito/" + circuitoRelacionado + "/*", "_top");
    window.location.reload();
  };

  const preReservar = () => {
    var paxes = [];
    var pax = [];
    var trLlegada = {};
    var trSalida = {};

    // console.log("PRERESERVAR");
    /*  console.log(arrHabSencilla);
     console.log(arrHabCompartida);
     console.log(arrHabDoble);
     console.log(arrDoble);
     console.log(cantDoble);
     console.log(arrHabTriple);
     console.log(arrTriple);
      console.log(objTrasladoAeropuerto);
     console.log(objTrasladoHotel); */

    //Valida que los campos esté completo
    let flagContinueReserva = false;
    if (
      nombreContacto === "" ||
      apellidoContacto === "" ||
      celularContacto === "" ||
      emailContacto === ""
    ) {
      flagContinueReserva = true;
    }
    if (flagContinueReserva) {
      ShowMensaje(
        "Favor indicar los datos completos del Titular de la Pre-Reserva."
      );
      return false;
    }
    if (!ValidateEmail(emailContacto)) {
      flagContinueReserva = true;
    }
    if (flagContinueReserva) {
      ShowMensaje(
        "Favor indicar el email correctamente del Titular de la Pre-Reserva."
      );
      return false;
    }

    if (!vueloIberolunaCheck && !LlegadaFDCheck && trasladoLlegada) {
      if (trasladoTipoLlegada === "aeropuerto") {
        if (
          selPrecioTrasladoLlegada.split("-")[0] === "" ||
          objTrasladoAeropuerto[0].CIUDAD === "" ||
          objTrasladoAeropuerto[0].VUELO === "" ||
          objTrasladoAeropuerto[0].LINEA === "" ||
          objTrasladoAeropuerto[0].HORA === ""
        ) {
          flagContinueReserva = true;
        } else {
          //Chequea los vuelos
          // if (
          //   !chequeoVuelo(
          //     "Llegada",
          //     objTrasladoAeropuerto[0].LINEA,
          //     objTrasladoAeropuerto[0].VUELO,
          //     objTrasladoAeropuerto[0].FECHASALIDA,
          //     objTrasladoAeropuerto[0].FECHALLEGADA,
          //     objTrasladoAeropuerto[0].HORA,
          //     selPrecioTrasladoLlegada.split("-")[0]
          //   )
          // ) {
          //   return false;
          // }
        }
      } else {
        if (
          objTrasladoHotel[0].CIUDAD === "" ||
          objTrasladoHotel[0].HOTEL === "" ||
          objTrasladoHotel[0].DIRECCION === "" ||
          objTrasladoHotel[0].HORA === ""
        ) {
          flagContinueReserva = true;
        }
      }
    }
    if (flagContinueReserva) {
      ShowMensaje("Favor indicar los datos del traslado de llegada.");
      return false;
    }

    if (!vueloIberolunaCheck && !SalidaFDCheck && trasladoSalida) {
      if (trasladoTipoSalida === "aeropuerto") {
        if (
          selPrecioTrasladoSalida.split("-")[0] === "" ||
          objTrasladoAeropuerto[1].CIUDAD === "" ||
          objTrasladoAeropuerto[1].VUELO === "" ||
          objTrasladoAeropuerto[1].LINEA === "" ||
          objTrasladoAeropuerto[1].HORA === ""
        ) {
          flagContinueReserva = true;
        } else {
          //Chequea vuelos
          /*  if (
            !chequeoVuelo(
              "Salida",
              objTrasladoAeropuerto[1].LINEA,
              objTrasladoAeropuerto[1].VUELO,
              objTrasladoAeropuerto[1].FECHASALIDA,
              "",
              objTrasladoAeropuerto[1].HORA,
              selPrecioTrasladoSalida.split("-")[0]
            )
          ) {
            return false;
          } */
        }
      } else {
        if (
          objTrasladoHotel[1].CIUDAD === "" ||
          objTrasladoHotel[1].HOTEL === "" ||
          objTrasladoHotel[1].DIRECCION === "" ||
          objTrasladoHotel[1].HORA === ""
        ) {
          flagContinueReserva = true;
        }
      }
    }
    if (flagContinueReserva) {
      ShowMensaje("Favor indicar los datos del traslado de salida.");
      return false;
    }

    let flagVal = checkHabitaciones();
    switch (flagVal) {
      case 1:
        ShowMensaje("Favor indique los datos conmpletos de los pasajeros.");
        return false;
        break;
      case 2:
        ShowMensaje("La fecha de nacimiento no coincide con la edad indicada.");
        return false;
        break;
      default:
      // code block
    }
    /*  if (checkHabitaciones() === false) {
      alert("Favor indique los datos que faltan.");
      return false;
    } */

    if (!flagContinueReserva) {
      setLoadingPrereserva(true);

      if (arrSencilla > 0) {
        arrHabSencilla.forEach((hab) => {
          hab.HABITACION = "SG";
          paxes.push(hab);
        });
      }
      if (cantDoble > 0) {
        arrHabDoble.forEach((hab) => {
          hab.HABITACION = "DB";
          paxes.push(hab);
        });
      }
      if (arrCompartida > 0) {
        arrHabCompartida.forEach((hab) => {
          hab.HABITACION = "";
          paxes.push(hab);
        });
      }
      if (cantTriple > 0) {
        arrHabTriple.forEach((hab) => {
          hab.HABITACION = "TR";
          paxes.push(hab);
        });
      }

      if (LlegadaFDCheck || vueloIberolunaCheck) {
        trLlegada = {
          TIPO_TRASLADO: "L",
          FECHA: idfecha.split("-")[2],
          CIUDAD: "",
          AEROPUERTO: "", // objTrasladoAeropuerto[0].AEROPUERTO,
          OPCION_ELEGIDA: "FD",
          CIUDAD_PROC_DEST: "",
          NUM_VUELO: "",
          COMPANYA_AEREA: "",
          HORA_RECOGIDA: "",
          NOMBRE_HOTEL: "",
          DIRECCION_HOTEL: "",
        };
      } else {
        if (trasladoLlegada) {
          if (trasladoTipoLlegada === "aeropuerto") {
            //REVISA LA HORA DEL TRASLADO DENTRO DEL MARGEN DE EUROPAMUNDO
            const horaIndicada = parseInt(
              objTrasladoAeropuerto[0].HORA.replace(":", "")
            );
            const horaMaxima = parseInt(horaMaximaLlegada);
            if (
              horaMaxima != 0 &&
              nochesAdicionalesInicio == 0 &&
              horaIndicada > horaMaxima
            ) {
              ShowMensaje(
                "La hora del vuelo de llegada debe ser antes de las " +
                  horaMaximaLlegada +
                  " ."
              );
              setLoadingPrereserva(false);
              return false;
            }
            trLlegada = {
              TIPO_TRASLADO: "L",
              FECHA: idfecha.split("-")[2],
              CIUDAD: "",
              AEROPUERTO: selPrecioTrasladoLlegada.split("-")[0], // objTrasladoAeropuerto[0].AEROPUERTO,
              OPCION_ELEGIDA: "AH",
              CIUDAD_PROC_DEST: objTrasladoAeropuerto[0].CIUDAD,
              NUM_VUELO: objTrasladoAeropuerto[0].VUELO,
              COMPANYA_AEREA: objTrasladoAeropuerto[0].LINEA,
              HORA_RECOGIDA: objTrasladoAeropuerto[0].HORA,
              Fecha_Salida: objTrasladoAeropuerto[0].FECHASALIDA,
              Fecha_Llegada: objTrasladoAeropuerto[0].FECHALLEGADA,
              NOMBRE_HOTEL: "",
              DIRECCION_HOTEL: "",
            };
          } else {
            trLlegada = {
              TIPO_TRASLADO: "L",
              FECHA: idfecha.split("-")[2],
              CIUDAD: "",
              AEROPUERTO: "",
              OPCION_ELEGIDA: "HH",
              CIUDAD_PROC_DEST: objTrasladoHotel[0].CIUDAD,
              NOMBRE_HOTEL: objTrasladoHotel[0].HOTEL,
              DIRECCION_HOTEL: objTrasladoHotel[0].DIRECCION,
              NUM_VUELO: "",
              COMPANYA_AEREA: "",
              HORA_RECOGIDA: objTrasladoHotel[0].HORA,
            };
          }
        }
      }

      if (SalidaFDCheck || vueloIberolunaCheck) {
        trSalida = {
          TIPO_TRASLADO: "S",
          FECHA: idfecha.split("-")[2],
          CIUDAD: "",
          AEROPUERTO: "", // objTrasladoAeropuerto[0].AEROPUERTO,
          OPCION_ELEGIDA: "FD",
          CIUDAD_PROC_DEST: "",
          NUM_VUELO: "",
          COMPANYA_AEREA: "",
          HORA_RECOGIDA: "",
          NOMBRE_HOTEL: "",
          DIRECCION_HOTEL: "",
        };
      } else {
        if (trasladoSalida) {
          if (trasladoTipoSalida === "aeropuerto") {
            //REVISA LA HORA DEL TRASLADO DENTRO DEL MARGEN DE EUROPAMUNDO
            const horaIndicada = parseInt(
              objTrasladoAeropuerto[1].HORA.replace(":", "")
            );
            const horaMinima = parseInt(horaMinimaSalida);
            if (
              horaMinima != 0 &&
              nochesAdicionalesFin == 0 &&
              horaIndicada < horaMinima
            ) {
              ShowMensaje(
                "La hora del vuelo de salida debe ser despues de las " +
                  horaMinimaSalida +
                  " ."
              );
              setLoadingPrereserva(false);
              return false;
            }
            trSalida = {
              TIPO_TRASLADO: "S",
              FECHA: idfecha.split("-")[2],
              CIUDAD: "",
              AEROPUERTO: selPrecioTrasladoSalida.split("-")[0], // objTrasladoAeropuerto[0].AEROPUERTO,
              OPCION_ELEGIDA: "HA",
              CIUDAD_PROC_DEST: objTrasladoAeropuerto[1].CIUDAD,
              NUM_VUELO: objTrasladoAeropuerto[1].VUELO,
              COMPANYA_AEREA: objTrasladoAeropuerto[1].LINEA,
              HORA_RECOGIDA: objTrasladoAeropuerto[1].HORA,
              Fecha_Salida: objTrasladoAeropuerto[1].FECHASALIDA,
              Fecha_Llegada: objTrasladoAeropuerto[1].FECHALLEGADA,
              NOMBRE_HOTEL: "",
              DIRECCION_HOTEL: "",
            };
          } else {
            trSalida = {
              TIPO_TRASLADO: "S",
              FECHA: idfecha.split("-")[2],
              CIUDAD: "",
              AEROPUERTO: "",
              OPCION_ELEGIDA: "HH",
              CIUDAD_PROC_DEST: objTrasladoHotel[1].CIUDAD,
              NOMBRE_HOTEL: objTrasladoHotel[1].HOTEL,
              DIRECCION_HOTEL: objTrasladoHotel[1].DIRECCION,
              NUM_VUELO: "",
              COMPANYA_AEREA: "",
              HORA_RECOGIDA: objTrasladoHotel[1].HORA,
            };
          }
        }
      }

      let obsLlegada =
        selTrasladoLlegada === "Si" ? "Requiere traslado de llegada. " : "";
      let obsSalida =
        selTrasladoSalida === "Si" ? "Requiere traslado de salida. " : "";

      var book = {
        NOCHESADICIONALESINICIO: nochesAdicionalesInicio,
        NOCHESADICIONALESFIN: nochesAdicionalesFin,
        FECHA: idfecha,
        PASAJEROS: paxes,
        OBSERVACIONES: obsLlegada + obsSalida + observaciones,
        TRASLADOLLEGADAOBJ: trLlegada,
        TRASLADOSALIDAOBJ: trSalida,
        TRASLADOLLEGADA: trasladoLlegada,
        TRASLADOSALIDA: trasladoSalida,
        NOMBRECONTACTO: nombreContacto,
        APELLIDOCONTACTO: apellidoContacto,
        CELULARCONTACTO: celularContacto,
        EMAILCONTACTO: emailContacto,
        NOMBREVIAJE: props.circuito.NOMBREVIAJE,
        PRECIOTOTALLOCAL: Math.ceil(parseFloat(coti.PrecioGastosLocal) * 100),
        PRECIOTOTAL: Math.ceil(parseFloat(coti.PrecioGastos) * 100),
        logid: props.logid,
        IdCotizacion: coti.IdLogCotizacion,
        tokenwb: "oTn84s(3Ms95=",
      };

      // console.log("bookPRE");
      // console.log(book);
      // console.log(arrDoble);
      // console.log(arrTriple);
      // console.log(arrHabSencilla);
      // console.log(arrHabCompartida);
      // console.log(arrHabDoble);
      // console.log(arrHabTriple);
      var urlBooking =
        "https://iberolunatravel.online/wse/api/PreReservaCircuito";

      axios
        .post(urlBooking, book, {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        })
        //.post('http://localhost:60691/api/Reserva', book)
        .then((response) => {
          setLoadingPrereserva(false);
          setExpediente(response.data.EXPEDIENTE);
          setCodigoReserva(response.data.CODIGO_RESERVA);
          setFechaPrereserva(response.data.FECHA_PRERESERVA);
          setHoraPreReserva(response.data.HORA_PRERESERVA);
          setPermiteAbono(response.data.PERMITEABONO);
          setFechaLimiteConfirma(response.data.FECHA_LIMITE_CONFIRMA);
          if (response.data.TEXTO_ERROR === "") {
            if (response.data.AEREO_ERROR === "") {
              setFlagReservaOK(true);
              handleClickOpenDialog();
            } else {
              ShowMensaje(response.data.AEREO_ERROR);
            }
          } else {
            setFlagReservaOK(false);
            handleClickOpenDialog();
          }
        })
        .catch((error) => {
          setFlagReservaOK(false);
          handleClickOpenDialog();
          setLoadingPrereserva(false);
        }); //
    }
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const steps = [
    "Select master blaster campaign settings",
    "Create an ad group",
    "Create an ad",
    "Select master blaster campaign settings",
    "Create an ad group",
    "Create an ad",
  ];

  return (
    <React.Fragment>
      {/*  <Box sx={{ width: '100%' }}>
      <Stepper activeStep={2} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box> */}
      {cardNumber >= 1 ? (
        <Card sx={{ maxWidth: 1100 }}>
          <div id="AC-inicio"></div>
          <CardHeader
            sx={{ bgcolor: Colors.dove_gray }}
            avatar={
              <Avatar sx={{ bgcolor: Colors.orange_ibero }} aria-label="recipe">
                1
              </Avatar>
            }
            titleTypographyProps={{ variant: "h5", color: Colors.blue_ibero }}
            title="Seleccione la fecha de su viaje"
          ></CardHeader>
          <CardContent>
            {props.meses.length === 0 ? (
              <Stack spacing={2} justifyContent="space-between">
                <Box>
                  <Typography
                    variant="h6"
                    color="text.secondary"
                    sx={{ textAlign: "center" }}
                  >
                    No existen fechas de salidas disponibles.
                  </Typography>
                </Box>
              </Stack>
            ) : (
              <Stack spacing={2} justifyContent="space-between">
                <Box>
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{ textAlign: "center" }}
                  >
                    Seleccione la fecha de salida deseada de las disponibles,
                    recuerde que esta disponibilidad de plazas es orientativa y
                    está sujeta a la fecha de confirmación de la reserva.
                  </Typography>
                </Box>

                <Box sx={{ width: "100%", bgcolor: Colors.light_gray }}>
                  <Box sx={{ borderBottom: 1, borderColor: "gray" }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      variant="scrollable"
                      scrollButtons="auto"
                      textColor="primary"
                      aria-label="scrollable auto tabs example"
                    >
                      {props.meses.map((item, i) => (
                        <Tab key={i} label={item} {...a11yProps({ i })} />
                      ))}
                    </Tabs>
                  </Box>
                  <TabPanel key={"TP1"} value={value} index={0}>
                    {GridFechas(props.fechas, 0)}
                  </TabPanel>
                  <TabPanel key={"TP2"} value={value} index={1}>
                    {GridFechas(props.fechas1, 1)}
                  </TabPanel>
                  <TabPanel key={"TP3"} value={value} index={2}>
                    {GridFechas(props.fechas2, 2)}
                  </TabPanel>
                  <TabPanel key={"TP4"} value={value} index={3}>
                    {GridFechas(props.fechas3, 3)}
                  </TabPanel>
                  <TabPanel key={"TP5"} value={value} index={4}>
                    {GridFechas(props.fechas4, 4)}
                  </TabPanel>
                  <TabPanel key={"TP6"} value={value} index={5}>
                    {GridFechas(props.fechas5, 5)}
                  </TabPanel>
                  <TabPanel key={"TP7"} value={value} index={6}>
                    {GridFechas(props.fechas6, 6)}
                  </TabPanel>
                  <TabPanel key={"TP8"} value={value} index={7}>
                    {GridFechas(props.fechas7, 7)}
                  </TabPanel>
                  <TabPanel key={"TP9"} value={value} index={8}>
                    {GridFechas(props.fechas8, 8)}
                  </TabPanel>
                  <TabPanel key={"TP10"} value={value} index={9}>
                    {GridFechas(props.fechas9, 9)}
                  </TabPanel>
                  <TabPanel key={"TP11"} value={value} index={10}>
                    {GridFechas(props.fechas10, 10)}
                  </TabPanel>
                  <TabPanel key={"TP12"} value={value} index={11}>
                    {GridFechas(props.fechas11, 11)}
                  </TabPanel>
                  <TabPanel key={"TP13"} value={value} index={12}>
                    {GridFechas(props.fechas12, 12)}
                  </TabPanel>
                </Box>
              </Stack>
            )}
            {flagRelacionado ? (
  <Box>
    <Stack
      direction="column"
      spacing={0}
      width="100%"
      justifyContent="center"
      alignItems="center"
      marginBottom="35px"
    >
      <br/>
<br/>
      <Fab
        variant="extended"
        aria-label="delete"
        size="large"
        sx={{
          bgcolor: Colors.orange_ibero,
          color: Colors.white,
          textTransform: "none",
          fontSize: "1.5rem",
          width: props.matches ? "270px" : "400px",
          "&:hover": {
            backgroundColor: Colors.blue_ibero,
          },
        }}
        onClick={OpenViajeRelacionado}
      >
        <SearchOutlinedIcon
          fontSize="30px"
          sx={{ color: Colors.white, marginRight: "5px" }}
        /> 
        {textoViajerelacionado}
      </Fab>
    </Stack>
  </Box>
) : (
  <></>
)}
          </CardContent>
        </Card>
      ) : (
        <></>
      )}
      <br />
      <br />
      <div id="AC-habitacion"></div>
      {cardNumber >= 2 ? (
        <Card sx={{ maxWidth: 1100 }}>
          <CardHeader
            sx={{ bgcolor: Colors.dove_gray }}
            avatar={
              <Avatar sx={{ bgcolor: Colors.orange_ibero }} aria-label="recipe">
                2
              </Avatar>
            }
            titleTypographyProps={{ variant: "h5", color: Colors.blue_ibero }}
            title="Seleccione la habitación"
          ></CardHeader>
          <CardContent>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ textAlign: "center" }}
            >
              Indíquenos el número de habitaciones que desea de cada tipo
              disponible (Precio por persona).
            </Typography>
            <br></br>
            <Box sx={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={{ xs: 2, md: 2 }}
                columns={{ xs: 6, sm: 12, md: 12 }}
              >
                <Grid item xs={3} sm={3} md={3}>
                  <Stack
                    direction="column"
                    spacing={0}
                    width={150}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Stack
                      direction="row"
                      spacing={0}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography sx={{ fontWeight: "bold" }}>
                        Sencilla
                      </Typography>
                      <Link
                        component="button"
                        variant="BUTTON"
                        color="inherit"
                        onClick={() =>
                          ShowMensaje(
                            "Sencilla: Una persona en una habitación."
                          )
                        }
                      >
                        <InfoOutlinedIcon
                          sx={{ color: Colors.orange_ibero }}
                        ></InfoOutlinedIcon>
                      </Link>
                    </Stack>
                    <Typography>
                      {precios[0].SENCILLA === 0
                        ? "No aplica"
                        : Number(precios[0].SENCILLA).toLocaleString(
                            userLocale,
                            options
                          ) + " USD"}
                    </Typography>
                    <FormControl size="small" fullWidth>
                      <InputLabel id="select-sencilla-label"></InputLabel>
                      <Select
                        disabled={checkDisableSelect("select-sencilla")}
                        labelId="select-sencilla-label"
                        id="select-sencilla"
                        value={cantSencilla}
                        label=""
                        name="select-sencilla"
                        onChange={handleChangeSelect}
                      >
                        <MenuItem value={0}>0 habs.</MenuItem>
                        <MenuItem value={1}>1 hab.</MenuItem>
                        <MenuItem value={2}>2 habs.</MenuItem>
                        <MenuItem value={3}>3 habs.</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={3} sm={3} md={3}>
                  <Stack
                    direction="column"
                    spacing={0}
                    width={150}
                    justifyContent="center"
                    alignItems="center"
                  >
                    {/*  <Typography sx={{ fontWeight: "bold" }}>
                      Compartida
                    </Typography> */}
                    <Stack
                      direction="row"
                      spacing={0}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography sx={{ fontWeight: "bold" }}>
                        Compartida{" "}
                      </Typography>
                      <Link
                        component="button"
                        variant="BUTTON"
                        color="inherit"
                        onClick={() =>
                          ShowMensaje(
                            "Doble a compartir: Opción para un persona viajando sola, Europamundo le ubica  un compañero del mismo género , aunque no edad, que comparta su habitación con dos camas individuales"
                          )
                        }
                      >
                        <InfoOutlinedIcon
                          sx={{ color: Colors.orange_ibero }}
                        ></InfoOutlinedIcon>
                      </Link>
                    </Stack>
                    <Typography>
                      {precios[0].COMPARTIDA === 0
                        ? "No aplica"
                        : Number(precios[0].COMPARTIDA).toLocaleString(
                            userLocale,
                            options
                          ) + " USD"}
                    </Typography>
                    <FormControl size="small" fullWidth>
                      <InputLabel id="select-compartida-label"></InputLabel>
                      <Select
                        disabled={checkDisableSelect("select-compartida")}
                        labelId="select-compartida-label"
                        id="select-compartida"
                        name="select-compartida"
                        value={cantCompartida}
                        label=""
                        onChange={handleChangeSelect}
                      >
                        <MenuItem value={0}>0 habs.</MenuItem>
                        <MenuItem value={1}>1 hab.</MenuItem>
                        <MenuItem value={2}>2 habs.</MenuItem>
                        <MenuItem value={3}>3 habs.</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={3} sm={3} md={3}>
                  <Stack
                    direction="column"
                    spacing={0}
                    width={150}
                    justifyContent="center"
                    alignItems="center"
                  >
                    {/* <Typography sx={{ fontWeight: "bold" }}>Doble</Typography> */}
                    <Stack
                      direction="row"
                      spacing={0}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography sx={{ fontWeight: "bold" }}>
                        Doble{" "}
                      </Typography>
                      <Link
                        component="button"
                        variant="BUTTON"
                        color="inherit"
                        onClick={() =>
                          ShowMensaje(
                            "Doble: dos personas en una habitación, ya sea en dos camas individuales o una doble."
                          )
                        }
                      >
                        <InfoOutlinedIcon
                          sx={{ color: Colors.orange_ibero }}
                        ></InfoOutlinedIcon>
                      </Link>
                    </Stack>
                    <Typography>
                      {precios[0].DOBLE === 0
                        ? "No aplica"
                        : Number(precios[0].DOBLE).toLocaleString(
                            userLocale,
                            options
                          ) + " USD"}
                    </Typography>
                    <FormControl size="small" fullWidth>
                      <InputLabel id="select-doble-label"></InputLabel>
                      <Select
                        disabled={idfecha === ""}
                        labelId="select-doble-label"
                        id="select-doble"
                        name="select-doble"
                        value={cantDoble}
                        label=""
                        onChange={handleChangeSelect}
                      >
                        <MenuItem value={0}>0 habs.</MenuItem>
                        <MenuItem value={1}>1 hab.</MenuItem>
                        <MenuItem value={2}>2 habs.</MenuItem>
                        <MenuItem value={3}>3 habs.</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={3} sm={3} md={3}>
                  <Stack
                    direction="column"
                    spacing={0}
                    width={150}
                    justifyContent="center"
                    alignItems="center"
                  >
                    {/* <Typography sx={{ fontWeight: "bold" }}>Triple</Typography> */}
                    <Stack
                      direction="row"
                      spacing={0}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography sx={{ fontWeight: "bold" }}>
                        Triple{" "}
                      </Typography>
                      <Link
                        component="button"
                        variant="BUTTON"
                        color="inherit"
                        onClick={() =>
                          ShowMensaje(
                            "Triple: tres personas en una habitación, son en realidad una habitación doble (de una o dos camas) a la que se agrega otra cama que en algún caso podrá ser plegable o sofá-cama."
                          )
                        }
                      >
                        <InfoOutlinedIcon
                          sx={{ color: Colors.orange_ibero }}
                        ></InfoOutlinedIcon>
                      </Link>
                    </Stack>
                    <Typography>
                      {precios[0].TRIPLE === 0
                        ? "No aplica"
                        : Number(precios[0].TRIPLE).toLocaleString(
                            userLocale,
                            options
                          ) + " USD"}
                    </Typography>
                    <FormControl size="small" fullWidth>
                      <InputLabel id="select-triple-label"></InputLabel>
                      <Select
                        disabled={checkDisableSelect("select-triple")}
                        labelId="select-triple-label"
                        id="select-triple"
                        name="select-triple"
                        value={cantTriple}
                        label=""
                        onChange={handleChangeSelect}
                      >
                        <MenuItem value={0}>0 habs.</MenuItem>
                        <MenuItem value={1}>1 hab.</MenuItem>
                        <MenuItem value={2}>2 habs.</MenuItem>
                        <MenuItem value={3}>3 habs.</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
            {/* <Stack direction="row" spacing={3} justifyContent="space-evenly">
            <Stack direction="column" spacing={0} width={100}>
              <Typography sx={{ fontWeight: "bolder" }}>Habitación</Typography>
              <Typography sx={{ fontWeight: "bolder" }}>Precio</Typography>
            </Stack>
            <Stack
              direction="column"
              spacing={0}
              width={150}
              justifyContent="center"
              alignItems="center"
            >
              <Typography sx={{ fontWeight: "bold" }}>Sencilla</Typography>
              <Typography>
                {precios[0].SENCILLA === 0
                  ? "No aplica"
                  : Number(precios[0].SENCILLA).toLocaleString(userLocale, {
                      options,
                    }) + " USD"}
              </Typography>
              <FormControl size="small" fullWidth>
                <InputLabel id="select-sencilla-label"></InputLabel>
                <Select
                  disabled={checkDisableSelect("select-sencilla")}
                  labelId="select-sencilla-label"
                  id="select-sencilla"
                  value={cantSencilla}
                  label=""
                  name="select-sencilla"
                  onChange={handleChangeSelect}
                >
                  <MenuItem value={0}>0 habs.</MenuItem>
                  <MenuItem value={1}>1 hab.</MenuItem>
                  <MenuItem value={2}>2 habs.</MenuItem>
                  <MenuItem value={3}>3 habs.</MenuItem>
                </Select>
              </FormControl>
            </Stack>
            <Stack
              direction="column"
              spacing={0}
              width={150}
              justifyContent="center"
              alignItems="center"
            >
              <Typography sx={{ fontWeight: "bold" }}>Compartida</Typography>
              <Typography>
                {precios[0].COMPARTIDA === 0
                  ? "No aplica"
                  : Number(precios[0].COMPARTIDA).toLocaleString(userLocale, {
                      options,
                    }) + " USD"}
              </Typography>
              <FormControl size="small" fullWidth>
                <InputLabel id="select-compartida-label"></InputLabel>
                <Select
                  disabled={checkDisableSelect("select-compartida")}
                  labelId="select-compartida-label"
                  id="select-compartida"
                  name="select-compartida"
                  value={cantCompartida}
                  label=""
                  onChange={handleChangeSelect}
                >
                  <MenuItem value={0}>0 habs.</MenuItem>
                  <MenuItem value={1}>1 hab.</MenuItem>
                  <MenuItem value={2}>2 habs.</MenuItem>
                  <MenuItem value={3}>3 habs.</MenuItem>
                </Select>
              </FormControl>
            </Stack>
            <Stack
              direction="column"
              spacing={0}
              width={150}
              justifyContent="center"
              alignItems="center"
            >
              <Typography sx={{ fontWeight: "bold" }}>Doble</Typography>
              <Typography>
                {precios[0].DOBLE === 0
                  ? "No aplica"
                  : Number(precios[0].DOBLE).toLocaleString(userLocale, options) +
                    " USD"}
              </Typography>
              <FormControl size="small" fullWidth>
                <InputLabel id="select-doble-label"></InputLabel>
                <Select
                  disabled={idfecha === ""}
                  labelId="select-doble-label"
                  id="select-doble"
                  name="select-doble"
                  value={cantDoble}
                  label=""
                  onChange={handleChangeSelect}
                >
                  <MenuItem value={0}>0 habs.</MenuItem>
                  <MenuItem value={1}>1 hab.</MenuItem>
                  <MenuItem value={2}>2 habs.</MenuItem>
                  <MenuItem value={3}>3 habs.</MenuItem>
                </Select>
              </FormControl>
            </Stack>
            <Stack
              direction="column"
              spacing={0}
              width={150}
              justifyContent="center"
              alignItems="center"
            >
              <Typography sx={{ fontWeight: "bold" }}>Triple</Typography>
              <Typography>
                {precios[0].TRIPLE === 0
                  ? "No aplica"
                  : Number(precios[0].TRIPLE).toLocaleString(userLocale, {
                      options,
                    }) + " USD"}
              </Typography>
              <FormControl size="small" fullWidth>
                <InputLabel id="select-triple-label"></InputLabel>
                <Select
                  disabled={checkDisableSelect("select-triple")}
                  labelId="select-triple-label"
                  id="select-triple"
                  name="select-triple"
                  value={cantTriple}
                  label=""
                  onChange={handleChangeSelect}
                >
                  <MenuItem value={0}>0 habs.</MenuItem>
                  <MenuItem value={1}>1 hab.</MenuItem>
                  <MenuItem value={2}>2 habs.</MenuItem>
                  <MenuItem value={3}>3 habs.</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Stack> */}
          </CardContent>
          {/*     <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
      </CardActions> */}
        </Card>
      ) : (
        <></>
      )}

      <br />
      <br />
      {cardNumber >= 3 ? (
        <Card sx={{ maxWidth: 1100 }}>
          <CardHeader
            sx={{ bgcolor: Colors.dove_gray }}
            avatar={
              <Avatar sx={{ bgcolor: Colors.orange_ibero }} aria-label="recipe">
                3
              </Avatar>
            }
            titleTypographyProps={{ variant: "h5", color: Colors.blue_ibero }}
            title="Indique las edades de los pasajeros"
          ></CardHeader>
          <CardContent>
            <Typography
              variant="body1"
              color={Colors.orange_ibero}
              sx={{ textAlign: "center", fontWeight: "bold" }}
            >
              La edad que comunique debe ser la que tenga al momento del inicio
              del circuito.
            </Typography>
            <br />
            <Box sx={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={{ xs: 2, md: 2 }}
                columns={{ xs: 6, sm: 12, md: 12 }}
              >
                <Grid item xs={3} sm={3} md={3}>
                  <Stack
                    direction="column"
                    spacing={0}
                    width={150}
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      Sencilla
                    </Typography>
                    {SelectSencilla(cantSencilla)}
                  </Stack>
                </Grid>
                <Grid item xs={3} sm={3} md={3}>
                  <Stack
                    direction="column"
                    spacing={0}
                    width={150}
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      Compartida
                    </Typography>
                    {SelectCompartida(cantCompartida)}
                  </Stack>
                </Grid>
                <Grid item xs={3} sm={3} md={3}>
                  <Stack
                    direction="column"
                    spacing={0}
                    width={150}
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Typography sx={{ fontWeight: "bold" }}>Doble</Typography>
                    {SelectDoble(cantDoble)}
                  </Stack>
                </Grid>
                <Grid item xs={3} sm={3} md={3}>
                  <Stack
                    direction="column"
                    spacing={0}
                    width={150}
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Typography sx={{ fontWeight: "bold" }}>Triple</Typography>
                    {SelectTriple(cantTriple)}
                  </Stack>
                </Grid>
              </Grid>
            </Box>
            {/* <Stack direction="row" spacing={3} justifyContent="space-evenly">
            <Stack
              direction="column"
              spacing={0}
              width={100}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Typography sx={{ fontWeight: "bolder" }}></Typography>
            </Stack>
            <Stack
              direction="column"
              spacing={0}
              width={150}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Typography sx={{ fontWeight: "bold" }}>Sencilla</Typography>
              {SelectSencilla(cantSencilla)}
            </Stack>
            <Stack
              direction="column"
              spacing={0}
              width={150}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Typography sx={{ fontWeight: "bold" }}>Compartida</Typography>
              {SelectCompartida(cantCompartida)}
            </Stack>
            <Stack
              direction="column"
              spacing={0}
              width={150}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Typography sx={{ fontWeight: "bold" }}>Doble</Typography>
              {SelectDoble(cantDoble)}
            </Stack>
            <Stack
              direction="column"
              spacing={0}
              width={150}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Typography sx={{ fontWeight: "bold" }}>Triple</Typography>
              {SelectTriple(cantTriple)}
            </Stack>
          </Stack> */}
          </CardContent>
          {/*     <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
      </CardActions> */}
        </Card>
      ) : (
        <></>
      )}

      <br />
      <br />
      <div id="AC-Complete"></div>
      {cardNumber >= 4 ? (
        <Card sx={{ maxWidth: 1100 }}>
          <CardHeader
            sx={{ bgcolor: Colors.dove_gray }}
            avatar={
              <Avatar sx={{ bgcolor: Colors.orange_ibero }} aria-label="recipe">
                4
              </Avatar>
            }
            titleTypographyProps={{ variant: "h5", color: Colors.blue_ibero }}
            title="Complete su viaje"
          ></CardHeader>
          <CardContent>
            <Grid container spacing={2} display="flex" alignItems="center">
              <Grid item xs={6} sx={{ textAlign: "right" }}>
                <Typography variant="body1" color="text.secondary">
                  ¿Desea añadir más noches de hotel al inicio de su viaje?
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormControl size="small">
                  <Select
                    disabled={!indNochesAntes || nochesAntes === 0}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={nochesAdicionalesInicio}
                    label=""
                    onChange={handleChangeNochesAdicionalesInicio}
                    sx={
                      !props.matches
                        ? { minWidth: "400px" }
                        : { minWidth: "170px" }
                    }
                  >
                    <MenuItem value={0}>No</MenuItem>
                    {generateNochesAdicionales(nochesAntes)}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "right" }}>
                <Typography variant="body1" color="text.secondary">
                  ¿Desea añadir más noches de hotel al final de su viaje?
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormControl size="small">
                  <Select
                    disabled={!indNochesDespues || nochesDespues === 0}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={nochesAdicionalesFin}
                    label=""
                    onChange={handleChangeNochesAdicionalesFin}
                    sx={
                      !props.matches
                        ? { minWidth: "400px" }
                        : { minWidth: "170px" }
                    }
                  >
                    <MenuItem value={0}>No</MenuItem>
                    {generateNochesAdicionales(nochesDespues)}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "right" }}>
                <Typography variant="body1" color="text.secondary">
                  {props.circuito.TRASLADOLLEGADA
                    ? "Traslado de llegada incluido (" +
                      props.circuito.CIUDADINICIO +
                      ")"
                    : "¿Desea traslado de llegada  (" +
                      props.circuito.CIUDADINICIO +
                      ") ?"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {/* {!props.circuito.TRASLADOLLEGADA ? ( */}
                <FormControl size="small">
                  <Select
                    // disabled={props.circuito.TRASLADOLLEGADA}
                    labelId="demo-simple-select-label-ll"
                    id="demo-simple-select-ll"
                    value={selTrasladoLlegada}
                    label=""
                    onChange={handleChangeTrasladoLlegada}
                    sx={
                      !props.matches
                        ? { minWidth: "400px" }
                        : { minWidth: "170px" }
                    }
                  >
                    <MenuItem value={"No"}>No</MenuItem>
                    {/* //-------- */}
                    {props.circuito.INTEGRACIONPERMITETRASLADO ? (
                      <>
                        {precioTrasladoLlegada
                          .filter((it) => it.TIPO !== "HH" && it.TIPO !== "AH")
                          .map((item) =>
                            item.NOMBRE_LUGAR === "" ? (
                              <MenuItem
                                key={item.LUGAR}
                                value={item.LUGAR + item.TIPO}
                              >
                                {props.circuito.CIUDADINICIO}. &nbsp;
                                <b>
                                  {item.MONEDA}: {item.PRECIO_TOTAL}
                                </b>
                              </MenuItem>
                            ) : (
                              <MenuItem
                                key={item.LUGAR}
                                value={item.LUGAR + item.TIPO}
                              >
                                {props.circuito.CIUDADINICIO} -{" "}
                                {item.NOMBRE_LUGAR} -{item.NOMBRE_LUGAR}. &nbsp;
                                <b>
                                  {item.MONEDA}: {item.PRECIO_TOTAL}
                                </b>
                              </MenuItem>
                            )
                          )}

                        {precioTrasladoLlegada
                          .filter((it) => it.TIPO === "HH")
                          .map((item, ii) => {
                            let content = [];

                            if (ii === 0) {
                              content.push(
                                <ListSubheader
                                  sx={{
                                    background: "#ffceba",
                                    fontWeight: "bold",
                                    fontSize: "16px",
                                  }}
                                >
                                  HOTEL - HOTEL
                                </ListSubheader>
                              );
                            }
                            content.push(
                              item.NOMBRE_LUGAR === "" ? (
                                <MenuItem
                                  key={item.LUGAR}
                                  value={item.LUGAR + "-" + item.TIPO}
                                >
                                  {props.circuito.CIUDADINICIO}. &nbsp;
                                  <b>
                                    {item.MONEDA}: {item.PRECIO_TOTAL}
                                  </b>
                                </MenuItem>
                              ) : (
                                <MenuItem
                                  key={item.LUGAR}
                                  value={item.LUGAR + "-" + item.TIPO}
                                >
                                  {props.circuito.CIUDADINICIO} -{" "}
                                  {item.NOMBRE_LUGAR} -{item.NOMBRE_LUGAR}.
                                  &nbsp;
                                  <b>
                                    {item.MONEDA}: {item.PRECIO_TOTAL}
                                  </b>
                                </MenuItem>
                              )
                            );

                            return content;
                          })}

                        {precioTrasladoLlegada
                          .filter((it) => it.TIPO === "AH")
                          .map((item, ii) => {
                            let content = [];

                            if (ii === 0) {
                              content.push(
                                <ListSubheader
                                  sx={{
                                    background: "#ffceba",
                                    fontWeight: "bold",
                                    fontSize: "16px",
                                  }}
                                >
                                  AEROPUERTO - HOTEL
                                </ListSubheader>
                              );
                            }
                            content.push(
                              item.NOMBRE_LUGAR === "" ? (
                                <MenuItem
                                  key={item.LUGAR}
                                  value={item.LUGAR + "-" + item.TIPO}
                                >
                                  {props.circuito.CIUDADINICIO}. &nbsp;
                                  <b>
                                    {item.MONEDA}: {item.PRECIO_TOTAL}
                                  </b>
                                </MenuItem>
                              ) : (
                                <MenuItem
                                  key={item.LUGAR}
                                  value={item.LUGAR + "-" + item.TIPO}
                                >
                                  {props.circuito.CIUDADINICIO} -{" "}
                                  {item.NOMBRE_LUGAR} -{item.NOMBRE_LUGAR}.
                                  &nbsp;
                                  <b>
                                    {item.MONEDA}: {item.PRECIO_TOTAL}
                                  </b>
                                </MenuItem>
                              )
                            );

                            return content;
                          })}
                        {/* //--------------- */}
                      </>
                    ) : (
                      <MenuItem value={"Si"}>Si</MenuItem>
                    )}
                  </Select>
                </FormControl>
                {/*  ) : (
                <></>
              )} */}
              </Grid>

              {/* EN COMENTARIO POR SOLICITUD DE IBEROLUNA PARA NO MANEJAR TRASLADO DE SALIDA */}
              {/*      <Grid item xs={6} sx={{ textAlign: "right" }}>
                <Typography variant="body1" color="text.secondary">
                  {props.circuito.TRASLADOSALIDA
                    ? "Traslado de salida incluido (" +
                      props.circuito.CIUDADFIN +
                      ")"
                    : "¿Desea traslado de salida? (" +
                      props.circuito.CIUDADFIN +
                      ")"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {!props.circuito.TRASLADOSALIDA ? (
                  <FormControl size="small">
                    <Select
                      disabled={props.circuito.TRASLADOSALIDA}
                      labelId="demo-simple-select-label-sa"
                      id="demo-simple-select-sa"
                      value={selTrasladoSalida}
                      label=""
                      onChange={handleChangeTrasladoSalida}
                      sx={
                        !props.matches
                          ? { minWidth: "400px" }
                          : { minWidth: "170px" }
                      }
                    >
                      <MenuItem key={"No"} value={"No"}>
                        No
                      </MenuItem>
                      {props.circuito.INTEGRACIONPERMITETRASLADO ? (
                        <>
                          {precioTrasladoSalida
                            .filter(
                              (it) => it.TIPO !== "HH" && it.TIPO !== "AH"
                            )
                            .map((item) =>
                              item.NOMBRE_LUGAR === "" ? (
                                <MenuItem
                                  key={item.LUGAR}
                                  value={item.LUGAR + "-" + item.TIPO}
                                >
                                  {props.circuito.CIUDADFIN}. &nbsp;
                                  <b>
                                    {item.MONEDA}: {item.PRECIO_TOTAL}
                                  </b>
                                </MenuItem>
                              ) : (
                                <MenuItem
                                  key={item.LUGAR}
                                  value={item.LUGAR + "-" + item.TIPO}
                                >
                                  {props.circuito.CIUDADFIN} -{" "}
                                  {item.NOMBRE_LUGAR} -{item.NOMBRE_LUGAR}.
                                  &nbsp;
                                  <b>
                                    {item.MONEDA}: {item.PRECIO_TOTAL}
                                  </b>
                                </MenuItem>
                              )
                            )}

                          {precioTrasladoSalida
                            .filter((it) => it.TIPO === "HH")
                            .map((item, ii) => {
                              let content = [];

                              if (ii === 0) {
                                content.push(
                                  <ListSubheader
                                    sx={{
                                      background: "#ffceba",
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                    }}
                                  >
                                    HOTEL - HOTEL
                                  </ListSubheader>
                                );
                              }
                              content.push(
                                item.NOMBRE_LUGAR === "" ? (
                                  <MenuItem
                                    key={item.LUGAR}
                                    value={item.LUGAR + "-" + item.TIPO}
                                  >
                                    {props.circuito.CIUDADFIN}. &nbsp;
                                    <b>
                                      {item.MONEDA}: {item.PRECIO_TOTAL}
                                    </b>
                                  </MenuItem>
                                ) : (
                                  <MenuItem
                                    key={item.LUGAR}
                                    value={item.LUGAR + "-" + item.TIPO}
                                  >
                                    {props.circuito.CIUDADFIN} -{" "}
                                    {item.NOMBRE_LUGAR} -{item.NOMBRE_LUGAR}.
                                    &nbsp;
                                    <b>
                                      {item.MONEDA}: {item.PRECIO_TOTAL}
                                    </b>
                                  </MenuItem>
                                )
                              );

                              return content;
                            })}

                          {precioTrasladoSalida
                            .filter((it) => it.TIPO === "AH")
                            .map((item, ii) => {
                              let content = [];

                              if (ii === 0) {
                                content.push(
                                  <ListSubheader
                                    sx={{
                                      background: "#ffceba",
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                    }}
                                  >
                                    HOTEL - AEROPUERTO
                                  </ListSubheader>
                                );
                              }
                              content.push(
                                item.NOMBRE_LUGAR === "" ? (
                                  <MenuItem
                                    key={item.LUGAR}
                                    value={item.LUGAR + "-" + item.TIPO}
                                  >
                                    {props.circuito.CIUDADFIN}. &nbsp;
                                    <b>
                                      {item.MONEDA}: {item.PRECIO_TOTAL}
                                    </b>
                                  </MenuItem>
                                ) : (
                                  <MenuItem
                                    key={item.LUGAR}
                                    value={item.LUGAR + "-" + item.TIPO}
                                  >
                                    {props.circuito.CIUDADFIN} -{" "}
                                    {item.NOMBRE_LUGAR} -{item.NOMBRE_LUGAR}.
                                    &nbsp;
                                    <b>
                                      {item.MONEDA}: {item.PRECIO_TOTAL}
                                    </b>
                                  </MenuItem>
                                )
                              );

                              return content;
                            })}
                        </>
                      ) : (
                        <MenuItem value={"Si"}>Si</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                ) : (
                  <></>
                )}
              </Grid> */}

              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <br></br>
                <br></br>
                <LoadingButton
                  disabled={flagCotiza}
                  variant="contained"
                  onClick={calculaPrecio}
                  sx={{
                    minWidth: "400px",
                    backgroundColor: Colors.orange_ibero,
                  }}
                  loading={loadingCotiza}
                >
                  Cotice su viaje
                </LoadingButton>
                <div id="Wompi"></div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ) : (
        <></>
      )}

      <br />
      <br />
      <div id="AC-Cotizacion"></div>
      {flagPrecios && cardNumber >= 5 ? (
        <Card sx={{ maxWidth: 1100 }}>
          <CardHeader
            sx={{ bgcolor: Colors.dove_gray }}
            avatar={
              <Avatar sx={{ bgcolor: Colors.orange_ibero }} aria-label="recipe">
                5
              </Avatar>
            }
            titleTypographyProps={{ variant: "h5", color: Colors.blue_ibero }}
            title="Cotización de su viaje"
          ></CardHeader>
          <CardContent>
            <Box sx={{ flexGrow: 1 }}>
              <Card sx={{ minWidth: 275 }} variant="outlined">
                <CardContent>
                  <Grid
                    container
                    spacing={{ xs: 1, md: 1 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                  >
                    {LineaCotizacion(
                      "Precio del Viaje",
                      "",
                      "",
                      "Titulo",
                      true
                    )}
                    {LineaHabitaciones()}
                    {LineaItems(true)}
                    {/*           <br />
                    {LineaCotizacion(
                      "2% gastos bancarios",
                      "",
                      "" +
                        Number(
                          coti.PrecioGastos - coti.PrecioTotal
                        ).toLocaleString(userLocale, options,
                        ),
                      "item",
                      false
                    )} */}
                    <br />
                    {LineaCotizacion(
                      "Presupuesto total de su viaje para todos los pasajeros:",
                      "",
                      "USD " +
                        Number(coti.PrecioGastos).toLocaleString(
                          userLocale,
                          options
                        ),
                      "Total",
                      false
                    )}
                    {/*                    {LineaCotizacion(
         "Incluido 2% de gastos bancarios",
          Number(coti.PrecioSencilla).toLocaleString(userLocale, options),
          "SubItem",
          true
        )} */}
                  </Grid>

                  <Grid item xs={12} sx={{ textAlign: "center" }}>
                    <br></br>
                    <Typography sx={{ color: Colors.black }}>
                      Se debe pagar en Pesos colombianos a la TRM del día que se
                      efectue el pago.
                    </Typography>
                    <br></br>
                    {selTrasladoSalida === "Si" ||
                    selTrasladoLlegada === "Si" ? (
                      /*  <Typography sx={{ color: Colors.danger }}>
                        NOTA: Ha seleccionado que desea traslado. Esta
                        cotización no incluye el precio de traslado, por tal
                        motivo el precio final puede variar según los vuelos.
                      </Typography> */
                      <>
                        <Typography sx={{ color: Colors.danger }}>
                          El precio del traslado de llegada, puede sufrir
                          ajustes dependiendo del aeropuerto y la hora de
                          llegada de los vuelos que usted reserve.
                        </Typography>
                        <Typography sx={{ color: Colors.danger }}>
                          El traslado de salida no esta incluido.
                        </Typography>
                      </>
                    ) : (
                      <></>
                    )}
                    <br></br>
                    <br></br>
                    <Link
                      component="button"
                      variant="body2"
                      onClick={muestraIncluye}
                    >
                      <Typography variant="body1" color="text.secondary">
                        Ver Incluye e Itinerario
                      </Typography>
                    </Link>
                    <br></br>
                    <br></br>
                    <br></br>
                    <Stack direction={!props.matches ? "row" : "column"}>
                      <Grid container spacing={1}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          sx={{ textAlign: "center" }}
                        >
                          <Button
                            disabled={flagCotiza}
                            variant="contained"
                            onClick={enablePresupuesto}
                            sx={{
                              minWidth: !props.matches ? "400px" : "250px",
                              backgroundColor: Colors.blue_ibero,
                              marginBottom: "10px"
                            }}
                          >
                            Guarde su presupuesto
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          sx={{ textAlign: "center" }}
                        >
                          <Button
                            disabled={flagCotiza}
                            variant="contained"
                            onClick={enableReserva}
                            sx={{
                              minWidth: !props.matches ? "400px" : "250px",
                              backgroundColor: Colors.orange_ibero,
                              marginBottom: "10px"
                            }}
                          >
                            Pre-Reserve su viaje
                          </Button>
                        </Grid>
                      </Grid>
                    </Stack>
                    {/* <Grid container spacing={1}>
                      <Grid item  xs={12} sm={12} md={6}  sx={{ textAlign: "center" }}>
                        <Button
                          disabled={flagCotiza}
                          variant="contained"
                          onClick={enablePresupuesto}
                          sx={{
                            minWidth:  !props.matches ? "400px" : "250px",
                            backgroundColor: Colors.blue_ibero,
                          }}
                        >
                          Guarde su presupuesto
                        </Button>
                      </Grid>
                      <Grid item  xs={12} sm={12} md={6}  sx={{ textAlign: "center" }}>
                        <Button
                          disabled={flagCotiza}
                          variant="contained"
                          onClick={enableReserva}
                          sx={{
                            minWidth: !props.matches ? "400px" : "250px",
                            backgroundColor: Colors.orange_ibero,
                          }}
                        >
                          Pre-Reserve su viaje
                        </Button>
                      </Grid>
                    </Grid> */}
                  </Grid>
                </CardContent>
              </Card>
            </Box>

            <br />
            {/*        <Typography variant="body1" color="text.secondary">
            Indíquenos el número de habitaciones que desea de cada tipo
            disponible.
          </Typography> */}
          </CardContent>
          {/*     <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
      </CardActions> */}
        </Card>
      ) : (
        <></>
      )}
      <div id="AC-Prereserva"></div>
      {flagReserva && cardNumber >= 6 ? (
        <React.Fragment>
          <br />
          <br />
          <Card sx={{ maxWidth: 1100 }}>
            <CardHeader
              sx={{ bgcolor: Colors.dove_gray }}
              avatar={
                <Avatar
                  sx={{ bgcolor: Colors.orange_ibero }}
                  aria-label="recipe"
                >
                  6
                </Avatar>
              }
              titleTypographyProps={{ variant: "h5", color: Colors.blue_ibero }}
              title="Datos de la pre-reserva"
            ></CardHeader>
            <CardContent>
              <CardHeader
                sx={{ bgcolor: alpha(Colors.blue_ibero, 0.3) }}
                /*  avatar={
              <Avatar sx={{ bgcolor: Colors.orange_ibero }} aria-label="recipe">
                1
              </Avatar>
            } */
                titleTypographyProps={{
                  variant: "h6",
                  color: Colors.blue_ibero,
                }}
                title="Datos de pasajeros"
              ></CardHeader>
              {/* <Box sx={{ flexGrow: 1 }}></Box> */}
              <Card sx={{ minWidth: 275 }} variant="outlined">
                <CardContent>{generateRoom()}</CardContent>
              </Card>
              <br />
              {/*        <Typography variant="body1" color="text.secondary">
            Indíquenos el número de habitaciones que desea de cada tipo
            disponible.
          </Typography> */}
            </CardContent>

            {/* EN COMENTARIO PARA NO PERMITIR INGRESAR DATOS DE VUELOS....OPERATIVO */}
            {/*    {trasladoLlegada || trasladoSalida ? (
              <CardContent>
                <CardHeader
                  sx={{ bgcolor: alpha(Colors.blue_ibero, 0.3) }}      
                  titleTypographyProps={{
                    variant: "h6",
                    color: Colors.blue_ibero,
                  }}
                  title="Datos de traslado"
                ></CardHeader>
                <CardContent>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ width: "100%" }}
                  >
                    <Stack direction="row" spacing={2}>
                      <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        sx={{ textAlign: "center" }}
                      >
                        ¿Desea que Iberoluna le gestione los vuelos para su
                        circuito?
                      </Typography>
                      <FormControlLabel
                        control={
                          <Switch
                            defaultChecked
                            name="VueloIberoluna"
                            checked={vueloIberolunaCheck}
                            onChange={handleChangeVIChecked}
                          />
                        }
                        label="Si"
                      />
                    </Stack>
                  </Box>

                  {vueloIberolunaCheck ? (
                    <></>
                  ) : (
                    <React.Fragment>
                      <br />
                      <br />
                      {trasladoLlegada ? GenerateTransfer("Llegada") : <></>}
                      {trasladoSalida ? GenerateTransfer("Salida") : <></>}
                    </React.Fragment>
                  )}
                </CardContent>
                <br />
              </CardContent>
            ) : (
              <></>
            )} */}

            <CardContent>
              <CardHeader
                sx={{ bgcolor: alpha(Colors.blue_ibero, 0.3) }}
                titleTypographyProps={{
                  variant: "h6",
                  color: Colors.blue_ibero,
                }}
                title="Datos de titular de la pre-reserva"
              ></CardHeader>

              <Card sx={{ minWidth: 275, with: "100%" }} variant="outlined">
                <CardContent>
                  {DatosContacto("contacto")}
                  <br></br>
                  <Grid
                    container
                    spacing={{ xs: 1, md: 1 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                    sx={{ p: 1, border: "1px dashed grey" }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        sx={{ textAlign: "center" }}
                      >
                        Observaciones
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        id="observaciones"
                        name="observaciones"
                        label=""
                        size="small"
                        sx={{ width: "100%" }}
                        onChange={handleObservaciones}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              {/*  <br />
                <Button variant="outlined" onClick={preReservar}>
                  Pre-reservar
                </Button> */}

              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <br></br>
                <br></br>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ width: "100%" }}
                >
                  <Stack direction="column" spacing={2}>
                    <box sx={{ witdh: "100%" }}>
                      <Typography
                        variant="h6"
                        color="text.secondary"
                        sx={{
                          textAlign: "center",
                          paddingTop: "7px",
                          color: Colors.orange_ibero,
                        }}
                      >
                        GASTOS POR CANCELACION DE RESERVA
                      </Typography>
                    </box>
                    <TableContainer component={Paper}>
                      <Table sx={{ maxWidth: 800 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell align="center">
                              DESDE
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              HASTA DIAS ANTES DEL INICIO DEL VIAJE
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              PORCENTAJE SOBRE EL VALOR DE LA RESERVA
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <StyledTableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <StyledTableCell align="center">
                              A partir del día del deposito
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              40 días
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              25%
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <StyledTableCell align="center">
                              39 días
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              30 días
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              35%
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <StyledTableCell align="center">
                              29 días
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              16 días
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              50%
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <StyledTableCell align="center">
                              15 días
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              o menos
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              100%
                            </StyledTableCell>
                          </StyledTableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      sx={{ textAlign: "center", paddingTop: "7px" }}
                    >
                      ** Recuerde revisar que su pasaporte este vigente 6 meses
                      a partir del inicio del viaje **
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      sx={{ textAlign: "center", paddingTop: "0px" }}
                    >
                      ** Revise si requiere visa para el/los países de destino
                      **
                    </Typography>
                  </Stack>
                </Box>
                <br></br>
                <Divider></Divider>
                <br></br>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ width: "100%" }}
                >
                  <Stack direction="rom" spacing={2}>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      sx={{ textAlign: "center", paddingTop: "7px" }}
                    >
                      Para realizar la pre-reserva favor aceptar las{" "}
                      <a href="/#/condiciones" target={"_blank"}>
                        condiciones generales
                      </a>
                      .
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedCondiciones}
                          onChange={handleChangeAceptacion}
                        />
                      }
                    ></FormControlLabel>
                  </Stack>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ width: "100%" }}
                >
                  <Stack direction="rom" spacing={2}>
                    <>
                      <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        sx={{ textAlign: "center", paddingTop: "7px" }}
                      >
                        Para realizar la pre-reserva favor aceptar las{" "}
                        <a href="/#/tratamientodatos" target={"_blank"}>
                          políticas de tratamiento de datos personales
                        </a>
                        .
                      </Typography>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkedTratamiento}
                            onChange={handleChangeAceptacionTratamiento}
                          />
                        }
                      ></FormControlLabel>
                    </>
                  </Stack>
                </Box>
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <br></br>
                <br></br>
                <LoadingButton
                  variant="contained"
                  onClick={preReservar}
                  disabled={!checkedCondiciones || !checkedTratamiento}
                  sx={{
                    minWidth: "400px",
                    backgroundColor: Colors.orange_ibero,
                  }}
                  loading={loadingPrereserva}
                >
                  Enviar pre-reserva
                </LoadingButton>

                {/* <LoadingButton
                  variant="contained"
                  onClick={preReservarPrueba}
                  disabled={!checkedCondiciones || !checkedTratamiento}
                  sx={{
                    minWidth: "400px",
                    backgroundColor: Colors.orange_ibero,
                  }}
                  loading={loadingPrereserva}
                >
                  Prueba
                </LoadingButton> */}
              </Grid>
              <br />
            </CardContent>
          </Card>
        </React.Fragment>
      ) : (
        <></>
      )}

      {flagPresupuesto && cardNumber >= 6 ? (
        <React.Fragment>
          <br />
          <br />
          <Card sx={{ maxWidth: 1100 }}>
            <CardHeader
              sx={{ bgcolor: Colors.dove_gray }}
              avatar={
                <Avatar
                  sx={{ bgcolor: Colors.orange_ibero }}
                  aria-label="recipe"
                >
                  6
                </Avatar>
              }
              titleTypographyProps={{ variant: "h5", color: Colors.blue_ibero }}
              title="Datos del presupuesto"
            ></CardHeader>
            <CardContent>
              <CardHeader
                sx={{ bgcolor: alpha(Colors.blue_ibero, 0.3) }}
                titleTypographyProps={{
                  variant: "h6",
                  color: Colors.blue_ibero,
                }}
                title="Datos del solicitante"
              ></CardHeader>

              <Card sx={{ minWidth: 275, with: "100%" }} variant="outlined">
                <CardContent>
                  {DatosContacto("presupuesto")}
                  <br></br>
                </CardContent>
              </Card>

              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <br></br>
                <br></br>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ width: "100%" }}
                >
                  <Stack direction="column" spacing={2}>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      sx={{ textAlign: "center", paddingTop: "0px" }}
                    >
                      ** El presupuesto tiene validez de 3 días, sujeto a
                      disponibilidad **
                    </Typography>
                  </Stack>
                </Box>
                <br></br>
                <Divider></Divider>
                <br></br>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ width: "100%" }}
                >
                  <Stack direction="rom" spacing={2}>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      sx={{ textAlign: "center", paddingTop: "7px" }}
                    >
                      Para realizar el presupuesto favor aceptar las{" "}
                      <a href="/#/condiciones" target={"_blank"}>
                        condiciones generales
                      </a>
                      .
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedCondicionesPresupuesto}
                          onChange={handleChangeAceptacionPresupuesto}
                        />
                      }
                    ></FormControlLabel>
                  </Stack>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ width: "100%" }}
                >
                  <Stack direction="rom" spacing={2}>
                    <>
                      <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        sx={{ textAlign: "center", paddingTop: "7px" }}
                      >
                        Para realizar el presupuesto favor aceptar las{" "}
                        <a href="/#/tratamientodatos" target={"_blank"}>
                          políticas de tratamiento de datos personales
                        </a>
                        .
                      </Typography>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkedTratamientoPresupuesto}
                            onChange={
                              handleChangeAceptacionTratamientoPresupuesto
                            }
                          />
                        }
                      ></FormControlLabel>
                    </>
                  </Stack>
                </Box>
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <br></br>
                <br></br>
                <LoadingButton
                  variant="contained"
                  onClick={calculaPrecio}
                  disabled={
                    !checkedCondicionesPresupuesto ||
                    !checkedTratamientoPresupuesto
                  }
                  sx={{
                    minWidth: "400px",
                    backgroundColor: Colors.orange_ibero,
                  }}
                  loading={loadingPresupuesto}
                >
                  Guardar presupuesto
                </LoadingButton>
              </Grid>
              <br />
            </CardContent>
          </Card>
        </React.Fragment>
      ) : (
        <></>
      )}

      <Dialog
        fullScreen={fullScreen}
        open={flagReservaDialog}
        onClose={handleCloseDialog}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <AppbarHeaderImage
            src="/images/ColReservas/logocolreservasazul200.png"
            alt="Colreservas"
            title="Colreservas"
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <br></br>
            {flagReservaOK ? (
              <>
                <Typography>
                  <Box fontWeight="Medium" display="inline">
                    Le informamos que hemos recibido su{" "}
                    <Box
                      fontWeight="bold"
                      display="inline"
                      color={Colors.orange_ibero}
                    >
                      Pre-Reserva
                    </Box>{" "}
                    número{" "}
                    <Box
                      fontWeight="bold"
                      display="inline"
                      color={Colors.orange_ibero}
                    >
                      CO-{expediente}
                    </Box>{" "}
                    del circuito{" "}
                    <Box
                      fontWeight="bold"
                      display="inline"
                      color={Colors.blue_ibero}
                    >
                      {props.circuito.NOMBREVIAJE}
                    </Box>{" "}
                    para{" "}
                    <Box
                      fontWeight="bold"
                      display="inline"
                      color={Colors.blue_ibero}
                    >
                      {parseInt(arrSencilla.length) +
                        parseInt(arrDoble.length) +
                        parseInt(arrTriple.length) +
                        parseInt(arrCompartida.length)}
                    </Box>{" "}
                    personas iniciando el{" "}
                    <Box
                      fontWeight="bold"
                      display="inline"
                      color={Colors.blue_ibero}
                    >
                      {idfecha.substring(idfecha.length - 2, idfecha.length)}/
                      {idfecha.substring(
                        idfecha.length - 4,
                        idfecha.length - 2
                      )}
                      /
                      {idfecha.substring(
                        idfecha.length - 8,
                        idfecha.length - 4
                      )}
                    </Box>
                    .
                  </Box>{" "}
                </Typography>
                <br></br>
                <Typography>
                  <Box fontWeight="Medium" display="inline">
                    Para garantizar su viaje, debe proceder al pago de la
                    Pre-Reserva antes del{" "}
                    <Box
                      fontWeight="bold"
                      display="inline"
                      color={Colors.orange_ibero}
                    >
                      {fechaLimiteConfirma}
                    </Box>
                  </Box>
                  .
                </Typography>
                <br></br>
                {iberolunaWeb ? (
                  <>                    
                    <Typography>
                      <Box fontWeight="Medium" display="inline">
                        Hemos enviado un correo a la dirección{" "}
                        <Box
                          fontWeight="bold"
                          display="inline"
                          color={Colors.orange_ibero}
                        >
                          {emailContacto}
                        </Box>{" "}
                        con la información de su pre-reserva.
                      </Box>
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography>
                      <Box fontWeight="Medium" display="inline">
                        En breve nuestros asesores se comunicarán con usted.
                      </Box>
                    </Typography>
                  </>
                )}
                {selTrasladoSalida === "Si" || selTrasladoLlegada === "Si" ? (
                  <>
                    <br></br>
                    <Typography
                      sx={{ color: Colors.danger, textAlign: "center" }}
                    >
                      <Box fontWeight="Medium" display="inline">
                        El precio del traslado de llegada, puede sufrir ajustes
                        dependiendo del aeropuerto y la hora de llegada de los
                        vuelos que usted reserve.{" "}
                      </Box>
                    </Typography>
                    <br></br>
                    <Typography
                      sx={{ color: Colors.danger, textAlign: "center" }}
                    >
                      <Box fontWeight="Medium" display="inline">
                        El traslado de salida no está incluido.{" "}
                      </Box>
                    </Typography>
                    <br></br>
                  </>
                ) : (
                  <></>
                )}

{iberolunaWeb ? (
                  <>
                    <br></br>
                    <Typography>
                      <Box fontWeight="Medium" display="inline">
                        Si desea confirmar su pre-reserva, presione aquí para
                        realizar el pago en COP:{" "}
                      </Box>
                    </Typography>
                    <br></br>
                    <Typography>
                      <Box
                        m={1}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{ height: 50, paddingLeft: 20, paddingRight: 20 }}
                          onClick={ActivaOpciones}
                        >
                          Realizar Pago
                        </Button>
                      </Box>
                    </Typography>
                  </>
                ) : (
                  <></>
                )}
                <br></br>
                <Typography>
                  <Box fontWeight="Medium" display="inline">
                    Gracias por preferirnos.
                  </Box>
                </Typography>
              </>
            ) : (
              <>
                <Typography>
                  <Box fontWeight="Medium" display="inline">
                    Hemos tenido problema para realizar su{" "}
                    <Box
                      fontWeight="bold"
                      display="inline"
                      color={Colors.orange_ibero}
                    >
                      Pre-Reserva
                    </Box>{" "}
                    del circuito{" "}
                    <Box
                      fontWeight="bold"
                      display="inline"
                      color={Colors.blue_ibero}
                    >
                      {props.circuito.NOMBREVIAJE}
                    </Box>{" "}
                    para{" "}
                    <Box
                      fontWeight="bold"
                      display="inline"
                      color={Colors.blue_ibero}
                    >
                      {parseInt(arrSencilla.length) +
                        parseInt(arrDoble.length) +
                        parseInt(arrTriple.length) +
                        parseInt(arrCompartida.length)}
                    </Box>{" "}
                    personas iniciando el{" "}
                    <Box
                      fontWeight="bold"
                      display="inline"
                      color={Colors.blue_ibero}
                    >
                      {idfecha.substring(idfecha.length - 2, idfecha.length)}/
                      {idfecha.substring(
                        idfecha.length - 4,
                        idfecha.length - 2
                      )}
                      /
                      {idfecha.substring(
                        idfecha.length - 8,
                        idfecha.length - 4
                      )}
                    </Box>
                    .
                  </Box>{" "}
                </Typography>
                <br></br>
                <Typography>
                  <Box fontWeight="Medium" display="inline">
                    Favor intentelo de nuevo, si el problema persiste favor
                    enviarnos su solicitud al correo{" "}
                    <Box
                      fontWeight="bold"
                      display="inline"
                      color={Colors.orange_ibero}
                    >
                      cotizaciones@iberolunatravel.com
                    </Box>
                  </Box>
                  .
                </Typography>
                <br></br>
                <Typography>
                  <Box fontWeight="Medium" display="inline">
                    Disculpe las molestías causadas.
                  </Box>
                </Typography>
              </>
            )}

            <br></br>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} autoFocus>
            {flagReservaOK ? (
              <TaskAltIcon
                fontSize="large"
                sx={{ color: "green" }}
              ></TaskAltIcon>
            ) : (
              <HighlightOffIcon
                fontSize="large"
                sx={{ color: "red" }}
              ></HighlightOffIcon>
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {/* //------------------------------------------ */}
      <Dialog
        fullScreen={fullScreen}
        open={flagPresupuestoDialog}
        onClose={handleCloseDialogPresupuesto}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <AppbarHeaderImage
            src="/images/ColReservas/logocolreservasazul200.png"
            alt="Colreservas"
            title="Colreservas"
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <br></br>
            {flagPresupuestoOK ? (
              <>
                <Typography>
                  <Box fontWeight="Medium" display="inline">
                    Le informamos que hemos recibido su{" "}
                    <Box
                      fontWeight="bold"
                      display="inline"
                      color={Colors.orange_ibero}
                    >
                      Presupuesto
                    </Box>{" "}
                    con el número{" "}
                    <Box
                      fontWeight="bold"
                      display="inline"
                      color={Colors.orange_ibero}
                    >
                      PR-{idPresupuesto}
                    </Box>{" "}
                    del circuito{" "}
                    <Box
                      fontWeight="bold"
                      display="inline"
                      color={Colors.blue_ibero}
                    >
                      {props.circuito.NOMBREVIAJE}
                    </Box>{" "}
                    para{" "}
                    <Box
                      fontWeight="bold"
                      display="inline"
                      color={Colors.blue_ibero}
                    >
                      {parseInt(arrSencilla.length) +
                        parseInt(arrDoble.length) +
                        parseInt(arrTriple.length) +
                        parseInt(arrCompartida.length)}
                    </Box>{" "}
                    personas iniciando el{" "}
                    <Box
                      fontWeight="bold"
                      display="inline"
                      color={Colors.blue_ibero}
                    >
                      {idfecha.substring(idfecha.length - 2, idfecha.length)}/
                      {idfecha.substring(
                        idfecha.length - 4,
                        idfecha.length - 2
                      )}
                      /
                      {idfecha.substring(
                        idfecha.length - 8,
                        idfecha.length - 4
                      )}
                    </Box>
                    .
                  </Box>{" "}
                </Typography>
                <br></br>
                <Typography>
                  <Box fontWeight="Medium" display="inline">
                    Este presupuesto tiene fecha límite{" "}
                    <Box
                      fontWeight="bold"
                      display="inline"
                      color={Colors.orange_ibero}
                    >
                      {fechaLimitePresupuesto}
                    </Box>
                  </Box>
                  .
                </Typography>
                <br></br>
                {iberolunaWeb ? (
                  <>
                    <Typography>
                      <Box fontWeight="Medium" display="inline">
                        Hemos enviado un correo a la dirección{" "}
                        <Box
                          fontWeight="bold"
                          display="inline"
                          color={Colors.orange_ibero}
                        >
                          {emailContacto}
                        </Box>{" "}
                        con la información de su presupuesto.
                      </Box>
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography>
                      <Box fontWeight="Medium" display="inline">
                        En breve nuestros asesores se comunicarán con usted.
                      </Box>
                    </Typography>
                  </>
                )}
                {selTrasladoSalida === "Si" || selTrasladoLlegada === "Si" ? (
                  <>
                    <br></br>
                    <Typography
                      sx={{ color: Colors.danger, textAlign: "center" }}
                    >
                      <Box fontWeight="Medium" display="inline">
                        El precio del traslado de llegada, puede sufrir ajustes
                        dependiendo del aeropuerto y la hora de llegada de los
                        vuelos que usted reserve.{" "}
                      </Box>
                    </Typography>
                    <br></br>
                    <Typography
                      sx={{ color: Colors.danger, textAlign: "center" }}
                    >
                      <Box fontWeight="Medium" display="inline">
                        El traslado de salida no está incluido.{" "}
                      </Box>
                    </Typography>
                    <br></br>
                  </>
                ) : (
                  <></>
                )}

                {/* <br></br>
                <Typography>
                  <Box fontWeight="Medium" display="inline">
                    Si desea confirmar su pre-reserva, presione aquí para
                    realizar el pago en COP:{" "}
                  </Box>
                </Typography>
                <br></br>
                <Typography>
                  <Box
                    m={1}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ height: 50, paddingLeft: 20, paddingRight: 20 }}
                      onClick={ActivaOpciones}
                    >
                      Realizar Pago
                    </Button>
                  </Box>
                </Typography> */}
                <br></br>
                <Typography>
                  <Box fontWeight="Medium" display="inline">
                    Gracias por preferirnos.
                  </Box>
                </Typography>
              </>
            ) : (
              <>
                <Typography>
                  <Box fontWeight="Medium" display="inline">
                    Hemos tenido problema para realizar su{" "}
                    <Box
                      fontWeight="bold"
                      display="inline"
                      color={Colors.orange_ibero}
                    >
                      Presupuesto
                    </Box>{" "}
                    del circuito{" "}
                    <Box
                      fontWeight="bold"
                      display="inline"
                      color={Colors.blue_ibero}
                    >
                      {props.circuito.NOMBREVIAJE}
                    </Box>{" "}
                    para{" "}
                    <Box
                      fontWeight="bold"
                      display="inline"
                      color={Colors.blue_ibero}
                    >
                      {parseInt(arrSencilla.length) +
                        parseInt(arrDoble.length) +
                        parseInt(arrTriple.length) +
                        parseInt(arrCompartida.length)}
                    </Box>{" "}
                    personas iniciando el{" "}
                    <Box
                      fontWeight="bold"
                      display="inline"
                      color={Colors.blue_ibero}
                    >
                      {idfecha.substring(idfecha.length - 2, idfecha.length)}/
                      {idfecha.substring(
                        idfecha.length - 4,
                        idfecha.length - 2
                      )}
                      /
                      {idfecha.substring(
                        idfecha.length - 8,
                        idfecha.length - 4
                      )}
                    </Box>
                    .
                  </Box>{" "}
                </Typography>
                <br></br>
                <Typography>
                  <Box fontWeight="Medium" display="inline">
                    Favor intentelo de nuevo, si el problema persiste favor
                    enviarnos su solicitud al correo{" "}
                    <Box
                      fontWeight="bold"
                      display="inline"
                      color={Colors.orange_ibero}
                    >
                      cotizaciones@iberolunatravel.com
                    </Box>
                  </Box>
                  .
                </Typography>
                <br></br>
                <Typography>
                  <Box fontWeight="Medium" display="inline">
                    Disculpe las molestías causadas.
                  </Box>
                </Typography>
              </>
            )}

            <br></br>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogPresupuesto} autoFocus>
            {flagPresupuestoOK ? (
              <TaskAltIcon
                fontSize="large"
                sx={{ color: "green" }}
              ></TaskAltIcon>
            ) : (
              <HighlightOffIcon
                fontSize="large"
                sx={{ color: "red" }}
              ></HighlightOffIcon>
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {/* //---------------------------------------------- */}

      <Dialog
        fullScreen={fullScreen}
        open={flagDialogMensaje}
        onClose={handleCloseDialogMensaje}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <AppbarHeaderImage
            src="/images/ColReservas/logocolreservasazul200.png"
            alt="Colreservas"
            title="Colreservas"
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography>
              <Box
                fontWeight="Medium"
                //display="inline"
                sx={{ width: "100%" }}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {mensajeDialog}
              </Box>{" "}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogMensaje} autoFocus>
            <CloseOutlinedIcon
              fontSize="large"
              sx={{ color: "gray" }}
            ></CloseOutlinedIcon>
          </Button>
        </DialogActions>
      </Dialog>

      {/*   <Dialog
        open={openPago}
        onClose={handleCloseDialogPago}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >       
       <PagoOpciones></PagoOpciones>
      </Dialog>
 */}
      <Dialog
        open={openPago}
        onClose={handleCloseDialogPago}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <PagoOpciones
          email={emailContacto}
          titular={nombreContacto + " " + apellidoContacto}
          celular={celularContacto}
          expediente={expediente}
          localizadorOnline={props.logid}
          precioTotalLocal={parseFloat(coti.PrecioGastosLocal)}
          //prereserva={codigoReserva}
          cerrarPago={handleCloseDialogPago}
          permiteAbono={permiteAbono}
        ></PagoOpciones>
        <DialogActions>
          <Button onClick={handleCloseDialogPago} autoFocus>
            <CloseOutlinedIcon
              fontSize="large"
              sx={{ color: "gray" }}
            ></CloseOutlinedIcon>
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
