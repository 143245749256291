import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import TitleDesktop from "./titleDesktop";
//import TitleMobile from "./titleMobile";

export default function title(props) {
  // const theme = useTheme();
  // const matches = useMediaQuery(theme.breakpoints.down('md'));
  const applyOrder = (order) => {
    props.applyOrder(order);
  };

  return (
    <>
      {/* {matches ? <TitleMobile matches={matches}/> : <TitleDesktop matches={matches}/>} */}
      <TitleDesktop
        applyOrder={applyOrder}
        totalCircuitos={props.totalCircuitos}
        matches={props.matches}
        tipo={props.tipo}
        orden={props.orden}
        ordenCampo={props.ordenCampo}
      ></TitleDesktop>
    </>
  );
}
