import {
  Grid,
  List,
  ListItemText,
  Typography,
  Button,
  ListItemButton,
} from "@mui/material";
import { Box } from "@mui/system";
import { Colors } from "../../styles/theme";
import FacebookIcon from "@mui/icons-material/Facebook";

import InstagramIcon from "@mui/icons-material/Instagram";
import { FooterTitle } from "../../styles/footer";

import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import { useState } from "react";
import axios from "axios";

export default function Footer() {
  const navigate = useNavigate();
  const [trm, setTrm] = useState(0);

  const handleOnClickResponsabilidad = useCallback(
    () => navigate("/condiciones", { replace: true }),
    [navigate]
  );

  const handleOnClickPqrs = useCallback(
    () => navigate("/pqrs", { replace: true }),
    [navigate]
  );

  const handleOnClickClausula = useCallback(
    () => navigate("/clausula", { replace: true }),
    [navigate]
  );

  const handleOnClickPoliticas = useCallback(
    () => navigate("/tratamientodatos", { replace: true }),
    [navigate]
  );
  const handleOnClickRetracto = useCallback(
    () => navigate("/politica", { replace: true }),
    [navigate]
  );

  useEffect(() => {
    // 👇️ only runs once
    fillTRM();
  }, []);

  const fillTRM = async () => {
    const url = "https://iberolunatravel.online/wse/api/getTRM";
    const resp = await axios.get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    setTrm(resp.data.TRM);
  };

  return (
    <Box
      sx={{
        background: Colors.blue_ibero,
        color: Colors.white,
        // p: { xs: 4, md: 10 },
        // pt: 12,
        // pb: 12,
        paddingTop: "25px",
        paddingBottom: "25px",
        paddingLeft: "7px",
        paddingRight: "5px",
        marginTop: "15px",
        fontSize: { xs: "12px", md: "14px" } 
      }}
    >
      <Grid container spacing={8} justifyContent="center">
        <Grid item md={6} lg={5}>
          <FooterTitle variant="h6">Colreservas S.A.S.</FooterTitle>
         {/*  <Typography variant="body1">
            Expertos en turismo desde hace 30 años, con el apoyo de grandes
            operadores en los 5 Continentes, las mejores herramientas y equipo
            altamente capacitado, para dar las opciones de viaje más
            competitivas del mercado, con mayor respaldo para su tranquilidad y
            seguridad.
          </Typography>
          <br />
          <Typography variant="body1">
            Somos una empresa que no vende viajes sino EXPERIENCIAS. Creamos un
            turismo responsable a través del cual pretendemos transmitir la
            importancia de valorar y cuidar nuestro entorno, la tierra que nos
            rodea, distintas culturas, formas diferentes de enfrentarse a la
            vida. Todo esto gracias a los trabajadores que hacen posible
            transportar a los clientes un gran valor añadido que es la pasión
            por viajar.
          </Typography>
          <br></br>
          <br></br> */}
          <p>
            <strong>NIT.</strong> 900.174.648-3
          </p>
          <br></br>
          <p>
          <strong>Oficina Bogotá:</strong> Carrera 5 No. 71-45 Edificio La Strada Oficina 501 - RNT No. 26962 - Lunes a viernes de 8:00 am a 5:30 pm y Sábados de 9:00 am a 1:30 pm
          </p>
          <br></br>
          <p>
            <strong>Oficina Pereira:</strong> Calle 14 No. 23-42 Barrio Álamos - RNT No. 15554 - Lunes a viernes de 8:00 am a 6:00 pm y Sábados de 9:00 am a 1:30 pm
          </p>
          <br></br>
          <p>
            <strong>Email:</strong> servicioalcliente@colreservas.com
          </p>
          <br></br>
          {/* <Link
            component="button"
            variant="BUTTON"
            color="inherit"
            onClick={() => {
              window.open("#/quienessomos", "_self");
            }}
          >
            QUIENES SOMOS
          </Link> */}

          <Box
            sx={{
              mt: 4,
              color: Colors.dove_gray,
            }}
          >
            {/* <Button
              onClick={() =>
                window.open(
                  "https://www.facebook.com/Iberolunatravel/",
                  "_blank"
                )
              }
            >
              <FacebookIcon
                sx={{ color: Colors.orange_ibero, fontSize: "68px" }}
              />
            </Button>
            <Button
              onClick={() =>
                window.open(
                  "https://www.instagram.com/viajes_iberoluna/",
                  "_blank"
                )
              }
            >
              <InstagramIcon
                sx={{ mr: 1, color: Colors.orange_ibero, fontSize: "68px" }}
              />
            </Button> */}
            <Button>
              <img
                src={`https://colreservas.com/img/logos-entidades.png`}
                loading="lazy"
                // width="68px"
                alt="Anato"
              ></img>
            </Button>
          </Box>
        </Grid>
        <Grid item md={6} lg={6}>
          <FooterTitle variant="body1">Enlaces de interés</FooterTitle>
          <List dense={true}>
            <ListItemButton
              onClick={() =>
                window.open(
                  "https://colreservas.com/terminos-y-condiciones.php",
                  "_blank"
                )
              }
            >
              <ListItemText>
                <Typography lineHeight={1} variant="caption2">
                Términos y condiciones
                </Typography>
              </ListItemText>
            </ListItemButton>
            <ListItemButton  onClick={() =>
                window.open(
                  "https://colreservas.com/quienes-somos.php",
                  "_blank"
                )
              }>
              <ListItemText>
                <Typography lineHeight={1} variant="caption2">
                ¿Quiénes somos?
                </Typography>
              </ListItemText>
            </ListItemButton>
            <ListItemButton  onClick={() =>
                window.open(
                  "https://colreservas.com/contacto.php",
                  "_blank"
                )
              }>
              <ListItemText>
                <Typography lineHeight={1} variant="caption2">
                Servicio al cliente
                </Typography>
              </ListItemText>
            </ListItemButton>
            <ListItemButton  onClick={() =>
                window.open(
                  "https://colreservas.com/codigo-de-conducta.php",
                  "_blank"
                )
              }>
              <ListItemText>
                <Typography lineHeight={1} variant="caption2">
                Código de conducta
                </Typography>
              </ListItemText>
            </ListItemButton>

            <ListItemButton  onClick={() =>
                window.open(
                  "https://colreservas.com/compromiso-con-la-sostenibilidad.php",
                  "_blank"
                )
              }>
              <ListItemText>
                <Typography lineHeight={1} variant="caption2">
                Compromiso con la sostenibilidad
                </Typography>
              </ListItemText>
            </ListItemButton>
            <ListItemButton
              onClick={() =>
                window.open(
                  "https://colreservas.com/recomendaciones-para-viajeros.php",
                  "_blank"
                )
              }
            >
              <ListItemText>
                <Typography lineHeight={1} variant="caption2">
                Recomendaciones para viajeros
                </Typography>
              </ListItemText>
            </ListItemButton>
            <ListItemButton
              onClick={() =>
                window.open(
                  "https://colreservas.com/politica-de-privacidad-y-tratamiento-de-datos-personales.php",
                  "_blank"
                )
              }
            >
              <ListItemText>
                <Typography lineHeight={1} variant="caption2">
                Política de privacidad y tratamiento de datos personales
                </Typography>
              </ListItemText>
            </ListItemButton>
          </List>
        </Grid>
        {/* <Grid item md={6} lg={3}>
          <FooterTitle variant="body1">Información de Interes</FooterTitle>
          <List dense={true}>
            <ListItemButton>
              <ListItemText>
                <Typography lineHeight={1} variant="caption2">
                  Tasa Representativa del Mercado: {trm}
                </Typography>
              </ListItemText>
            </ListItemButton>          
          </List>
        </Grid> */}      
      </Grid>
      <Typography sx={{textAlign: "right", paddingRight: "20px"}}>Elaborado por: <b>BookingsServices</b></Typography>
    </Box>
  );
}
