import {
  TitleContainer,
  TitleHeader,
  TitleHeader2,
  TitleHeaderMobile,
  TitleHeader2Mobile,
  TitleContainerMobile,
} from "../../../styles/circuitsdetail";
import Actions from "./actions";
import { useUIContext } from "../../../context/ui";
import { Colors } from "../../../styles/theme";

export default function TitleDesktop(props) {
  const { setShowSearchBox } = useUIContext();

  const OnClick = (index) => {
    props.onClick(index);
  };

  return (
    <>
      {!props.matches ? (
        <>
          <TitleContainer>
            {props.tipo === "Oferta" ? (
              <>
                <img src="/images/Oferta.png"></img>
              </>
            ) : (
              <></>
            )}

            <TitleHeader sx={{ paddingLeft: "7px", paddingRight: "5px" }}>
              {props.circuitoData.NOMBREVIAJE}{" "}
            </TitleHeader>
            <TitleHeader2>
              (Duración: {props.circuitoData.NUMERODIAS} días)
            </TitleHeader2>
            <Actions onClick={OnClick} />
          </TitleContainer>
        </>
      ) : (
        <>
          <TitleContainerMobile>
            <TitleHeaderMobile sx={{ paddingLeft: "7px", paddingRight: "5px" }}>
              {props.circuitoData.NOMBREVIAJE}{" "}
            </TitleHeaderMobile>
            <TitleHeader2Mobile sx={{ paddingLeft: "7px" }}>
              (Duración: {props.circuitoData.NUMERODIAS} días)
            </TitleHeader2Mobile>
          </TitleContainerMobile>
        </>
      )}
    </>
  );
}
