import {
  Button,
  Grid,
  Paper,
  Table,
  Box,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  Typography,
} from "@mui/material";
import "../../App.css";
import { Container, Stack } from "@mui/material";
import theme from "../../styles/theme";
import Appbar from "../appbar";
import { Fragment, useEffect } from "react";
import Footer from "../footer";
import { UIProvider } from "../../context/ui";
import AppDrawer from "../drawer";
import { Colors } from "../../styles/theme";
import { AppTitleBlue, AppTitleOrange } from "../../styles/app";
import styled from "@emotion/styled";

function Circuitsdetaildesktop(props) {
  useEffect(() => {
    document.title = "Colreservas - Condicione Generales";
  }, []);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: Colors.blue_ibero,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: "8px",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="xl"
        sx={{
          background: "#fff",
          padding: "0px !important",
        }}
      >
        <Stack>
          <UIProvider>
            <Appbar />
            <Stack>
              <Stack
                direction={!props.matches ? "row" : "column"}
                spacing={2}
                justifyContent="space-between"
              >
                <Stack alignItems="center">
                  <AppTitleOrange
                    variant={!props.matches ? "h3" : "h4"}
                    sx={{ textAlign: "center",marginTop: "30px"  }}
                  >
                    CONDICIONES GENERALES Colreservas S.A.S.
                  </AppTitleOrange>
                  <AppTitleBlue
                    variant={!props.matches ? "h5" : "h6"}
                    sx={{ textAlign: "center", marginBottom: "30px" }}
                  >
                    Nit: 830008421-5. Registro Nacional de Turismo número 36964
                  </AppTitleBlue>
                  <Stack
                    sx={{
                      background: "#fff",
                      borderColor: Colors.blue_ibero,
                      padding: "15px",
                      borderRadius: "15px",
                      borderStyle: "solid",
                      borderWidth: "1px",
                      with: "100%",
                      m: "15px",
                    }}
                  >
                    <div style={{ textAlign: "justify" }}>
                      <p>
                        Las condiciones y términos señalados en el presente
                        sitio web, son generales tanto para aquellos usuarios
                        que tengan la calidad de consumidores en los términos de
                        la Ley 1480 de 2011, o en caso contrario, como partes
                        contractuales a las que se les aplicarán las
                        disposiciones generales contractuales del Código de
                        Comercio
                      </p>
                      <br />
                      <p>
                        <strong>
                          ACEPTACIÓN Y ALCANCE DE CONDICIONES GENERALES:
                        </strong>{" "}
                        Las presentes condiciones generales tienen por objeto
                        informar a nuestros usuarios y consumidores del
                        contenido y pautas generales de los productos y
                        servicios de los cuales somos intermediarios y la forma
                        de acceder a ellos. En consecuencia, la información,
                        publicidad y derechos de consumidores o usuarios
                        obedecen a condiciones específicas que no implican la
                        libre interpretación de nuestros usuarios. De modo que,
                        al aceptar las presentes condiciones generales, el
                        usuario consiente en que todas las transacciones
                        realizadas en este portal web, estarán sujetas a estas
                        condiciones. Colreservas S.A.S., se reserva el
                        derecho de modificar estas condiciones en cualquier
                        momento y sin previo aviso, sin embargo, serán
                        aplicables a partir de su publicación en esta página
                        web, por lo que el usuario se compromete a leer estas
                        condiciones generales con el objetivo de mantenerse
                        informado de cualquier actualización o cambio.
                      </p>
                      <br />
                      <p>
                        <strong>RESPONSABILIDADES:</strong> Este sitio web,
                        pertenece al operador Colreservas S.A.S., quien
                        declara explícitamente que obra como intermediario entre
                        "el pasajero" y "los prestatarios", es decir las
                        entidades o personas llamadas a facilitar los servicios
                        que se indican en los itinerarios; por consiguiente, se
                        compromete a cumplir con los servicios de
                        intermediación, para los cuales empleará la diligencia
                        comercial debida, propia de una obligación de medio, sin
                        perjuicio de las salvedades especificadas en estas
                        Condiciones Generales y la responsabilidad por
                        incumplimiento de los prestatarios en la ejecución de
                        sus obligaciones, la ocurrencia de cualquier daño,
                        herida, accidente, retraso o irregularidades durante la
                        ejecución de los servicios, a las personas que efectúen
                        el viaje por su mediación, así como al equipaje y demás
                        objetos de su propiedad; por las consecuencias
                        económicas derivadas de malas condiciones
                        climatológicas, manifestaciones, tumultos, guerras o
                        rumores de guerra, actos terroristas o por cualquier
                        otro motivo que constituya caso fortuito o de fuerza
                        mayor y que por ende no pueda ser imputable a la
                        voluntad de cualquiera de las partes, se regirá por lo
                        dispuesto en la Ley 1480 de 2011. En todo caso, los
                        gastos que se causen por anulación total o parcial de un
                        paquete, serán los que apliquen los prestatarios de los
                        servicios. Cabe señalar que Colreservas S.A.S. no
                        es responsable por la compra de excursiones y/o
                        servicios adicionales que el pasajero adquiera en el
                        destino. Cuando el pasajero viaje en cualquier medio de
                        transporte aéreo, terrestre, fluvial o marítimo,
                        conviene el someterse expresamente a las leyes del país
                        en que esté matriculado el requerido transporte en caso
                        de accidente. Para el transporte aéreo, el pasajero
                        conviene en someterse a las condiciones generales de
                        contratación impresas en el billete de pasaje, sin que
                        la agencia de viajes, ni el operador, puedan intervenir
                        ni ser responsables. Colreservas S.A.S. y los
                        operadores prestatarios de los servicios se reservan la
                        prerrogativa de retirar de un tour al pasajero que, por
                        causa grave de carácter moral o disciplinario,
                        debidamente comprobada, atente contra el éxito de este;
                        caso en el cual, el pasajero tendrá derecho al reintegro
                        del valor de los servicios turísticos no disfrutados.
                        Asimismo, Colreservas S.A.S., no se hace
                        responsable frente a los asuntos legales u otros
                        inconvenientes en que pueda verse involucrado un
                        pasajero en caso de que éste se vea obligado a retirarse
                        de la excursión o plan turístico por faltas de carácter
                        moral, disciplinarias o legales y, así mismo, frente a
                        los gastos personales en que el mismo incurra. Del mismo
                        modo, Colreservas S.A.S., no se hace responsable
                        con relación al equipaje del pasajero y el contenido de
                        éste, ni tampoco de cualquier objeto que lleve consigo,
                        puesto que los mismos son responsabilidad exclusiva del
                        pasajero.
                      </p>
                      <br />
                      <p>
                        <strong>RESERVACIONES Y PAGO:</strong> Toda solicitud de
                        reservación de los circuitos turísticos deberá ser
                        efectuada a través del buscador de circuitos dispuesto
                        en el portal web: www.iberolunatravel.online para lo
                        cual, el usuario deberá ingresar el mes y año, ciudades
                        y/o países que desee visitar. El portal web realizará la
                        búsqueda de los circuitos disponibles en las fechas y
                        destinos seleccionados, el usuario podrá filtrar los
                        resultados por ciudad de inicio, ciudad de finalización
                        y precio; el usuario seleccionará el circuito deseado
                        donde puede cotizar en la fecha específica disponible y
                        proceder a realizar la reserva en la fecha preferida,
                        aquí proporcionará la información personal detallada de
                        nombres completos, fechas de nacimiento, números de
                        pasaporte, nacionalidad y genero de cada uno de los
                        pasajeros, datos de contacto de un titular: celular y
                        e-mail para completar la reserva, es deber del usuario
                        revisar y confirmar la información de la reserva antes
                        de proceder al pago. Al realizar una pre-reserva se
                        solicitará un pago mínimo del 25% de la reserva,
                        pudiendo pagar el excedente hasta 40 días antes de
                        iniciar el viaje, sin ser esto una forma de
                        financiamiento. O si prefiere realizar el pago total en
                        un solo pago. Para realizar el pago se redirigirá a la
                        pasarela de pagos donde debe ingresara los datos para
                        realizar el pago, estos datos son para uso exclusivos de
                        la transacción por el banco y no son recopilados por
                        Colreservas S.A.S. Si no desea realizar el pago en
                        este momento puede realizarlo posteriormente en el link
                        que le llegara al correo electrónico con la información
                        de la pre-reserva, o directamente en la página web, en
                        la sección de Consulta de Reserva, respetando los plazos
                        de vencimiento de la pre-reserva o reserva según el
                        caso, de no realizarse los pagos en los plazos
                        estipulados se cancelaran automáticamente acarreando los
                        gastos correspondientes. El pago podrá realizarlo con
                        tarjeta de crédito o debito por medio de la pasarela de
                        pagos. Al completarse el proceso de pago Viajes
                        Iberoluna S.A.S. enviará una confirmación de la reserva
                        al correo electrónico donde incluirá el enlace “Mi
                        Viaje” de la reserva en la cual podrá encontrar
                        información detallada del viaje, además de datos de
                        utilidad como: Bono de servicios, Itinerario en detalle,
                        Listado de hoteles confirmados con direcciones y
                        teléfonos, visitas y actividades opcionales (que ya se
                        pueden comprar con tarjeta de crédito, a través de este
                        enlace), Guías de viaje, Teléfono de emergencias 24
                        horas en destino. De producirse por parte del pasajero
                        modificaciones o la anulación de la reserva, sin
                        perjuicio del derecho de retracto, se cobrará el costo
                        operativo que esto genere.
                      </p>
                      <br />
                      <p>
                        <strong>PRECIOS DE VENTA:</strong> Todos los precios de
                        venta que se publican en el portal web, se indican en
                        dólares estadounidenses (USD), con la respectiva Tasa
                        Representativa del Mercado (TRM) del día, la cual podrá
                        ser observada en el aparte “Información de interés” del
                        portal web. Al momento del pago se tendrá en cuenta la
                        TRM del día en que se realice y el portal indicará el
                        valor a pagar en pesos colombianos (COP).
                      </p>
                      <br />
                      <p>
                        En aquellos países, en que la paridad de la moneda local
                        varía frente al Dólar o al Euro, los precios están
                        sujetos a ajustes de producirse alteraciones entre las
                        paridades monetarias, o por aumentos de los precios
                        internos. Asimismo, en fechas de grandes eventos o para
                        reservas de última hora los hoteles podrán aplicar
                        suplementos, los cuales serán informados al confirmarse
                        las reservaciones siendo potestad del pasajero aceptarlo
                        o rechazarlo. Las tarifas aéreas incluidas en los
                        programas que así lo indiquen, son las vigentes al
                        momento de la impresión del folleto, por lo que, de
                        producirse aumentos o disminuciones con posterioridad,
                        éstos serán aplicados. Los precios de las extensiones de
                        los tours son válidos únicamente para ser vendidos
                        juntamente con el programa básico. La vigencia de cada
                        precio indica el último día para regresar. Toda salida
                        que se extienda más allá de la fecha de vigencia podrá
                        tener suplemento en el precio.
                      </p>
                      <br />
                      <p>
                        <strong>PRECIO DE HABITACION SENCILLA:</strong> El
                        precio publicado para habitación sencilla es para un
                        mínimo de dos personas viajando juntas. Cuando el
                        pasajero viaje sólo en un programa, deberá abonar un
                        suplemento por diferencia en los traslados y excursiones
                        que deban ser prestados en privado.
                      </p>
                      <br />
                      <p>
                        <strong>LOS PROGRAMAS INCLUYEN:</strong> a) alojamiento
                        en los hoteles señalados o de similar categoría y
                        precio, según se hayan confirmado antes de la salida y
                        conforme figuren en los bono de servicios u otros
                        documentos de viaje que sean entregados al pasajero; b)
                        impuestos de alojamiento que estén creados al momento de
                        la impresión del folleto o manual, quedando excluidas
                        algunas tasas municipales menores que no pueden ser
                        pagadas por el organizador; c) las comidas indicadas en
                        el texto del itinerario, siempre en base a menús fijos,
                        no a la carta; d) los traslados, visitas y excursiones
                        que se mencionen; e) la transportación terrestre, aérea
                        o marítima que se detalle; f) guía local y/o acompañante
                        cuando así se indique; g) cualquier otro servicio que se
                        indique expresamente.
                      </p>
                      <br />
                      <p>
                        <strong>LOS PROGRAMAS NO INCLUYEN:</strong> Cualquier
                        impuesto o tasa que pudiesen crear las autoridades
                        colombianas y de destino, con posterioridad publicación
                        de los programas tasas aeroportuarias ni impuestos de
                        salida de los países que lo apliquen; el “city tax” que
                        varias ciudades del mundo cobran directamente a los
                        huéspedes por el uso de instalaciones públicas; gastos
                        ni trámites de documentación; extras de carácter
                        personal (como lavado y planchado de ropa, comidas no
                        mencionadas, bebidas, llamadas telefónicas, cargos por
                        servicios especiales en los hoteles -como uso de cajas
                        fuertes, canales por cable, películas, etc.-), propinas;
                        costos por el manejo del exceso de equipaje y en general
                        cualquier servicio que no haya sido expresamente
                        detallado. Tarjeta de asistencia medica, traslados de
                        salida y tiquetes aéreos a excepción de que se
                        mencionen.
                      </p>
                      <br />
                      <p>
                        <strong>PASAJES AEREOS EN PAQUETES:</strong> Los pasajes
                        aéreos incluidos en los paquetes turísticos son válidos
                        para viajar en las fechas para las cuales han sido
                        confirmados y que están indicadas en su emisión. Una vez
                        emitidos, los pasajes aéreos pueden no ser reembolsables
                        en caso de no efectuarse el viaje en las fechas
                        previstas. Algunas líneas aéreas, en ciertas épocas del
                        año, autorizan cambios de fechas antes de iniciar el
                        viaje, previo el pago de una penalidad. En el caso de
                        atrasos por causas de fuerza mayor, la línea aérea
                        interviniente actúa de acuerdo con estas normas,
                        otorgando en reemplazo del alojamiento que el cliente
                        deja de percibir en exterior, las comidas y el
                        alojamiento necesario hasta que pueda transportarlo
                        hacia su destino. El pasajero deberá contar con un
                        seguro para cubrir los gastos que se originen por
                        cualquier anulación de vuelos debido a razones
                        climatológicas.
                      </p>
                      <br />
                      <p>
                        <strong>CANCELACIONES Y PENALIDADES:</strong> Sin
                        perjuicio del derecho de retracto, el usuario podrá
                        cancelar los servicios o productos contratados a través
                        de esta página, asumiendo las consecuencias que se
                        deriven para cada caso, para ello deberá hacer uso del
                        formulario de Peticiones, Quejas y Reclamos (PQRS)
                        dispuesta en el portal web. Radicada la petición, Viajes
                        Iberoluna S.A.S., dará el trámite correspondiente a la
                        solicitud y tendrá máximo quince días hábiles a partir
                        de la fecha de recepción para dar respuesta a la
                        solicitud del usuario. Toda cancelación de un programa
                        confirmado en la forma solicitada por el pasajero tiene
                        gastos por concepto de tramitación y comunicaciones,
                        adicional a los gastos generados por los operadores de
                        destino. Los gastos por aplicar son los siguientes:
                      </p>
                      <br />
                      <Grid
                        item
                        xs={12}
                        sx={{ textAlign: "center", width: "100%" }}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          sx={{ width: "100%" }}
                        >
                          <Stack direction="column" spacing={2}>
                            <Box sx={{ witdh: "100%" }}>
                              <Typography
                                variant="h6"
                                color="text.secondary"
                                sx={{
                                  textAlign: "center",
                                  paddingTop: "7px",
                                  color: Colors.orange_ibero,
                                }}
                              >
                                GASTOS POR CANCELACION DE RESERVA
                              </Typography>
                            </Box>
                            <TableContainer component={Paper}>
                              <Table aria-label="simple table">
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell align="center">
                                      DESDE
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      HASTA DIAS ANTES DEL INICIO DEL VIAJE
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      PORCENTAJE SOBRE EL VALOR DE LA RESERVA
                                    </StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <StyledTableRow
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <StyledTableCell align="center">
                                      A partir del día del deposito
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      40 días
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      25%
                                    </StyledTableCell>
                                  </StyledTableRow>
                                  <StyledTableRow
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <StyledTableCell align="center">
                                      39 días
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      30 días
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      35%
                                    </StyledTableCell>
                                  </StyledTableRow>
                                  <StyledTableRow
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <StyledTableCell align="center">
                                      29 días
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      16 días
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      50%
                                    </StyledTableCell>
                                  </StyledTableRow>
                                  <StyledTableRow
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <StyledTableCell align="center">
                                      15 días
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      o menos
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      100%
                                    </StyledTableCell>
                                  </StyledTableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Stack>
                        </Box>
                      </Grid>
                      <br />
                      <p>
                        Hay programas que tienen condiciones de cancelación más
                        estrictas; en estos casos las mismas serán informadas al
                        momento de solicitarse la reservación, para que "el
                        cliente" esté debidamente informado antes de entrar en
                        el periodo de riesgo de incurrir en el cargo. Cuando "el
                        cliente" desista de la realización de un viaje que ya
                        hubiera contratado Colreservas S.A.S reembolsará el
                        saldo, una vez deducida la penalidad aplicada por la
                        empresa prestataria de los servicios y el beneficio
                        propio.
                      </p>
                      <br />
                      <p>
                        <strong>GASTOS DE RESERVACION:</strong> De producirse
                        por parte del pasajero modificaciones de un programa
                        confirmado, genera gastos de cancelación de 35 dólares
                        por persona y por destino incluidos en el paquete o
                        programa, por considerarse una nueva reservación
                      </p>
                      <br />
                      <p>
                        <strong>DEVOLUCIONES:</strong> En caso de existir el
                        caso, se debe realizar una solicitud por medio de la
                        página web, en formato de PQR, como una petición a la
                        cual se dará respuesta, con no más de treinta días
                        hábiles posteriores a la finalización de los servicios
                        prestados por el organizador. El departamento
                        administrativo de Colreservas S.A.S. comprobará en
                        el menor tiempo posible la veracidad de la solicitud y
                        de corresponder efectuará el reintegro, por el medio que
                        el cliente lo solicite. Favor tomar en cuenta que NO SE
                        HARAN DEVOLUCIONES POR SERVICIOS VOLUNTARIAMENTE NO
                        UTILIZADOS Y QUE LAS NOCHES DE CIRCUITOS NO SE PUEDEN
                        REEMPLAZAR ya que forman parte de un "precio de
                        paquete". Los servicios que no sean prestados a
                        consecuencia de fallas o desentendimientos originados
                        por cambios voluntariamente efectuados por el pasajero
                        una vez iniciado los servicios, no originarán ningún
                        reembolso.
                      </p>
                      <br />
                      <p>
                        <strong>DOCUMENTACIÓN:</strong> Para la efectiva
                        prestación de los servicios descritos en esta página
                        web, la obtención y presentación de los documentos
                        relativos a viajes, tales como, documentos de identidad,
                        pasaportes, visas, permisos de salida de menores, cartas
                        de invitación de residentes en país extranjero y demás,
                        son de absoluta responsabilidad de los pasajeros. Viajes
                        Iberoluna S.A.S., no asume responsabilidad alguna en
                        caso de que las autoridades del país o países a ser
                        visitados nieguen al pasajero los documentos requeridos
                        para el ingreso. Cuando sean aplicables, el pasajero
                        deberá tener las vacunas y permisos sanitarios
                        necesarios. El pasajero, deberá presentar la
                        documentación que la autoridad exija . La omisión de
                        este requisito exonera a Colreservas S.A.S., de
                        cualquier reclamación económica que presente el pasajero
                        involucrado en los incidentes que tal conducta pueda
                        generar desde el punto de vista de la realización del
                        plan turístico. Lo mismo ocurre cuando las autoridades
                        de cualquier país rehusasen permitir su entrada por
                        cualquier motivo.
                      </p>
                      <br />
                      <p>
                        <strong>EQUIPAJE Y COMPRAS PERSONALES:</strong> El
                        equipaje y demás efectos personales del pasajero viajan
                        por su propia cuenta y riesgo, por lo que, Viajes
                        Iberoluna S.A.S., procederá a tramitar la efectividad de
                        la garantía con el respectivo prestador del servicio, en
                        los términos de la Ley 1480 . Se recomienda al pasajero
                        la contratación de una tarjeta de asistencia o una
                        póliza de seguros para cubrir este riesgo y otros
                        inconvenientes inherentes al viaje (salud, accidentes,
                        pérdida de dinero). Dado el gran auge y especialización
                        en materia de robos y hurtos, en ningún momento deben
                        descuidarse cámaras fotográficas, joyas, documentos y
                        otros objetos de valor. En casi todos los hoteles
                        existen cajas de seguridad, cuyo servicio es gratuito o
                        con mínimo costo. El pasajero es, asimismo, el único
                        responsable por las compras que pudiese realizar en
                        tiendas u otro tipo de comercios durante el tiempo de la
                        realización de las excursiones.
                      </p>
                      <br />
                      <p>
                        <strong>CAMBIO DE ITINERARIOS Y HOTELES:</strong> El
                        organizador podrá, por razones de fuerza mayor (huelga,
                        fenómenos climatológicos, actos terroristas, eventos
                        políticos imprevistos), caso fortuito o de seguridad,
                        modificar tanto el itinerario como los hoteles por otros
                        de similar categoría y confort, así como el horario de
                        salida de los viajes, previa comunicación a el pasajero.
                        Si un hotel confirmado registrara sobreventa, el
                        pasajero será alojado en un hotel de similar o superior
                        categoría.
                      </p>
                      <br />
                      <p>
                        <strong>ASPECTOS POR DESTACAR EN LOS CIRCUITOS:</strong>{" "}
                        Los programas cuyos recorridos se realizan en autobús,
                        minibús o van tienen características especiales que son
                        importantes de ser conocidas por el cliente:
                      </p>
                      <br />
                      <ul>
                        <li>
                          <strong>Hoteles:</strong> En algunas ciudades, los
                          hoteles que se utilizan suelen no ser céntricos. Esto
                          obedece tanto a las limitaciones de circulación que
                          existen para los autobuses, como a la poca capacidad
                          de los hoteles céntricos. Los hoteles que se utilizan
                          para circuitos turísticos se hallan principalmente
                          construidos en áreas semicéntricas o periféricas,
                          siempre bien comunicados por el transporte público
                          hacia el área céntrica. Asimismo, de coincidir la
                          estadía en una ciudad, con algún gran congreso, feria
                          o evento, en que los hoteles vuelcan toda su capacidad
                          al mismo, el alojamiento podrá tener lugar en una
                          ciudad cercana. En los hoteles de categoría turista,
                          turista superior y primera estándar, existe una
                          tendencia a la reducción de personal, lo que ha
                          llevado a la eliminación de ciertos servicios; como el
                          del servicio de comidas en las habitaciones y el de
                          maleteros.
                        </li>
                        <li>
                          <strong>Habitaciones:</strong> En Europa, dado el
                          pequeño tamaño, no son recomendables las habitaciones
                          triples ya que la tercera cama suele ser un catre
                          añadido, que ocupa el espacio destinado al equipaje.
                          En Europa, América del Sur y Japón lo común es que los
                          hoteles permitan un máximo de 3 personas por
                          habitación.. En Estados Unidos, Canadá, México, el
                          Lejano Oriente (excepto Japón) y Pacífico Sur es usual
                          que los hoteles tengan dos camas dobles y que permitan
                          el alojamiento de hasta 4 personas, en las camas
                          existentes. En caso de requerirse cama adicional, el
                          importe de esta deberá ser abonado directamente por el
                          pasajero al hotel.
                        </li>
                        <li>
                          <strong>Desayuno:</strong> En algunos hoteles se sirve
                          el desayuno buffet, el cual puede ser continental o de
                          tipo europeo, es decir, sin comidas calientes. En
                          estos casos quienes deseen tomar un desayuno americano
                          buffet podrán optar, en cada hotel, por abonar la
                          diferencia para desayunar en el salón destinado a
                          hombres de negocios que solicitan este servicio. En
                          otros hoteles solo existe un tipo de desayuno que es
                          el buffet completo, incluyendo comidas calientes.
                        </li>
                        <li>
                          <strong>Traslados:</strong> En todos los casos los
                          traslados serán efectuados una sola vez y a los vuelos
                          y horarios que nos han sido informados. Si el pasajero
                          llegase en un vuelo distinto o con más de una hora de
                          retraso sobre el horario previsto, deberá dirigirse
                          por su cuenta al hotel confirmado, sin derecho a
                          reintegro por el traslado abonado desde Colombia.
                          (Cuando existan vuelos de conexión en Europa, a menos
                          que existan 3 horas de tiempo entre un vuelo y otro,
                          aconsejamos no llevar el traslado de llegada incluido
                          ya que la experiencia indica que las conexiones se
                          pierden y por ende el traslado también.). Se incluye,
                          en el precio de los traslados, la transportación de 1
                          maleta de tamaño normal con un máximo de 25 kilos, más
                          un bolso de mano con un máximo de 8 kilos, por
                          persona. De existir equipaje adicional que no cupiese
                          en el vehículo asignado para el traslado, "el cliente"
                          deberá abonar el suplemento para su transportación en
                          un vehículo adicional. Todos los paquetes incluyen
                          traslados para los aeropuertos principales y para
                          vuelos diurnos. Para los aeropuertos que utilizan las
                          líneas "low cost" y para traslados nocturnos aplican
                          suplementos.
                        </li>
                        <li>
                          <strong>Restaurantes:</strong> Las comidas incluidas
                          en los diferentes programas son siempre en base a
                          "menú fijo" preestablecido, no a la carta.
                        </li>
                        <li>
                          <strong>Formulario de comentarios:</strong> Varios de
                          los operadores entregan un formulario para que el
                          pasajero formule sus apreciaciones sobre el viaje. Su
                          devolución es importante porque permite sacar un
                          modelo computarizado de aquellos aspectos que hay que
                          corregir en cada programa.
                        </li>
                        <li>
                          <strong>Cambios de guías y autobuses:</strong> Las
                          estrictas normas de descanso de los conductores,
                          puestas en vigencia por la Comunidad Económica
                          Europea, imposibilitan que un mismo equipo de
                          chofer-guía haga un circuito largo. Es por ello que
                          los operadores se han visto obligados a modificar la
                          operatoria de los mismos. Hasta ahora un circuito
                          largo se parcializaba en tramos, ahora es a la
                          inversa, un circuito largo es la sumatoria de varios
                          circuitos cortos. Ello implica que el pasajero
                          viajará, en la mayoría de los casos, con diferentes
                          autobuses, conductores y guías a lo largo de un
                          circuito europeo.
                        </li>
                        <li>
                          <strong>Paradas:</strong> En las distintas etapas
                          entre una ciudad y otra, aproximadamente cada dos
                          horas se hacen paradas de descanso, en lugares con
                          baños públicos.
                        </li>
                        <li>
                          <strong>Rotación de asientos:</strong> La mayoría de
                          los operadores utilizan el sistema rotativo de
                          asientos, con el fin de que todos los pasajeros tengan
                          oportunidad de sentarse en la parte delantera del
                          autobús.
                        </li>
                        <li>
                          <strong>Espacio entre filas de asientos:</strong> Los
                          autobuses vienen de fábrica con un tamaño estándar de
                          separación entre filas de asientos. Para personas de
                          gran estatura (1.80 metros o más), suelen ser
                          incómodos para las piernas.
                        </li>
                        <li>
                          <strong>Excursiones y visitas:</strong> A menos que se
                          señale lo contrario, las excursiones de todos los
                          programas se prestan en base a servicios regulares, en
                          van, minibús o autobús de turismo. Las visitas son
                          panorámicas e incluyen las entradas a monumentos o
                          sitios de interés, cuando así se mencione en el
                          recorrido. Las excursiones regulares, por lo general,
                          se inician y finalizan en un punto céntrico dentro de
                          cada ciudad, debiéndose trasladar el cliente, por su
                          cuenta entre el hotel en que se encuentre alojado y
                          ese punto, tanto a la ida como al regreso. En caso de
                          requerir el cliente servicios privados, se cotizará el
                          suplemento respectivo de acuerdo con el número de
                          pasajeros participantes.
                        </li>
                        <li>
                          <strong>Excursiones opcionales:</strong> El guía
                          acompañante ofrece, en cada circuito, excursiones
                          opcionales, las cuales son "absolutamente voluntarias"
                          y ninguna persona debe sentirse presionada a tomarlas.
                          Nuestra recomendación es que se verifiquen que los
                          precios de las excursiones ofrecidas no sean
                          superiores a los que se publicitan en los hoteles.
                          Tampoco recomendamos comprar artículos en los negocios
                          donde paran los tours ya que suelen tener precios más
                          elevados.
                        </li>
                        <li>
                          <strong>Propinas:</strong> A menos que se indique lo
                          contrario, están incluidas las propinas para el manejo
                          de 1 maleta por persona en los hoteles, durante el
                          itinerario terrestre. Algunos hoteles, especialmente
                          los de categoría turista, turista superior y primera
                          estándar, tienen personal reducido para estas tareas,
                          lo que implica que puede haber una tardanza de hasta
                          una hora para que el equipaje sea llevado a todas las
                          habitaciones. Las propinas a conductores y guías no se
                          incluyen y es norma darlas cuando el servicio haya
                          sido acorde a las expectativas. Es uso y costumbre,
                          ante un buen servicio, un importe de 5 dólares por
                          persona y por día tanto para el guía acompañante como
                          para el conductor y de 3 dólares para los guías
                          locales.
                        </li>
                        <li>
                          <strong>Manejo del exceso de equipaje:</strong> La
                          capacidad del maletero de cada autobús permite la
                          transportación de 1 maleta grande por persona, más un
                          bolso de mano. Si el autobús no va lleno puede haber
                          capacidad para maletas adicionales en cuyo caso su
                          transportación implicará un costo adicional, en
                          promedio, de 5 dólares por cada hotel, por concepto de
                          maleteros.
                        </li>
                        <li>
                          <strong>Puntualidad:</strong> Los horarios de salida
                          de las excursiones deben manejarse con una gran
                          puntualidad ya que de otra forma no se pueden
                          desarrollar todas las actividades programadas. El
                          tiempo de uso diario del autobús lo regula el
                          tacómetro electrónico y cuenta desde que el autobús
                          arranca en la mañana hasta que para en la noche y no
                          puede superar las 9 horas corridas. Aquellos pasajeros
                          que no estén a la hora y en el lugar indicado, se
                          entiende que no participarán en las actividades
                          previstas para ese día.
                        </li>
                        <li>
                          <strong>
                            Horarios de entrada y salida en los hoteles:
                          </strong>{" "}
                          Las habitaciones se entregan, normalmente, a partir
                          del mediodía (algunos hoteles tienen como horario de
                          entrada las 3.00 p.m.). De llegar el pasajero antes de
                          la hora reglamentaria, la entrega de la habitación
                          está supeditada a la disponibilidad de espacio y a la
                          política propia de cada hotel. El día de salida las
                          habitaciones se encuentran pagas también hasta el
                          mediodía, por lo que, en caso de que el pasajero desee
                          permanecer más tiempo en la habitación deberá
                          comunicarlo a la recepción del hotel y hacer el
                          arreglo respectivo. El pasajero debe tener en cuenta
                          que, de no hacer un arreglo previo, la permanencia en
                          su habitación más allá de la hora reglamentaria de
                          salida, implica el cargo automático de un monto extra.
                        </li>
                        <li>
                          <strong>Aire Acondicionado:</strong> Salvo excepciones
                          en Europa del Norte, los hoteles de 4 estrellas
                          cuentan con aire acondicionado y la mayoría de los de
                          3 estrellas también. Pero ello no indica que este
                          servicio se encuentre siempre funcionando. En Europa
                          solo lo ponen en funcionamiento en los meses del año
                          en que la ley lo permite o cuando "ellos consideran
                          que hace calor". La temperatura mínima suele ser mayor
                          a la que se acostumbra en América.
                        </li>
                      </ul>
                      <br />
                      <p>
                        <strong>RETRACTO:</strong> Cuando la venta se realice
                        por métodos no tradicionales o a distancia, y no se
                        hayan comenzado a ejecutar la prestación del servicio
                        antes de cinco (5) días hábiles, se entenderá pactado el
                        derecho de retracto por parte del consumidor. En el
                        evento en que el cliente haga uso de la facultad de
                        retracto, se resolverá el contrato. Colreservas
                        S.A.S. reintegrará el dinero que el cliente hubiese
                        pagado el cual no excederá de treinta (30) días
                        calendario desde el momento en que se ejerció el
                        derecho.
                      </p>
                      <br />
                      <p>
                        <strong>REVERSIÓN:</strong> Cuando la adquisición de los
                        servicios se hubiere realizado a través de mecanismos de
                        comercio electrónico y, para tal efecto, se hubiere
                        utilizado tarjetas de crédito, débito o cualquier otro
                        instrumento de pago electrónico, tales como internet,
                        PSE, Call Center o cualquier otro mecanismo de televenta
                        o tienda virtual, habrá lugar a que el cliente solicite
                        la reversión del pago realizado cuando: sea objeto de
                        fraude, la operación no fuere solicitada, no se
                        recibiere el servicio, cuando el servicio entregado no
                        corresponda a lo solicitado, no cumpla con las
                        características inherentes a las atribuidas por la
                        información que se suministre sobre él. El usuario
                        deberá presentar la queja a través del formulario de PQR
                        dispuesto en el portal web, en el cual indicará la
                        causal o causales invocadas. La queja deberá ser
                        presentada dentro de los cinco (5) días hábiles
                        siguientes a la fecha en que el cliente tuvo noticia de
                        la operación fraudulenta o no solicitada, o en que debió
                        haber recibido el producto o lo recibió defectuoso o sin
                        que correspondiera a lo solicitado.
                      </p>
                      <br />
                      <p>
                        <strong>CODIGO DE CONDUCTA:</strong> Colreservas
                        S.A.S., está comprometida con el código de conducta
                        contra la explotación y violencia sexual infantil y en
                        cumplimiento de los artículos 16 y 17 de la Ley 679 de
                        2001 ADVIERTE sobre las consecuencias legales de la
                        explotación y abuso sexual de menores de edad.
                      </p>
                      <br />
                      <p>
                        <strong>ACEPTACION:</strong> El pasajero declara conocer
                        y aceptar las presentes "normas aplicables a la
                        prestación de los servicios" y dicha aceptación queda
                        ratificada por medio de uno de los siguientes actos: a)
                        El pago de los servicios contratados antes del inicio
                        del viaje, por cualquier modalidad; b) Mediante el uso
                        de una porción cualquiera de los servicios contratados.
                      </p>
                      <br />
                      <br />
                      <br />
                      <Box
                        m={1}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{ height: 50, paddingLeft: 20, paddingRight: 20 }}
                          onClick={() =>
                            window.open(
                              "/contenidos/politicas/CONDICIONESGENERALES.pdf",
                              "_blank"
                            )
                          }
                        >
                          IMPRIMIR PDF
                        </Button>
                      </Box>
                    </div>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <AppDrawer />
          </UIProvider>
        </Stack>
        <Footer />
      </Container>
    </ThemeProvider>
  );
}

export default Circuitsdetaildesktop;
