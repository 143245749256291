export const promotions = [
    /*     {
            id: 1,
            name: "Carta de Viajes",
            url: "https://www.europamundo.com/multibuscadorcarta.aspx",
            description: "Torem ipsum dolor sit amet, consectetur adipisicing elitsed do eiusmo tempor incididunt ut labore et dolore magna",
            image: "https://www.iberolunatravel.com/web/BannerSlider/cartas-de-viaje-2.jpg"
        }, */
        {
            id: 1,
            name: "Europamundo",
            url: "/Catalogos",
            description: "Torem ipsum dolor sit amet, consectetur adipisicing elitsed do eiusmo tempor incididunt ut labore et dolore magna",
            image: "../../images/banner/Catalogos.png"
        }
        ,/*
        {
            id: 2,
            name: "Más vendidos",
            url: "/masvendidos",
            description: "Los mejores programas a Europa.",
            image: "../../images/banner/LosMejoresProgramas.png"
        }
        , */
    {
            id: 3,
            name: "Salidas Grupales 2024",
            url: "/masvendidos",
           description: "Ofertas de temporada.",
        image: "../../images/banner/BannerSalidas2024.png"
        },
        {
            id: 4,
            name: "Cruceros Fluviales",
            url: "/results/Destino/EUROPAFLUVIAL/2024-05/*/*/*",
            description: "Cruceros Fluviales.",
            image: "../../images/banner/CrucerosFluviales.jpg"
        }
    ]