import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import ContactModal from "./contactModalDesktop";
import { useEffect } from "react";

export default function ContactModealIndex(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  

  useEffect(() => {
    // 👇️ only runs once
    window.scrollTo(0, 0)
  }, []);

  const onClick = async () => {
    props.onClick();
  }

    return (
    <>
    <ContactModal onClick={onClick} />
    </>
  );
}
