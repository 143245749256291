import { 
  Button, 
  Stack,
  Typography,
} from "@mui/material";
import {
  TitleHeader,
} from "../../../styles/circuitsdetail";
import { useUIContext } from "../../../context/ui";
import { Colors } from "../../../styles/theme";
import { useEffect, useState } from "react";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

export default function TitleDesktop(props) {
  const { setShowSearchBox } = useUIContext();
  const [value, setValue] = useState(0);
  const [orden, setOrden] = useState(props.orden);
  const [ordenCampo, setOrdenCampo] = useState(props.ordenCampo);

  /*   console.log("propsTitle");
  console.log(props); */

  const orderBy = (param) => {
    var ord = orden;
    if (ordenCampo === param) {
      if (orden === "ASC") {
        ord = "DES";
        //setOrden("DES");
      } else {
        ord = "ASC";
        //setOrden("ASC");
      }
    } else {
      //setOrden("ASC");
      ord = "ASC";
     
    }
    setOrdenCampo(param);
    setOrden(ord);
    props.applyOrder(param + ord);
  };

    useEffect(() => {
      setOrdenCampo(ordenCampo);
      // console.log("XXXXXXXXXXXXXXXXXXXXX")
      // console.log(ordenCampo)
      // console.log(orden)
  }, [orden,ordenCampo]);

  return (
    <div style={{ backgroundColor: props.tipo === "Ofertas" ? Colors.orange_ibero : Colors.blue_ibero, padding: "20px", marginBottom: "5px" }}>
      <Stack
        direction={!props.matches ? "row" : "column"}
        spacing={2}
        justifyContent="space-around"
        alignItems="center"
      >
        <TitleHeader sx={{textAlign: props.matches ? "center" : "left"}}>{props.totalCircuitos} {props.tipo === "Ofertas" ? "en Ofertas encontrados" : "encontrados"}</TitleHeader>
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Typography
            variant="h5"
            gutterBottom
            component="div"
            sx={{ color: Colors.white, fontWeight: "bold" }}
          >
            Ordenar por:
          </Typography>
          {!props.matches ? (
            <>
              {ordenCampo === "Nombre" ? (
                <Button
                  name="nombreOrden"
                  id="nombreOrdenid"
                  variant="outlined"
                  endIcon={
                    orden === "ASC" ? (
                      <ArrowDownwardIcon sx={{ color: Colors.white }} />
                    ) : (
                      <ArrowUpwardIcon />
                    )
                  }
                  sx={{ color: Colors.white, borderColor: Colors.white }}
                  onClick={() => orderBy("Nombre")}
                >
                  Nombre
                </Button>
              ) : (
                <Button
                  variant="outlined"
                   
                  sx={{ color: Colors.white, borderColor: Colors.white }}
                  onClick={() => orderBy("Nombre")}
                >
                  Nombre
                </Button>
              )}
            </>
          ) : (
            <></>
          )}

          {ordenCampo === "Dias" ? (
            <Button
              variant="outlined"
              endIcon={
                orden === "ASC" ? <ArrowDownwardIcon sx={{ color: Colors.white }} /> : <ArrowUpwardIcon />
              }
              sx={{ color: Colors.white, borderColor: Colors.white }}
              onClick={() => orderBy("Dias")}
            >
              Días
            </Button>
          ) : (
            <Button
              variant="outlined"
              sx={{ color: Colors.white, borderColor: Colors.white }}
              onClick={() => orderBy("Dias")}
            >
              Días
            </Button>
          )}

          {ordenCampo === "Precio" ? (
            <Button
              variant="outlined"
              endIcon={
                orden === "ASC" ? <ArrowDownwardIcon sx={{ color: Colors.white }} /> : <ArrowUpwardIcon />
              }
              sx={{ color: Colors.white, borderColor: Colors.white }}
              onClick={() => orderBy("Precio")}
            >
              Precio
            </Button>
          ) : (
            <Button
              variant="outlined"
              sx={{ color: Colors.white, borderColor: Colors.white }}
              onClick={() => orderBy("Precio")}
            >
              Precio
            </Button>
          )}
        </Stack>
        {/*   <BottomNavigation
        sx={{backgroundColor: "lightgray", color: Colors.white}}
              showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction sx={{color: Colors.white}} label="Nombre" icon={<ArrowDownwardIcon />} />
        <BottomNavigationAction sx={{color: Colors.white}} label="Días" icon={<CompareArrowsIcon />} />
        <BottomNavigationAction sx={{color: Colors.white}} label="Precio" icon={<ArrowUpwardIcon />} />
        
      </BottomNavigation> */}

        {/* <TitleHeader2>Duración: 15 días</TitleHeader2> */}
        {/* <Actions matches={matches} /> */}
      </Stack>
    </div>
  );
}
