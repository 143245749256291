import * as React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";
import { Colors } from "../../../../../styles/theme";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  tableCellClasses,
} from "@mui/material";
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TableContainer,
} from "@mui/material";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { styled } from "@mui/material/styles";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: Colors.blue_ibero,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function Condiciones(props) {
  //console.log(props.condicionesData);
  return (
    <Box
      component="section"
      sx={{ display: "flex", bgcolor: "secondary.light", overflow: "hidden" }}
    >
      <Container
        sx={{
          mt: 10,
          mb: 8,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          src="/Images/productCurvyLines.png"
          alt="curvy lines"
          sx={{
            pointerEvents: "none",
            position: "absolute",
            top: -180,
            opacity: 0.7,
          }}
        />
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h4"
              marked="center"
              component="h2"
              sx={{ mb: 3 }}
              align="center"
            >
              Condiciones de venta
            </Typography>
            <List dense={true} key={"L-IT"}>
              {props.condicionesData
                .filter((item) => item.DESCRIPCION === "condiciones")
                .map((item, i) => (
                  <ListItem key={i}>
                    <ListItemIcon>
                      <CheckCircleOutlinedIcon
                        key={i}
                        sx={{ color: Colors.orange_ibero }}
                      />
                    </ListItemIcon>
                    <ListItemText key={i} primary={item.INCLUYE} />
                  </ListItem>
                ))}
            </List>
            <Typography variant="h5" component="h2" align="center">
              Plazos de pagos
            </Typography>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 250 }} aria-label="customized table">
                {/* <TableHead>
                <TableRow>
                  <StyledTableCell align="center">
                    Doble
                  </StyledTableCell>
                  <StyledTableCell align="center">Triple</StyledTableCell>
                  <StyledTableCell align="center">Niños 3 a 15 años</StyledTableCell>
                  <StyledTableCell align="center">
                    Sencilla
                  </StyledTableCell>                
                </TableRow>
              </TableHead> */}
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row" align="center">
                      1er. Pago
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Al reservar con copia del pasaporte
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      $ 5.000.000 (No reembolsables)
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row" align="center">
                      2do. Pago
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      70 días antes de iniciar el viaje
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Completar el 70%
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row" align="center">
                      3er. Pago
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      50 días antes de iniciar el viaje
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Completar el 100%
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography
              variant="h4"
              marked="center"
              component="h2"
              align="center"
              sx={{ mb: 3 }}
            >
              Gastos de cancelación
            </Typography>
            {/* <Typography variant="h5" component="h2" align="center">
              Tiquete aéreo
            </Typography>
            <List dense={true} key={"L-IT"}>
              {props.condicionesData
                .filter((item) => item.DESCRIPCION === "cancelacionaereo")
                .map((item, i) => (
                  <ListItem key={i}>
                    <ListItemIcon>
                      <CheckCircleOutlinedIcon
                        key={i}
                        sx={{ color: Colors.orange_ibero }}
                      />
                    </ListItemIcon>
                    <ListItemText key={i} primary={item.INCLUYE} />
                  </ListItem>
                ))}
            </List>
            <Typography variant="h5" component="h2" align="center">
              Porción terrestre
            </Typography>
            <List dense={true} key={"L-IT"}>
              {props.condicionesData
                .filter((item) => item.DESCRIPCION === "cancelacionterrestre")
                .map((item, i) => (
                  <ListItem key={i}>
                    <ListItemIcon>
                      <CheckCircleOutlinedIcon
                        key={i}
                        sx={{ color: Colors.orange_ibero }}
                      />
                    </ListItemIcon>
                    <ListItemText key={i} primary={item.INCLUYE} />
                  </ListItem>
                ))}
            </List> */}
            <List dense={true} key={"L-IT"}>
              {props.condicionesData
                .filter((item) => item.DESCRIPCION === "cancelacion")
                .map((item, i) => (
                  <ListItem key={i}>
                    <ListItemIcon>
                      <CheckCircleOutlinedIcon
                        key={i}
                        sx={{ color: Colors.orange_ibero }}
                      />
                    </ListItemIcon>
                    <ListItemText key={i} primary={item.INCLUYE} />
                  </ListItem>
                ))}
            </List>
          </Grid>
        </Grid>

        <Grid container spacing={4} sx={{ marginTop: "25px" }}>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h4"
              marked="center"
              component="h2"
              sx={{ mb: 3 }}
              align="center"
            >
              Requisitos de viaje
            </Typography>
            <List dense={true} key={"L-IT"}>
              {props.condicionesData
                .filter((item) => item.DESCRIPCION === "requisitos")
                .map((item, i) => (
                  <ListItem key={i}>
                    <ListItemIcon>
                      <CheckCircleOutlinedIcon
                        key={i}
                        sx={{ color: Colors.orange_ibero }}
                      />
                    </ListItemIcon>
                    <ListItemText key={i} primary={item.INCLUYE} />
                  </ListItem>
                ))}
            </List>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography
              variant="h4"
              marked="center"
              component="h2"
              align="center"
              sx={{ mb: 3 }}
            >
              Formas de pago
            </Typography>
            <List dense={true} key={"L-IT"}>
              {props.condicionesData
                .filter((item) => item.DESCRIPCION === "pagos")
                .map((item, i) => (
                  <ListItem key={i}>
                    <ListItemIcon>
                      <CheckCircleOutlinedIcon
                        key={i}
                        sx={{ color: Colors.orange_ibero }}
                      />
                    </ListItemIcon>
                    <ListItemText key={i} primary={item.INCLUYE} />
                  </ListItem>
                ))}
            </List>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Condiciones;
