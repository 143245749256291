import {
  Button,
  Grid,
  Paper,
  Box,
  ThemeProvider,
  Typography,
  Autocomplete,
  TextField,
  FormControl,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Divider,
  Fab,
  InputLabel,
  Input,
} from "@mui/material";
import "../../App.css";
import { Container, Stack } from "@mui/material";
import theme from "../../styles/theme";
import Appbar from "../appbar";
import { Fragment, useEffect, useState } from "react";
import Footer from "../footer";
import { UIProvider } from "../../context/ui";
import AppDrawer from "../drawer";
import { Colors } from "../../styles/theme";
import { AppTitleBlue, AppTitleOrange } from "../../styles/app";
import styled from "@emotion/styled";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { es } from "date-fns/locale";
import { Check } from "@mui/icons-material";
import QuestionMarkOutlinedIcon from "@mui/icons-material/QuestionMarkOutlined";

const StepLabelStyled = styled(StepLabel)(({ theme }) => ({
  ".MuiStepLabel-label": {
    color: Colors.blue_ibero,
    fontSize: "24px",
  },
  "& .MuiStepLabel-active": {
    color: "red",
    fontSize: "24px",
  },
  "& .Mui-completed": {
    color: "gray",
    fontSize: "24px",
  },
}));

function Circuitsdetaildesktop(props) {
  const [paisesSelect, setPaisesSelect] = useState([]);
  const [ciudadesSelect, setCiudadesSelect] = useState([]);
  const [fecha, setFecha] = useState(new Date());
  const [fechaDesde, setFechaDesde] = useState(new Date());
  const [fechaHasta, setFechaHasta] = useState(new Date());
  const [activeStep, setActiveStep] = useState(0);
  const [diasSelect, setDiasSelect] = useState("Todos");
  const [flagBuscador, setFlagBuscador] = useState("No");

  useEffect(() => {
    document.title = "Colreservas - Asistente Virtual";
    setFechaHasta(
      new Date(
        fechaDesde.getFullYear() + 1,
        fechaDesde.getMonth(),
        fechaDesde.getDate()
      )
    );
  }, []);

  const applyFilter = async () => {
    var paises = "";
    paisesSelect.forEach(function (pais, key) {
      if (paises === "") {
        paises = pais.PAIS;
      } else {
        paises = paises + "," + pais.PAIS;
      }
    });

    var ciudades = "";
    ciudadesSelect.forEach(function (ciudad, key) {
      if (ciudades === "") {
        ciudades = ciudad.CIUDAD;
      } else {
        ciudades = ciudades + "," + ciudad.CIUDAD;
      }
    });

    var feccir =
      fecha.getFullYear() +
      "-" +
      (fecha.getMonth() + 1).toString().padStart(2, "0");
    paises = paises === "" ? "*" : paises;
    ciudades = ciudades === "" ? "*" : ciudades;
    window.open(
      "#/results/Circuitos/*/" + feccir + "/" + paises + "/" + ciudades + "/" + diasSelect.code,
      "_self"
    );
  };

  function CustomStepIcon({ active, completed }) {
    return (
      <div
        style={{
          width: 40,
          height: 40,
          borderRadius: "50%",
          backgroundColor: active
            ? Colors.orange_ibero
            : completed
            ? "green"
            : Colors.blue_ibero,
          color: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {completed ? <Check /> : <QuestionMarkOutlinedIcon />}
      </div>
    );
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleBuscadorCiudad = () => {
    clearArrayPaises();
    setFlagBuscador("Ciudad");
    setActiveStep(0);
    jump("AS-ciudad");
  };


  const diasAutocomplete = (values) => {
    setDiasSelect(values);
  };

  const clearArrayPaises = () => {
    setPaisesSelect([]);
  };

  const handleBuscadorPais = () => {
    clearArrayCiudades();
    setFlagBuscador("Pais");
    setActiveStep(0);
    jump("AS-ciudad");
  };

  const clearArrayCiudades = () => {
    setCiudadesSelect([]);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const jump = (h) => {
    var top = document.getElementById(h).offsetTop; //Getting Y of target element
    window.scrollTo(0, top);
  };

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: "center",
    color: theme.palette.text.secondary,
    sx: { padding: "0px" },
  }));

  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="xl"
        sx={{
          background: "#fff",
          padding: "0px !important",
        }}
      >
        <Stack>
          <UIProvider>
            <Appbar />
            <Stack>
              <Stack
                direction={"column"}
                spacing={2}
                justifyContent="space-between"
                sx={{
                  background: "#fff",

                  padding: "20px",

                  with: "100%",
                  m: "10px",
                }}
              >
                {/*   <Stack alignItems="center"  sx={{
                      background: "#fff",
                       borderColor: "green",
                       borderRadius: "15px",
                       borderStyle: "solid",
                       borderWidth: "1px",
                      padding: "15px",
                     
                      with: "100%",
                      m: "15px",
                    }}> */}

                {/* <br></br>
                  <br></br>
                  <br></br>
                  <AppTitleOrange
                    variant={!props.matches ? "h3" : "h4"}
                    sx={{ textAlign: "center" }}
                  >
                    Asistente Virtual
                  </AppTitleOrange>
                  <br></br>
                  <br></br> */}
                {/*      <Stack alignItems="center"
                    sx={{
                      background: "#fff",
                       borderColor: Colors.blue_ibero,
                       borderRadius: "15px",
                       borderStyle: "solid",
                       borderWidth: "1px",
                      padding: "15px",
                     
                      with: "100%",
                      m: "15px",
                    }}
                  > */}
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  sx={{
                    color: Colors.blue_ibero,
                    fontWeight: "Medium",
                    width: "100%",

                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Seleccione su búsqueda por:
                </Typography>
                <Grid container spacing={1} sx={{ marginBottom: "35px" }}>
                  <Grid item xs={6} key={1}>
                    <ThemeProvider theme={theme}>
                      <Item key={1} elevation={6} sx={{ padding: "5px" }}>
                        <img
                          src={`/images/MapaMundiPaises.png`}
                          loading="lazy"
                          width={props.matches ? "130px" : "150px"}
                          alt="MapaMundi"
                        ></img>
                        <div style={{ minHeight: "80px" }}>
                          <p>
                            Realizar búsqueda de viajes por el mundo indicando
                            los paises
                          </p>
                        </div>
                        <br></br>
                        <Fab
                          variant="extended"
                          aria-label="delete"
                          size="small"
                          sx={{
                            bgcolor: Colors.orange_ibero,
                            color: Colors.white,
                            textTransform: "none",
                            fontSize: "14px",
                            marginBottom: "15px",
                            width: props.matches ? "130px" : "150px",
                            "&:hover": {
                              backgroundColor: Colors.blue_ibero,
                            },
                          }}
                          onClick={handleBuscadorPais}
                        >
                          {/* <SearchOutlinedIcon
                                fontSize="40px"
                                sx={{ color: Colors.white, marginRight: "5px" }}
                              /> */}
                          Paises
                        </Fab>
                        <br></br>
                      </Item>
                    </ThemeProvider>
                  </Grid>
                  <Grid item xs={6} key={2}>
                    <ThemeProvider theme={theme}>
                      <Item
                        key={3}
                        elevation={6}
                        sx={{ padding: "5px", marginRight: "0px" }}
                      >
                        <img
                          src={`/images/MapaMundi.png`}
                          loading="lazy"
                          width={props.matches ? "130px" : "150px"}
                          alt="MapaMundi"
                        ></img>
                        <div style={{ minHeight: "80px" }}>
                          <p>
                            Realizar búsqueda de viajes por el mundo indicando
                            las ciudades
                          </p>
                        </div>
                        <br></br>
                        <Fab
                          variant="extended"
                          aria-label="delete"
                          size="small"
                          sx={{
                            bgcolor: Colors.orange_ibero,
                            color: Colors.white,
                            textTransform: "none",
                            fontSize: "14px",
                            marginBottom: "15px",
                            width: props.matches ? "130px" : "150px",
                            "&:hover": {
                              backgroundColor: Colors.blue_ibero,
                            },
                          }}
                          onClick={handleBuscadorCiudad}
                        >
                          {/* <SearchOutlinedIcon
                                fontSize="40px"
                                sx={{ color: Colors.white, marginRight: "5px" }}
                              /> */}
                          Ciudades
                        </Fab>
                        <br></br>
                      </Item>
                    </ThemeProvider>
                  </Grid>
                </Grid>

                {flagBuscador !== "No" ? (
                  <>
                    <Divider></Divider>
                    <div id="AS-ciudad"></div>
                    <Grid container spacing={1} sx={{ marginBottom: "35px" }}>
                      <Grid item xs={12} key={1}>
                        <Item key={1} elevation={0} sx={{ padding: "10px" }}>
                          <Stepper
                            activeStep={activeStep}
                            orientation="vertical"
                          >
                            <Step key={0}>
                              <StepLabelStyled
                                StepIconComponent={CustomStepIcon}
                              >
                                {flagBuscador === "Pais"
                                  ? "Selección los paises que desea visitar"
                                  : "Selección las ciudades que desea visitar"}
                              </StepLabelStyled>
                              <StepContent>
                                <FormControl
                                  fullWidth
                                  sx={{ m: 2 }}
                                  variant="standard"
                                >
                                  <Stack
                                    direction={"column"}
                                    spacing={2}
                                    justifyContent="space-between"
                                  >
                                    {/* <Typography>Seleccione los paises</Typography> */}
                                    {flagBuscador === "Pais" ? (
                                      <Autocomplete
                                        value={paisesSelect}
                                        id="country-select-demo"
                                        sx={{ bgcolor: "white" }}
                                        options={props.paises}
                                        autoHighlight
                                        multiple={true}
                                        getOptionLabel={(option) => option.PAIS}
                                        onChange={(event, value) =>
                                          setPaisesSelect(value)
                                        }
                                        renderOption={(props, option) => (
                                          <Box
                                            component="li"
                                            sx={{
                                              "& > img": {
                                                mr: 2,
                                                flexShrink: 0,
                                              },
                                            }}
                                            {...props}
                                          >
                                            <img
                                              loading="lazy"
                                              width="20"
                                              src={`https://flagcdn.com/w20/${option.CODIGO_IATA.toLowerCase()}.png`}
                                              srcSet={`https://flagcdn.com/w40/${option.CODIGO_IATA.toLowerCase()}.png 2x`}
                                              alt=""
                                            />
                                            {option.PAIS}
                                          </Box>
                                        )}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="Seleccione el país"
                                            inputProps={{
                                              ...params.inputProps,
                                            }}
                                          />
                                        )}
                                      />
                                    ) : (
                                      <Autocomplete
                                        value={ciudadesSelect}
                                        id="city-select-demo"
                                        sx={{ bgcolor: "white" }}
                                        options={props.ciudades}
                                        autoHighlight
                                        multiple={true}
                                        getOptionLabel={(option) =>
                                          option.CIUDAD
                                        }
                                        onChange={(event, value) =>
                                          setCiudadesSelect(value)
                                        }
                                        renderOption={(props, option) => (
                                          <Box
                                            component="li"
                                            sx={{
                                              "& > img": {
                                                mr: 2,
                                                flexShrink: 0,
                                              },
                                            }}
                                            {...props}
                                          >
                                            {option.CIUDAD}
                                          </Box>
                                        )}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="Seleccione ciudad"
                                            inputProps={{
                                              ...params.inputProps,
                                              autoComplete: "new-password", // disable autocomplete and autofill
                                            }}
                                          />
                                        )}
                                      />
                                    )}
                                  </Stack>
                                </FormControl>
                                <br></br>
                                <Divider />
                                <br></br>
                                <Box
                                  sx={{
                                    mb: 2,
                                    textAlign: "center",
                                    paddingBottom: "10px",
                                  }}
                                >
                                  <div>
                                    <Button
                                      variant="contained"
                                      onClick={handleNext}
                                      sx={{
                                        mt: 1,
                                        mr: 1,
                                        backgroundColor: Colors.orange_ibero,
                                      }}
                                    >
                                      {"Continuar"}
                                    </Button>
                                    <Button
                                      disabled={true}
                                      onClick={handleBack}
                                      sx={{ mt: 1, mr: 1 }}
                                    >
                                      Regresar
                                    </Button>
                                  </div>
                                </Box>
                              </StepContent>
                            </Step>

                             <Step key={3}>
                              <StepLabelStyled
                                StepIconComponent={CustomStepIcon}
                              >
                                Días a visitar
                              </StepLabelStyled>
                              <StepContent>
                                <FormControl fullWidth variant="standard">
                                  <Stack
                                    direction={"column"}
                                    spacing={2}
                                    justifyContent="space-between"
                                  >
                                     <Autocomplete
              id="dias-select-demo"
              value={diasSelect}
              sx={{ bgcolor: "white" }}
              options={dias}
              // multiple={true}
              // autoHighlight
              // getOptionLabel={(option) => option.label}
              onChange={(event, value) => diasAutocomplete(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Días hasta"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password",
                  }}
                />
              )}
            />
                                  </Stack>
                                </FormControl>
                                <br></br>
                                <Divider />
                                <br></br>
                                <Box
                                  sx={{
                                    mb: 2,
                                    textAlign: "center",
                                    paddingBottom: "10px",
                                  }}
                                >
                                  <div>
                                    <Button
                                      variant="contained"
                                      onClick={handleNext}
                                      sx={{ mt: 1, mr: 1, backgroundColor: Colors.orange_ibero }}
                                    >
                                      {"Continuar"}
                                    </Button>
                                    <Button
                                      disabled={false}
                                      onClick={handleBack}
                                      sx={{ mt: 1, mr: 1 }}
                                    >
                                      Regresar
                                    </Button>
                                  </div>
                                </Box>
                              </StepContent>
                            </Step> 

                            <Step key={3}>
                              <StepLabelStyled
                                StepIconComponent={CustomStepIcon}
                              >
                                Fecha estimada de viaje
                              </StepLabelStyled>
                              <StepContent>
                                <FormControl variant="standard">
                                  <Stack
                                    direction={"column"}
                                    spacing={2}
                                    justifyContent="space-between"
                                  >
                                    <FormControl
                                      fullWidth
                                      sx={{ m: 1 }}
                                      variant="standard"
                                    >
                                      <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                        adapterLocale={es}
                                      >
                                        <DatePicker
                                          openTo="month"
                                          variant="inline"
                                          views={["year", "month"]}
                                          size="small"
                                          label="Mes y año del viaje"
                                          minDate={fechaDesde}
                                          maxDate={fechaHasta}
                                          value={fecha}
                                          onChange={(newValue) => {
                                            setFecha(newValue);
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              helperText={null}
                                              /*      sx={{
                      bgcolor: "white",
                    }} */
                                              sx={{
                                                bgcolor: "white",
                                                width: "100%",
                                                "& label": {
                                                  fontSize: "20px",
                                                  fontWeight: "bold",
                                                },
                                              }}
                                            />
                                          )}
                                        />
                                      </LocalizationProvider>
                                    </FormControl>
                                  </Stack>
                                </FormControl>
                                <br></br>
                                <Divider />
                                <br></br>
                                <Box
                                  sx={{
                                    mb: 2,
                                    textAlign: "center",
                                    paddingBottom: "10px",
                                  }}
                                >
                                  <Box>
                                    <Button
                                      variant="contained"
                                      onClick={applyFilter}
                                      sx={{
                                        mt: 1,
                                        mr: 1,
                                        backgroundColor: Colors.orange_ibero,
                                      }}
                                    >
                                      {"Buscar circuitos"}
                                    </Button>
                                    <Button
                                      disabled={false}
                                      onClick={handleBack}
                                      sx={{ mt: 1, mr: 1 }}
                                    >
                                      Regresar
                                    </Button>
                                  </Box>
                                </Box>
                              </StepContent>
                            </Step>
                          </Stepper>
                        </Item>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
              </Stack>
              {/* </Stack> */}
              {/* </Stack> */}
            </Stack>
            <AppDrawer />
          </UIProvider>
        </Stack>
        <Footer />
      </Container>
    </ThemeProvider>
  );
}

const dias = [
  { code: "Todos", label: "Todos" },
  { code: "2", label: "2" },
  { code: "3", label: "3" },
  { code: "4", label: "4" },
  { code: "5", label: "5" },
  { code: "6", label: "6" },
  { code: "7", label: "7" },
  { code: "8", label: "8" },
  { code: "9", label: "9" },
  { code: "10", label: "10" },
  { code: "11", label: "11" },
  { code: "12", label: "12" },
  { code: "13", label: "13" },
  { code: "14", label: "14" },
  { code: "15", label: "15" },
  { code: "16", label: "16" },
  { code: "17", label: "17" },
  { code: "18", label: "18" },
  { code: "19", label: "19" },
  { code: "20", label: "20" },
  { code: "21", label: "21" },
  { code: "22", label: "22" },
  { code: "23", label: "23" },
  { code: "24", label: "24" },
  { code: "25", label: "25" },
  { code: "26", label: "26" },
  { code: "27", label: "27" },
  { code: "28", label: "28" },
  { code: "29", label: "29" },
  { code: "30", label: "30" },
  { code: "31", label: "31" },
  { code: "36", label: "36" },
];

export default Circuitsdetaildesktop;
