import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { Colors } from "../../../styles/theme";
import { Link } from "react-router-dom";

export default function Circuits(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const renderCircuits = props.folletos.map((circuit) => (
    <Grid
      item
      key={circuit.id}
      xs={12}
      sm={4}
      md={4}
      display="flex"
      flexDirection={"column"}
      alignItems="center"
    >
      <Card
        sx={{
          display: "flex",
          width: "100%",
          padding: "7px",
          bgcolor: Colors.blue_light,
          "&:hover": {           
              boxShadow: 15,
          }
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            minHeight: "255px",
          }}
        >
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Link
              style={{ textDecoration: "none" }}
              to={"/results/Catalogo/" + circuit.ColorViaje}
            >
              <Typography
                component="div"
                variant="h5"
                sx={{ color: Colors.blue_ibero }}
              >
                {circuit.DescripcionFolleto}
              </Typography>

              <Typography variant="body" color="text.secondary" component="div">
                {circuit.Observacion}
              </Typography>
            </Link>
            <br></br>
            <a href={circuit.UrlPDF} rel="noreferrer" target={"_blank"}>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
              >
                Ver PDF
              </Typography>
            </a>
          </CardContent>
        </Box>
        <Link
          style={{ textDecoration: "none" }}
          to={"/results/Catalogo/" + circuit.ColorViaje}
        >
          <Avatar elevation={3} src={circuit.Imagen} sx={{ width: 170, height: 170, boxShadow: 3, marginTop: "25px" }} />
        </Link>
        {/*  <CardMedia
        component="img"
        sx={{ width: 151 }}
        image="https://iberolunatravel.online/wse///images/imagessmall/Amsterdam_20210915133909.jpg"
        alt="Live from space album cover"
      /> */}
      </Card>

      {/* <SingleCircuitDesktop key={circuit.id} circuit={circuit} matches={matches}/> */}
    </Grid>
  ));
  return (
    <>
      <Grid
        container
        spacing={{ xs: 1, md: 3 }}
        justifyContent="center"
        sx={{ margin: `20px 4px 10px 4px` }}
        columns={{ xs: 3, sm: 8, md: 12 }}
      >
        {renderCircuits}
      </Grid>
    </>
  );
}
