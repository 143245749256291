import * as React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Button from "../components/Button";
import Typography from "../components/Typography";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Colors } from "../../../../../styles/theme";

const item = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  px: 5,
  minwidth: "180px",
};

const number = {
  fontSize: 32,
  fontFamily: "default",
  color: "secondary.main",
  fontWeight: "medium",
};

const image = {
  height: 25,
  my: 1.5,
  color: Colors.blue_ibero,
};

function ProductHowItWorks() {
  return (
    <Box
      component="section"
      sx={{ display: "flex", bgcolor: "secondary.light", overflow: "hidden" }}
    >
      <Container
        sx={{
          mt: 10,
          mb: 15,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          src="/Images/productCurvyLines.png"
          alt="curvy lines"
          sx={{
            pointerEvents: "none",
            position: "absolute",
            top: -180,
            opacity: 0.7,
          }}
        />
        <Typography variant="h4" marked="center" component="h2"  align="center" sx={{ mb: 8 }}>
          Fechas de salidas
        </Typography>      
        <div>
          <Grid container spacing={4}>            
            <Grid item xs={12} md={12}>
              <Box sx={item}>
                <Box sx={number}>Julio 2024</Box>
                <Box sx={image}>
                  <CalendarMonthIcon></CalendarMonthIcon>
                </Box>
                <Typography variant="h5" align="center">
                  13 julio
                </Typography>
                <Typography variant="h5" align="center">
                  20 julio
                </Typography>
              </Box>
            </Grid>
            
          </Grid>
        </div>
        {/* <Button
          color="secondary"
          size="large"
          variant="contained"
          component="a"
          href="/premium-themes/onepirate/sign-up/"
          sx={{ mt: 8 }}
        >
          Get started
        </Button> */}
      </Container>
    </Box>
  );
}

export default ProductHowItWorks;
