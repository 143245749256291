import * as React from "react";
import Box from "@mui/material/Box";
import { Colors } from "../../../styles/theme";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Slider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import es from "date-fns/locale/es";

export default function SelectedListItem(props) {
  const [paisesSelect, setPaisesSelect] = React.useState(
    props.paisesCriterio.length !== 0 ? props.paisesCriterio : []
  );
  const [ciudadesSelect, setCiudadesSelect] = React.useState(
    props.ciudadesCriterio !== undefined && props.ciudadesCriterio !== "*"
      ? props.ciudadesCriterio
      : []
  );
  const [inicioSelect, setInicioSelect] = React.useState([]);
  const [finSelect, setFinSelect] = React.useState([]);
  const [habitacionSelect, setHabitacionSelect] = React.useState([]);
  const [diasSelect, setDiasSelect] = React.useState(props.dias);
  const [serieSelect, setSerieSelect] = React.useState([]);
  const [flagAccordion, setFlagAccordion] = React.useState(true);
  const [flagPrice, setFlagPrice] = React.useState(true);
  const [disableFolleto, setDisableFolleto] = React.useState(false);
  const [valueSlider, setValueSlider] = React.useState([
    props.precioDesde,
    props.precioHasta,
  ]);
  const [fecha, setFecha] = React.useState(
    props.fecha !== undefined ? new Date(props.fecha + "-15") : new Date()
  );
  const [fechaDesde, setFechaDesde] = React.useState(new Date());
  const [fechaHasta, setFechaHasta] = React.useState(new Date());
  const [nombreCircuito, setNombreCircuito] = React.useState("");
  // console.log(serieSelect);
  // console.log("Filtro");
  // console.log(props);

  /*   React.useEffect(() => {
    if (props.paisesCriterio !== undefined && props.paisesCriterio !== "*") {
      const array = [];
      props.paisesCriterio.split(",").forEach(function (pais) {
        const elementToAdd = props.paisesData.find(
          (element) => element.PAIS === pais
        );

        array.push(elementToAdd);
      });
      console.log(array);
      setPaisesSelect(array);
    }
    }, []); */

  React.useEffect(() => {
    // 👇️ only runs once
    console.log("XXXXXXXXXXX");
    console.log(props.dias);
    console.log(diasSelect);
    setFechaHasta(
      new Date(
        fechaDesde.getFullYear() + 1,
        fechaDesde.getMonth(),
        fechaDesde.getDate()
      )
    );
    if (props.tipo === "Destino") {
      setDisableFolleto(true);
      applyFilter();
    } else {
      let obj = props.folletosData.find(
        (data) => data.ColorViaje === props.codfolleto
      );

      // console.log(props.codfolleto);
      // console.log(obj);
      if (obj !== undefined) {
        setSerieSelect((oldArray) => [...oldArray, obj]);
        setDisableFolleto(true);
        // console.log("applyFilter()");

        applyFilter();
      } else {
        if (props.fecha === undefined || props.tipo === "Ofertas") {
          setPaisesSelect([]);
          setCiudadesSelect([]);
          setFecha(new Date());
          setInicioSelect([]);
          setFinSelect([]);
          setHabitacionSelect([]);
          setDiasSelect("Todos");
          setSerieSelect([]);
          setFlagAccordion(true);
          setFlagPrice(true);
          setDisableFolleto(false);
          setValueSlider([props.precioDesde, props.precioHasta]);
          setNombreCircuito("");
          applyFilter();

          /*   if (props.paisesData.length !== 0) {
            if (
              props.paisesCriterio !== undefined &&
              props.paisesCriterio !== "*"
            ) {
              const array = [];
              props.paisesCriterio.split(",").forEach(function (pais, key) {
                const elementToAdd = props.paisesData.find(
                  (element) => element.PAIS === pais
                );

                array.push(elementToAdd);
              });
              console.log(array);
              setPaisesSelect(array);
            }

     
          } */
        } else {
          applyFilter();
        }
      }
      /* else {
          if (props.paisesData.length !== 0) {
            if (
              props.paisesCriterio !== undefined &&
              props.paisesCriterio !== "*"
            ) {
              const array = [];
              props.paisesCriterio.split(",").forEach(function (pais, key) {
                const elementToAdd = props.paisesData.find(
                  (element) => element.PAIS === pais
                );

                array.push(elementToAdd);
              });
              console.log(array);
              setPaisesSelect(array);
            }

            applyFilter();
          }
        } */
    }
  }, [props.folletosData, props.tipo]);

  const Item = styled(Paper)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    backgroundColor: "transparent",
    ...theme.typography.body2,
    padding: theme.spacing(0),
    textAlign: "center",
    color: Colors.blue_ibero,
    fontWeight: "bold",
  }));

  const applyFilter = () => {
    var paises = "";

    /*     if (props.paisesCriterio !== undefined && props.paisesCriterio !== "*") {
      const array = [];
      props.paisesCriterio.split(",").forEach(function (pais, key) {
        const elementToAdd = props.paisesData.find(
          (element) => element.PAIS === pais
        );

        array.push(elementToAdd);
      });
      console.log(array);
      setPaisesSelect(array);
      paises = props.paisesCriterio;
    } else {
      paisesSelect.forEach(function (pais, key) {
        if (paises === "") {
          paises = pais.PAIS;
        } else {
          paises = paises + "," + pais.PAIS;
        }
      });
    } */

    paisesSelect.forEach(function (pais, key) {
      if (paises === "") {
        paises = pais.PAIS;
      } else {
        paises = paises + "," + pais.PAIS;
      }
    });

    /*     paisesSelect.forEach(function (pais, key) {
      if (paises === "") {
        paises = pais.PAIS;
      } else {
        paises = paises + "," + pais.PAIS;
      }
    }); */

    var ciudades = "";
    /*     if (
      props.ciudadesCriterio !== undefined &&
      props.ciudadesCriterio !== "*"
    ) {
      const array = [];
      props.ciudadesCriterio.split(",").forEach(function (ciudad, key) {
        const elementToAdd = props.ciudadesData.find(
          (element) => element.CIUDAD === ciudad
        );
        array.push(elementToAdd);
      });
      setCiudadesSelect(array);
      ciudades = props.ciudadesCriterio;
    } else {
      ciudadesSelect.forEach(function (ciudad, key) {
        if (ciudades === "") {
          ciudades = ciudad.CIUDAD;
        } else {
          ciudades = ciudades + "," + ciudad.CIUDAD;
        }
      });
    } */

    ciudadesSelect.forEach(function (ciudad, key) {
      if (ciudades === "") {
        ciudades = ciudad.CIUDAD;
      } else {
        ciudades = ciudades + "," + ciudad.CIUDAD;
      }
    });

    /*     ciudadesSelect.forEach(function (ciudad, key) {
      if (ciudades === "") {
        ciudades = ciudad.CIUDAD;
      } else {
        ciudades = ciudades + "," + ciudad.CIUDAD;
      }
    }); */

    var inicio = "";
    inicioSelect.forEach(function (ciudad, key) {
      if (inicio === "") {
        inicio = ciudad.CIUDAD;
      } else {
        inicio = inicio + "," + ciudad.CIUDAD;
      }
    });

    var fin = "";
    finSelect.forEach(function (ciudad, key) {
      if (fin === "") {
        fin = ciudad.CIUDAD;
      } else {
        fin = fin + "," + ciudad.CIUDAD;
      }
    });

    var habitacion = "";
    habitacionSelect.forEach(function (hab, key) {
      if (habitacion === "") {
        habitacion = hab.label;
      } else {
        habitacion = habitacion + "," + hab.label;
      }
    });

    var serie = "";
    serieSelect.forEach(function (ser, key) {
      if (serie === "") {
        serie = ser.ColorViaje;
      } else {
        serie = serie + "," + ser.ColorViaje;
      }
    });

    var feccir = "";
    /*   if (props.fecha !== undefined) {
      feccir = props.fecha;
      const dateObject = new Date(props.fecha + "-15");
      setFecha(dateObject);
    } else {
      feccir = fecha.getFullYear() + "-" + (fecha.getMonth() + 1);
    } */

    feccir = fecha.getFullYear() + "-" + (fecha.getMonth() + 1);

    //var feccir = fecha.getFullYear() + "-" + (fecha.getMonth() + 1);
    var precioRango;
    if (
      valueSlider[0] != props.precioDesde ||
      valueSlider[1] != props.precioHasta
    ) {
      precioRango = valueSlider[0] + "-" + valueSlider[1];
    } else {
      precioRango = "0-0";
    }

    setFlagAccordion(false);
    console.log("codfolleto");
    console.log(diasSelect);
    props.onClick(
      feccir,
      ciudades,
      paises,
      inicio,
      fin,
      habitacion,
      precioRango,
      props.codfolleto === "*" ? serie : props.codfolleto,
      "PrecioASC",
      1,
      nombreCircuito,
      diasSelect.code === "Todos" ? "0" : diasSelect.code
    );
  };

  const inicioAutocomplete = (values) => {
    setInicioSelect(values);
  };
  const finAutocomplete = (values) => {
    setFinSelect(values);
  };
  const habitacionAutocomplete = (values) => {
    setHabitacionSelect(values);
  };

  const diasAutocomplete = (values) => {
    console.log(values);
    if (values === null) {
      setDiasSelect("Todos");
     } else {
      setDiasSelect(values);
     }
  };

  const serieAutocomplete = (values) => {
    setSerieSelect(values);

    // console.log("Serie");
    // console.log(serieSelect);
  };

  const handleNombreCircuito = (event) => {
    setNombreCircuito(event.target.value);
  };

  React.useEffect(() => {
    setValueSlider([props.precioDesde, props.precioHasta]);
  }, [props.precioDesde]);

  React.useEffect(() => {
    setValueSlider([props.precioDesde, props.precioHasta]);
  }, [props.precioHasta]);

  const handleChangeSlider = (event, newValue) => {
    if (newValue[0] != props.precioDesde || newValue[1] != props.precioHasta) {
      setFlagPrice(true);
    }
    setValueSlider(newValue);
    /*     setPrecioDesde(newValue[0])
        setPrecioHasta(newValue[1]) */
  };

  const handleAccordion = (panel) => () => {
    setFlagAccordion(!flagAccordion);
  };

  const MultiFiltro = () => {
    let content = [];
    content.push(
      <Box
        flex={1}
        sx={{ minWidth: "183px", bgcolor: Colors.blue_light, p: "15px" }}
      >
        <Stack spacing={2} justifyContent="space-between">
          {!props.matches ? (
            <Item color={Colors.orange_ibero}>
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                sx={{ color: Colors.orange_ibero, fontWeight: "bold" }}
              >
                MultiFiltros{" "}
                {/* {props.tipo === "Ofertas" ? " de Ofertas" : " de Circuitos"} */}
              </Typography>
            </Item>
          ) : (
            <></>
          )}

          <Item elevation={0}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={es}
            >
              <DatePicker
                openTo="month"
                views={["year", "month"]}
                label="Mes y año del viaje"
                minDate={fechaDesde}
                maxDate={fechaHasta}
                value={fecha}
                onChange={(newValue) => {
                  setFecha(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    helperText={null}
                    sx={{
                      bgcolor: "white",
                      width: "100%",
                      "& label": { fontSize: "20px", fontWeight: "bold" },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Item>

          <Item elevation={0}>
            {/* Seleccione País */}
            <Autocomplete
              value={paisesSelect}
              id="country-select-demo"
              // sx={{ width: 100 }}
              sx={{ bgcolor: "white" }}
              //options={countries}
              options={props.paisesData}
              autoHighlight
              multiple={true}
              getOptionLabel={(option) => option.PAIS}
              onChange={(event, value) => setPaisesSelect(value)}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${option.CODIGO_IATA.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option.CODIGO_IATA.toLowerCase()}.png 2x`}
                    alt=""
                  />
                  {option.PAIS}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Seleccione el país"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
          </Item>

          <Item elevation={0}>
            {/* Seleccione Ciudad */}
            <Autocomplete
              value={ciudadesSelect}
              id="city-select-demo"
              // sx={{ width: 100 }}
              sx={{ bgcolor: "white" }}
              options={props.ciudadesData}
              autoHighlight
              multiple={true}
              getOptionLabel={(option) => option.CIUDAD}
              onChange={(event, value) => setCiudadesSelect(value)}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option.CIUDAD}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Seleccione ciudad"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </Item>

          <Item elevation={0}>
            {/* Ciudad de inicio */}
            <Autocomplete
              value={inicioSelect}
              id="inicio-select-demo"
              // sx={{ width: 100 }}
              sx={{ bgcolor: "white" }}
              options={props.ciudadesData}
              multiple={true}
              autoHighlight
              getOptionLabel={(option) => option.CIUDAD}
              onChange={(event, value) => inicioAutocomplete(value)}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {/*  <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          /> */}
                  {option.CIUDAD}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Ciudad de Inicio"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </Item>

          <Item elevation={0}>
            {/* Ciudad de finalización */}
            <Autocomplete
              id="fin-select-demo"
              value={finSelect}
              // sx={{ width: 100 }}
              sx={{ bgcolor: "white" }}
              options={props.ciudadesData}
              multiple={true}
              autoHighlight
              getOptionLabel={(option) => option.CIUDAD}
              onChange={(event, value) => finAutocomplete(value)}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {/*  <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          /> */}
                  {option.CIUDAD}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Ciudad de finalización"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </Item>

          {/* Habitación */}

          <Item elevation={0}>
            <Autocomplete
              id="habitacion-select-demo"
              value={habitacionSelect}
              sx={{ bgcolor: "white" }}
              options={rooms}
              multiple={true}
              autoHighlight
              getOptionLabel={(option) => option.label}
              onChange={(event, value) => habitacionAutocomplete(value)}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option.label}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Habitación"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password",
                  }}
                />
              )}
            />
          </Item>

          {/* Días */}

          <Item elevation={0}>
            {/* <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={top100Films}
      sx={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label="Movie" />}
    /> */}
            <Autocomplete
              id="dias-select-demo"
              value={diasSelect}
              sx={{ bgcolor: "white" }}
              options={dias}
              // multiple={true}
              // autoHighlight
              // getOptionLabel={(option) => option.label}
              onChange={(event, value) => diasAutocomplete(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Días hasta"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password",
                  }}
                />
              )}
            />
          </Item>

          <Item elevation={0}>
            {/* Seleccione la serie */}
            <Autocomplete
              disabled={disableFolleto}
              id="serie-select-demo"
              value={serieSelect}
              sx={{ bgcolor: "white" }}
              options={props.folletosData}
              autoHighlight
              multiple={true}
              getOptionLabel={(option) => option.DescripcionFolleto}
              onChange={(event, value) => serieAutocomplete(value)}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option.DescripcionFolleto}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Serie"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </Item>

          {/*   <Item elevation={0}>
            <Input id="outlined-basic" label="Nombre circuito" variant="outlined"  value={nombreCircuito}  onChange={handleNombreCircuito}   sx={{ bgcolor: "white", width: "100%" }} />
          </Item> */}

          <Item elevation={0}>
            <div style={{ width: "100%", marginTop: "10px" }}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                spacing={3}
              >
                <Box>
                  <TextField
                    label="Desde USD"
                    id="outlined-size-small"
                    /*    InputProps={{
              classes: {
                root: classes.InputProps,
                input: classes.InputProps,
              },
            }} */
                    InputLabelProps={{
                      classes: {
                        //root: classes.inputLabel,
                        focused: "focused",
                        shrink: "shrink",
                      },
                    }}
                    value={valueSlider[0]}
                    /*   onChange={(event) => {
                setPrecioDesde(event.target.value);
                setValueSlider([event.target.value, precioHasta]);
              }} */
                    //className={classes.textField}
                    variant="outlined"
                    size="small"
                  />
                </Box>
                <Box>
                  <TextField
                    //className={classes.textField}
                    label="Hasta USD"
                    InputProps={{
                      classes: {
                        /*  root: classes.InputProps,
                input: classes.InputProps, */
                      },
                    }}
                    InputLabelProps={{
                      classes: {
                        //root: classes.inputLabel,
                        focused: "focused",
                        shrink: "shrink",
                      },
                    }}
                    id="outlined-size-small2"
                    value={valueSlider[1]}
                    /* onChange={(event) => {
              setPrecioHasta(event.target.value);
              setValueSlider([precioDesde, event.target.value]);
            }} */
                    variant="outlined"
                    size="small"
                  />
                </Box>
              </Box>
            </div>

            <Slider
              value={valueSlider}
              onChange={handleChangeSlider}
              valueLabelDisplay="off"
              aria-labelledby="range-slider"
              min={props.precioDesde}
              max={props.precioHasta}
            />
          </Item>
          <Item elevation={0}>
            <Button
              variant="contained"
              disableElevation
              sx={{ bgcolor: Colors.orange_ibero, color: Colors.white }}
              onClick={applyFilter}
            >
              Filtrar
            </Button>
          </Item>
        </Stack>
      </Box>
    );
    return content;
  };

  return (
    <>
      {!props.matches ? (
        <>{MultiFiltro()}</>
      ) : (
        <>
          <Accordion
            sx={{ bgcolor: Colors.blue_light }}
            expanded={flagAccordion}
            onChange={handleAccordion("jg")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                sx={{
                  color: Colors.orange_ibero,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                MultiFiltros{" "}
                {/* {props.tipo === "Ofertas" ? " de Ofertas" : " de Circuitos"} */}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>{MultiFiltro()}</AccordionDetails>
          </Accordion>
        </>
      )}

      {props.flagWait ? (
        <Box
          sx={{ display: "flex" }}
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <></>
      )}
    </>
  );
}

const rooms = [
  { code: "SE", label: "Sencilla" },
  { code: "CO", label: "Compartida" },
  { code: "DO", label: "Doble" },
  { code: "TR", label: "Triple" },
  { code: "CU", label: "Cuadruple" },
];

const dias = [
  { code: "Todos", label: "Todos" },
  { code: "2", label: "2" },
  { code: "3", label: "3" },
  { code: "4", label: "4" },
  { code: "5", label: "5" },
  { code: "6", label: "6" },
  { code: "7", label: "7" },
  { code: "8", label: "8" },
  { code: "9", label: "9" },
  { code: "10", label: "10" },
  { code: "11", label: "11" },
  { code: "12", label: "12" },
  { code: "13", label: "13" },
  { code: "14", label: "14" },
  { code: "15", label: "15" },
  { code: "16", label: "16" },
  { code: "17", label: "17" },
  { code: "18", label: "18" },
  { code: "19", label: "19" },
  { code: "20", label: "20" },
  { code: "21", label: "21" },
  { code: "22", label: "22" },
  { code: "23", label: "23" },
  { code: "24", label: "24" },
  { code: "25", label: "25" },
  { code: "26", label: "26" },
  { code: "27", label: "27" },
  { code: "28", label: "28" },
  { code: "29", label: "29" },
  { code: "30", label: "30" },
  { code: "31", label: "31" },
  { code: "36", label: "36" },
];

const series = [
  { code: "MI", label: "Más Incluidos" },
  { code: "TU", label: "Turista" },
  { code: "DO", label: "Península Ibérica y Marruecos" },
  { code: "TR", label: "Europa Mediterranea" },
  { code: "CU", label: "Europa Atlántica" },
  { code: "TR1", label: "Europa Central" },
  { code: "CU2", label: "Europa Nórdica" },
];
