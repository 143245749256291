import styled from "@emotion/styled";
import { Button, Card, IconButton } from "@mui/material";
import { Box, height } from "@mui/system";
import { slideInBottom, slideInRight } from "../../animation";
import { Colors } from "../theme";

export const Product = styled(Card)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  [theme.breakpoints.up("md")]: {
    position: "relative",
  },
  
}));

export const ProductImage = styled("img")(({ src, theme }) => ({
  src: `url(${src})`,
  width: "100%",
  background: Colors.light_gray,
  padding: '0px',
  [theme.breakpoints.down("md")]: {
    width: "100%", 
    padding: '1px',
  },  
}));

export const ProductActionButton = styled(IconButton)(() => ({
  background: Colors.orange_ibero,
  margin: 4,
  color: Colors.white,
  "&:hover": {
    background: Colors.blue_ibero
  }
}));


export const ProductFavButton = styled(ProductActionButton)(({ isfav, theme }) => ({
  //color: isfav ? Colors.orange_ibero : Colors.white,  
  [theme.breakpoints.up("md")]: {
    position: "absolute",
    right: 0,
    top: 0,
  }
}));

export const ProductAddToCart = styled(Button, {
  shouldForwardProp: (prop) => prop !== "show",
})(({ show, theme }) => ({
  width: "120px",
  fontSize: "12px",
  [theme.breakpoints.up("md")]: {
    position: "absolute",    
    bottom: "2%",
    width: "300px",
    padding: "10px 5px",
    animation:
      show &&
      `${slideInBottom} 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both`,
  },
  background: Colors.secondary,
  opacity: 0.9,
}));

export const ProductMetaWrapper = styled(Box)(({theme}) => ({
  padding: "10px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center"
}));

export const ProductMetaWrapperOfertas = styled(Box)(({theme}) => ({
  padding: "0px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  bgcolor: "red",
  width: "100%",
  height: "60px"
}));

export const ProductMetaTitleWrapper = styled(Box)(({theme}) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  bgcolor: "rgba(0, 0, 0, 0.54) !important" ,
  color: "white",
  padding: "10px"
}));

export const ProductActionsWrapper = styled(Box)(({ show, theme }) => ({ 
  [theme.breakpoints.up("md")]: {
    display: show ? 'visible' : 'none',
    position: "absolute",
    right: 0,
    top: '20%',
    animation: show && `${slideInRight} 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both`,
  }
}));

