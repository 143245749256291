import * as React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";
import { Colors } from "../../../../../styles/theme";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import UnpublishedOutlinedIcon from "@mui/icons-material/UnpublishedOutlined";

function Europack(props) {
  return (
    <Box
      component="section"
      sx={{ display: "flex", bgcolor: "secondary.light", overflow: "hidden" }}
    >
      <Container
        sx={{
          mt: 10,
          mb: 8,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          src="/Images/productCurvyLines.png"
          alt="curvy lines"
          sx={{
            pointerEvents: "none",
            position: "absolute",
            top: -180,
            opacity: 0.7,
          }}
        />
        <Typography variant="h4" marked="center" component="h2"  align="center" sx={{ mb: 3 }}>
        Complemente su viaje con el EUROPACK (opcional)
        </Typography>
        {/* <Typography variant="h5" align="center">
          8 comidas / 4 visitas
        </Typography> */}
        <Typography
          variant="h5"
          align="center"
          sx={{ mb: 5, color: Colors.orange_ibero }}
        >
          Precio por persona: <b>$ 1.920.000</b>
        </Typography>
        <div>
          <Grid container spacing={5}>
            {/*<Grid item xs={12} md={12}>
               <Typography variant="h4" align="center">
                Incluye
              </Typography>
            </Grid> */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h6"
                align="center"
                sx={{ textDecoration: "underline" }}
              >
                Comidas incluidas
              </Typography>
              <List dense={true} key={"L-IT"}>
                <ListItem>
                  <ListItemIcon>
                    <CheckCircleOutlinedIcon
                      sx={{ color: Colors.orange_ibero }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Almuerzo en Toledo." />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckCircleOutlinedIcon
                      sx={{ color: Colors.orange_ibero }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Almuerzo en Barcelona." />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckCircleOutlinedIcon
                      sx={{ color: Colors.orange_ibero }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Almuerzo en Pisa." />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckCircleOutlinedIcon
                      sx={{ color: Colors.orange_ibero }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Almuerzo en Roma." />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckCircleOutlinedIcon
                      sx={{ color: Colors.orange_ibero }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Almuerzo en Florencia." />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckCircleOutlinedIcon
                      sx={{ color: Colors.orange_ibero }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Almuerzo en Venecia." />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckCircleOutlinedIcon
                      sx={{ color: Colors.orange_ibero }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Almuerzo en Montmartre." />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckCircleOutlinedIcon
                      sx={{ color: Colors.orange_ibero }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Cena en Lourdes." />
                </ListItem>
              </List>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography
                variant="h6"
                align="center"
                sx={{ textDecoration: "underline" }}
              >
                Visitas incluidas
              </Typography>
              <List dense={true} key={"L-IT"}>
                <ListItem>
                  <ListItemIcon>
                    <CheckCircleOutlinedIcon
                      sx={{ color: Colors.orange_ibero }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Excursión a Toledo." />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckCircleOutlinedIcon
                      sx={{ color: Colors.orange_ibero }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Visita Museos Vaticanos." />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckCircleOutlinedIcon
                      sx={{ color: Colors.orange_ibero }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Subida Torre Eiffel 2º Piso." />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckCircleOutlinedIcon
                      sx={{ color: Colors.orange_ibero }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Paseo en Barco por el rio Sena." />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </div>
      </Container>
    </Box>
  );
}

export default Europack;
