import { Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import SingleCircuitDesktop from "./SingleCircuitDesktop";

export default function Circuits(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const renderCircuits = props.circuitsResultsX.map((circuit) => (
    <Grid
      item
      key={circuit.id}
      xs={12}
      sm={4}
      md={4}
      display="flex"
      flexDirection={"column"}
      alignItems="center"
    >
   {/*    {matches ? (
        <SingleCircuit key={circuit.id} circuit={circuit} matches={matches} />
      ) : (
        <SingleCircuitDesktop key={circuit.id} circuit={circuit} matches={matches} />
      )} */}
      <SingleCircuitDesktop key={circuit.id} circuit={circuit} matches={matches} tipo={props.tipo} tabFecha={props.tabFecha}/>
    </Grid>
  ));
  return (
    // <Container>
      <Grid
        container
        spacing={{ xs: 1, md: 3 }}
        justifyContent="center"
        sx={{ margin: `20px 4px 10px 4px` }}
        columns={{ xs: 3, sm: 8, md: 12 }}
      >
        {renderCircuits}
      </Grid>
    // </Container>
  );
}
