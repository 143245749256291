import * as React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";
import { Avatar, useMediaQuery } from "@mui/material";
import { Colors } from "../../../../../styles/theme";
import theme from "../theme";

const item = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  px: 5,
};

function ProductValues() {
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      component="section"
      sx={{ display: "flex", overflow: "hidden", bgcolor: "secondary.light" }}
    >
      <Container sx={{ mt: 10, mb: 10, display: "flex", position: "relative" }}>
        <Box
          component="img"
          src="/Images/productCurvyLines.png"
          alt="curvy lines"
          sx={{ pointerEvents: "none", position: "absolute", top: -180 }}
        />
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box />
              <Avatar
                sx={{
                  bgcolor: Colors.orange_ibero,
                  textAlign: "center",
                  fontSize: "32px",
                  padding: 10,
                  boxShadow: 3,
                }}
              >
                3 Paises
              </Avatar>
              <br></br>
              <Typography variant="h5" align="center">
                España / Francia / Italia
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box />
              <Avatar
                sx={{
                  bgcolor: Colors.blue_ibero,
                  textAlign: "center",
                  fontSize: "32px",
                  padding: 10,
                  boxShadow: 3,
                }}
              >
                10 Ciudades
              </Avatar>
              <br></br>
              <Typography variant="h5" align="center">
              Madrid / Zaragoza / Barcelona / Roma / Florencia / Venecia / Annemasse / Paris / Lourdes / San Sebastián
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box />
              <Avatar
                sx={{
                  bgcolor: "red",
                  textAlign: "center",
                  fontSize: "32px",
                  padding: 10,
                  boxShadow: 3,
                }}
              >
                17 Días
              </Avatar>
              <br></br>
              <Typography variant="h5" align="center">
                17 días / 15 noches
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
          <Typography
            variant="h4"
            marked="center"
            component="h2"
            sx={{ mb: 3, mt: 4 }}
            align="center"
          >
            Mapa de rutas
          </Typography>
            <Box sx={item}>
              <img
                src={`/images/LandingPages/MapaIberofertaEuropa.png`}
                loading="lazy"
                width={!matches ? "600px" : "350px"}
                alt="MapaMundi"
                style={{                            
                  boxShadow: '1px 2px 9px #F4AAB9',
                }}
              ></img>              
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ProductValues;
