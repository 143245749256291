import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  ActionIconsContainerDesktop,
  MyList,
} from "../../../styles/circuitsdetail";
import { Colors } from "../../../styles/theme";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";

export default function Actions(props) {
/*   const Component = matches
    ? ActionIconsContainerMobile
    : ActionIconsContainerDesktop; */
    const Component = ActionIconsContainerDesktop;

    const OnClick = (index) => {
      props.onClick(index);
};

  return (
    <Component>
      <MyList type="row">
        <ListItemButton
          sx={{
            justifyContent: "center",
          }}
          onClick={(event) => OnClick(99)}
        >
          <ListItemIcon
            sx={{
              display: "flex",
              justifyContent: "center",
              color: Colors.secondary,
              // color: matches && Colors.secondary,
            }}
          >
            <CalculateOutlinedIcon sx={{ fontSize: 40, color: "white" }} />
            <ListItemText
              disableTypography
              primary={
                <Typography variant="h6" style={{ color: Colors.white }}>
                  Cotice y reserve su viaje
                </Typography>
              }
            />
          </ListItemIcon>
        </ListItemButton>
      </MyList>
    </Component>
  );
}
