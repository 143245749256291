export const masvendidos = [
    {
        id: 1,
        name: "Carta de Viajes",
        url: "https://www.europamundo.com/multibuscadorcarta.aspx",
        description: "Torem ipsum dolor sit amet, consectetur adipisicing elitsed do eiusmo tempor incididunt ut labore et dolore magna",
        image: "http://www.iberolunatravel.com/Imagenes/salidas-grupales/europa-completisima-cop-jul-sep.jpg"
    },
   
    {
        id: 3,
        name: "Carta de Viajes",
        url: "https://www.europamundo.com/multibuscadorcarta.aspx",
        description: "Torem ipsum dolor sit amet, consectetur adipisicing elitsed do eiusmo tempor incididunt ut labore et dolore magna",
        image: "http://www.iberolunatravel.com/Imagenes/salidas-grupales/andalucia-marruecos-portugal.jpg"
    }
    ,
    {
        id: 4,
        name: "Carta de Viajes",
        url: "https://www.europamundo.com/multibuscadorcarta.aspx",
        description: "Torem ipsum dolor sit amet, consectetur adipisicing elitsed do eiusmo tempor incididunt ut labore et dolore magna",
        image: "http://www.iberolunatravel.com/Imagenes/salidas-grupales/europa-romantica-cop.jpg"
    }
    ,
    {
        id: 5,
        name: "Carta de Viajes",
        url: "https://www.europamundo.com/multibuscadorcarta.aspx",
        description: "Torem ipsum dolor sit amet, consectetur adipisicing elitsed do eiusmo tempor incididunt ut labore et dolore magna",
        image: "http://www.iberolunatravel.com/Imagenes/salidas-grupales/europa-completisima-2022.jpg"
    }
    ,
    {
        id: 6,
        name: "Carta de Viajes",
        url: "https://www.europamundo.com/multibuscadorcarta.aspx",
        description: "Torem ipsum dolor sit amet, consectetur adipisicing elitsed do eiusmo tempor incididunt ut labore et dolore magna",
        image: "http://www.iberolunatravel.com/Imagenes/salidas-grupales/europa-para-todos.jpg"
    }
    ,
    {
        id: 7,
        name: "Carta de Viajes",
        url: "https://www.europamundo.com/multibuscadorcarta.aspx",
        description: "Torem ipsum dolor sit amet, consectetur adipisicing elitsed do eiusmo tempor incididunt ut labore et dolore magna",
        image: "http://www.iberolunatravel.com/Imagenes/salidas-grupales/tour-iberico-2022.jpg"
    }
]