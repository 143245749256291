import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";
import TextField from "../components/TextField";
import Snackbar from "../components/Snackbar";
import Button from "../components/Button";
import {
  BoxItinerario,
  DayImage,
  SubtitleHeader,
} from "../../../../../styles/circuitsdetail";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { Colors } from "../../../../../styles/theme";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import ReactPlayer from "react-player";
import { List, ListItem, ListItemIcon, ListItemText, Stack } from "@mui/material";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import UnpublishedOutlinedIcon from "@mui/icons-material/UnpublishedOutlined";

function Incluye(props) {
  const [open, setOpen] = React.useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container
      component="section"
      sx={{
        mt: 10,
        mb: 10,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h4"
            marked="center"
            component="h2"
            sx={{ mb: 3 }}
            align="center"
          >
            Servicios Incluidos
          </Typography>
          <List dense={true} key={"L-IT"}>
              {props.incluyeData
                .filter((item) => item.DESCRIPCION !== "noincluye")
                .map((item, i) => (
                  <ListItem key={i}>
                    <ListItemIcon>
                      <CheckCircleOutlinedIcon
                        key={i}
                        sx={{ color: Colors.orange_ibero }}
                      />
                    </ListItemIcon>
                    <ListItemText key={i} primary={item.INCLUYE} />
                  </ListItem>
                ))}
            </List>
        </Grid>

        <Grid item xs={12}  md={6}>
          <Typography
            variant="h4"
            marked="center"
            component="h2"
            align="center"
            sx={{ mb: 3 }}
          >
            Servicios No Incluidos
          </Typography>
          <List dense={true} key={"L-IT"}>
                  {props.incluyeData
                    .filter((item) => item.DESCRIPCION === "noincluye")
                    .map((item, i) => (
                      <ListItem key={i}>
                        <ListItemIcon>
                          <UnpublishedOutlinedIcon
                            key={i}
                            sx={{ color: Colors.orange_ibero }}
                          />
                        </ListItemIcon>
                        <ListItemText key={i} primary={item.INCLUYE} />
                      </ListItem>
                    ))}
                </List>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Incluye;
